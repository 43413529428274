


/***************************** 订单管理所有接口 **************************************/


// 导入axios实例
import httpRequest from '@/request/index'

// 服务商列表
export function merchantList(param) {
    return httpRequest({
        url: 'admin/merchant/lists',
        method: 'post',
        data: param
    })
}

//禁用或启用服务商账号
export function change_status(param) {
    return httpRequest({
        url: 'admin/merchant/change_status',
        method: 'post',
        data: param
    })
}

//编辑服务商
export function update_service(param) {
    return httpRequest({
        url: 'admin/merchant/update_service',
        method: 'post',
        data: param
    })
}

//服务商基本信息
export function base_info(param) {
    return httpRequest({
        url: 'admin/merchant/base_info',
        method: 'GET',
        params: param,
    })
}


//根据字典编码获取数据
export function getDictionary(param) {
    return httpRequest({
        url: 'cate/getDictionary',
        method: 'GET',
        data: {
            dict_code: param
        }
    })
}

//认证待审核列表
export function merchant_audit_lists(param) {
    return httpRequest({
        url: 'admin/merchant/audit_lists',
        method: 'GET',
        params: param,
    })
}

//认证已驳回列表 admin/merchant/reject_lists
export function merchant_reject_lists(param) {
    return httpRequest({
        url: 'admin/merchant/reject_lists',
        method: 'GET',
        params: param,
    })
}

//意见反馈详情 admin/merchant/feedback_info
export function merchant_feedback_info(param) {
    return httpRequest({
        url: 'admin/merchant/feedback_info',
        method: 'GET',
        params: param,
    })
}

//服务商反馈处理 admin/merchant/update_feedback
export function merchant_update_feedback(param) {
    return httpRequest({
        url: 'admin/merchant/update_feedback',
        method: 'post',
        data: param
    })
}

//认证详情
export function merchant_authentication_info(param) {
    return httpRequest({
        url: 'admin/merchant/authentication_info',
        method: 'GET',
        params: param,
    })
}

//认证审核 admin/merchant/authentication_save
export function merchant_authentication_save(param) {
    return httpRequest({
        url: 'admin/merchant/authentication_save',
        method: 'post',
        data: param
    })
}

//意见反馈待处理列表 admin/merchant/pending_lists
export function merchant_pending_lists(param) {
    return httpRequest({
        url: 'admin/merchant/pending_lists',
        method: 'GET',
        params: param,
    })
}

//服务商反馈已处理列表 admin/merchant/processed_lists
export function merchant_processed_lists(param) {
    return httpRequest({
        url: 'admin/merchant/processed_lists',
        method: 'GET',
        params: param,
    })
}