<template>
  <div>
    <div class="container">
      <div class="part">
        <div class="title">
          <h3>意见反馈信息</h3>
          <div class="line"></div>
        </div>
        <div class="content">
          <div class="item item-flex">
            <span class="item-name">反馈服务商</span>
            <div class="item-con">
              <p>{{detailsData.service_name}}</p>
            </div>
          </div>
          <div class="item item-flex">
            <span class="item-name">反馈类型</span>
            <div class="item-con">
              <p>{{detailsData.dict_name}}</p>
            </div>
          </div>
          <div class="item">
            <span class="item-name">反馈内容</span>
            <div class="item-con">
              <p style="margin-top: 4px;">{{detailsData.content}}</p>
            </div>
          </div>
          <div class="item">
            <span class="item-name">相关图片</span>
            <div class="item-con">
              <div class="relate-pictures">
                <el-image v-for="(item, index) in detailsData.imgs" fit="contain" :src="item" :preview-src-list="detailsData.imgs" :initial-index="index" :key="index"></el-image>
              </div>
            </div>
          </div>
          <div class="item item-flex">
            <span class="item-name">联系方式</span>
            <div class="item-con">
              <p>{{detailsData.call_tel}}</p>
            </div>
          </div>
          <div class="item item-flex">
            <span class="item-name">提交时间</span>
            <div class="item-con">
              <p>{{detailsData.create_time}}</p>
            </div>
          </div>
        </div>
      </div>
      <div class="part mt-12">
        <div class="title">
          <h3>投诉回复</h3>
          <div class="line"></div>
        </div>
        <div class="content">
          <div class="item">
            <span class="item-name">回复内容</span>
            <div class="item-con">
              <p style="margin-top: 4px;">{{detailsData.reply_content}}</p>
            </div>
          </div>
          <div class="item">
            <span class="item-name">回复图片</span>
            <div class="item-con">
              <div class="relate-pictures">
                <el-image v-for="(item, index) in detailsData.reply_imgs" fit="contain" :src="item" :preview-src-list="detailsData.reply_imgs" :initial-index="index" :key="index"></el-image>
              </div>
            </div>
          </div>
          <div class="item item-flex">
            <span class="item-name">回复时间</span>
            <div class="item-con">
              <p>{{detailsData.reply_time}}</p>
            </div>
          </div>
        </div>
        <div class="back-btn" @click="backMethod">返回</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "handleDetail",
  data() {
    return {
      detailsData: {},
      data: {
        relatePictures: [
          'https://fuss10.elemecdn.com/3/63/4e7f3a15429bfda99bce42a18cdd1jpeg.jpeg?imageMogr2/thumbnail/360x360/format/webp/quality/100',
          'https://fuss10.elemecdn.com/3/63/4e7f3a15429bfda99bce42a18cdd1jpeg.jpeg?imageMogr2/thumbnail/360x360/format/webp/quality/100'
        ]
      },
      input: ""
    }
  },
  created(){
    this.getDetailsDataMethod()
  },
  methods: {
    getDetailsDataMethod() {
      this.$api.merchant_feedback_info({id: this.$route.query.id}).then(res => {
        this.detailsData = res.data;
        console.log(res.data,'res.data')
      })
    },
    backMethod() {
      this.$router.push({path: '/serverBigManage', query: {activeName: 'second'}})
    }
  }
}
</script>

<style scoped lang="scss">
.container {
  margin: 18px;
  //padding: 30px;
  width: calc(100% - 96px);
  height: calc(100% - 96px);
  //background: #FFFFFF;
  overflow: scroll;
  scrollbar-width: none;
  .back-btn{
    width: 100px;
    height: 44px;
    background: #EEF1F3;
    border-radius: 4px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    font-size: 14px;
    color: #6C7880;
    line-height: 44px;
    text-align: center;
    cursor: pointer;
  }
  .part {
    margin-bottom: 12px;
    padding: 30px 30px 14px;
    background-color: #ffffff;
    &:last-child {
      margin-bottom: 0;
    }
    .title {
      display: flex;
      align-items: center;
      justify-content: space-between;
      h3{
        position: relative;
        margin-right: 10px;
        flex-shrink: 0;
        height: 20px;
        line-height: 20px;
        font-weight: 400;
        font-size: 18px;
        color: #242D33;
        &:before{
          content: '';
          width: 4px;
          height: 20px;
          background: #3984F5;
          position: absolute;
          top: 0;
          left: -30px;
        }
      }
      .line{
        background: #EDF1F4;
        flex-grow: 1;
        height: 1px;
      }
    }
    .content {
      padding: 20px 0 0;
    }
  }
  .item {
    margin-bottom: 12px;
    font-size: 12px;
    color: #242D33;
    line-height: 24px;
  }
  .item-flex {
    display: flex;
  }
  .item-name {
    margin-right: 16px;
    color: #6C7880;
  }
  .relate-pictures {
    margin-top: 12px;
    .el-image {
      margin-right: 10px;
      width: 100px;
      height: 100px;
    }
  }
  .upload-tips {
    font-size: 12px;
    color: #6C7880;
    line-height: 48px;
  }
  // tags样式修改
  ::v-deep .el-tabs__nav-wrap::after {
    background: #EEEFF2;
    height: 1px;
  }
  ::v-deep .el-tabs__item.is-active {
    color: #3984F5;
    font-weight: bold;
  }
  ::v-deep .el-tabs__item {
    &:hover {
      color: #3984F5;
    }
  }
}
</style>
