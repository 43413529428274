<script>
import titleDom from "@/components/common/titleDom";
import {base_info, getDictionary, getdictionary, update_service} from "../../../../request/servertApi";
export default {
  name: "edit",
  components: {titleDom},
  data() {
    return {
      ruleForm: {
        id: "",
        truename: "",
        mobile:"",
        areaid: "",
        address:"",
        industry_id: "",
        main_business: ""
      },
      detailsData: {
        service_name: "",
        code:"",
        username: "",
        status: "",
      },
      addressData: [],
      industryData: [],
      rules: {
        name: [
          { required: false, message: '请输入服务商名称', trigger: 'blur' },
          { min: 3, max: 5, message: '长度在 3 到 5 个字符', trigger: 'blur' }
        ],
        code: [
          { required: false, message: '请输入统一社会信用代码', trigger: 'blur' },
        ],
        username: [
          { required: false, message: '请输入用户名', trigger: 'blur' },
        ],
        truename: [
          { required: true, message: '请输入联系人', trigger: 'blur' },
          { min: 2, max: 10, message: '长度在 2 到 10 个字符', trigger: 'blur' }
        ],
        mobile: [
          { required: true, message: '请输入联系电话', trigger: 'blur' },
          { min: 11, max: 11, message: '请输入正确的手机号', trigger: 'blur' }
        ],
        areaid: [
          { required: true, message: '请选择所在地区', trigger: 'change' }
        ],
        address: [
          { required: true, message: '请输入详细地址', trigger: 'blur' }
        ],
        industry_id: [
          { required: true, message: '请选择所属行业', trigger: 'change' }
        ],
        main_business: [
          { required: true, message: '请输入主营业务', trigger: 'blur' }
        ],
        status: [
          { required: false, message: '请输入认证状态', trigger: 'blur' }
        ]
      }
    };
  },
  mounted() {
    this.$nextTick(() => {
      this.getAddressData();
      this.getHy();
      this.getDetailsDataMethod();
    });
  },
  methods: {
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.ruleForm.areaid = this.ruleForm.areaid[this.ruleForm.areaid.length - 1];
          this.$api.update_service(this.ruleForm).then(res => {
            this.$message.success("修改成功");
            this.$router.push({path: '/serverList'});
          })
          console.log(this.ruleForm,'提交表单')
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    getAddressData() {
      this.$api.areaTreeList().then(res=>{
        this.addressData = res.data;
      })
    },
    getDetailsDataMethod() {
      this.$api.base_info({id: this.$route.query.id}).then(res => {
        this.ruleForm.id = res.data.id;
        this.detailsData.service_name = res.data.service_name;
        this.detailsData.code = res.data.identification;
        this.detailsData.username = res.data.username;
        if (res.data.vaildate_status == 0) {
          this.detailsData.status = "未认证";
        }else if (res.data.vaildate_status == 1) {
          this.detailsData.status = "审核中";
        }else if (res.data.vaildate_status == 2) {
          this.detailsData.status = "已认证";
        }else if (res.data.vaildate_status == 3) {
          this.detailsData.status = "认证失败";
        }
        console.log(res,'详情接口')
      })
    },
    getHy(){
      this.$api.getDictionary('serviceIndustry').then(res=>{
        this.industryData = res.data
        console.log(res,'行业接口')
      })
    }
  }
}
</script>

<template>
<div class="container">
  <title-dom title="基本信息"></title-dom>
  <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="160px" class="demo-ruleForm">
    <el-form-item label="服务商名称" prop="name">
      <el-input v-model="detailsData.service_name" style="width: 400px" disabled></el-input>
    </el-form-item>
    <el-form-item label="统一社会信用代码" prop="code">
      <el-input v-model="detailsData.code" style="width: 400px" disabled></el-input>
    </el-form-item>
    <el-form-item label="用户名" prop="username">
      <el-input v-model="detailsData.username" style="width: 400px" disabled></el-input>
    </el-form-item>
    <el-form-item label="联系人" prop="truename">
      <el-input v-model="ruleForm.truename" style="width: 400px" placeholder="请输入联系人"></el-input>
    </el-form-item>
    <el-form-item label="联系电话" prop="mobile">
      <el-input v-model="ruleForm.mobile" style="width: 400px" placeholder="请输入联系电话"></el-input>
    </el-form-item>
    <el-form-item label="所在地区" prop="areaid">
      <el-cascader style="width: 400px" v-model="ruleForm.areaid" :options="addressData" collapse-tags :props="{value: 'id', label: 'name'}" clearable></el-cascader>
    </el-form-item>
    <el-form-item label="详细地址" prop="address">
      <el-input v-model="ruleForm.address" style="width: 400px" placeholder="请输入详细地址"></el-input>
    </el-form-item>
    <el-form-item label="所属行业" prop="industry_id">
      <el-select v-model="ruleForm.industry_id" placeholder="请选择所属行业" style="width: 400px">
        <el-option v-for="item in industryData" :key="item.id" :label="item.dict_name" :value="item.id"></el-option>
      </el-select>
    </el-form-item>
    <el-form-item label="主营业务" prop="main_business">
      <el-input type="textarea" v-model="ruleForm.main_business" placeholder="请输入主营业务"></el-input>
    </el-form-item>
    <el-form-item label="认证状态" prop="status">
      <el-input :class="{'status-blue': detailsData.status == '未认证' || detailsData.status == '审核中', 'status-green':  detailsData.status == '已认证', 'status-red': detailsData.status == '认证失败'}" v-model="detailsData.status" style="width: 400px;" disabled></el-input>
    </el-form-item>
    <el-form-item>
      <el-button type="primary" style="width: 100px" @click="submitForm('ruleForm')">确定</el-button>
      <el-button type="info" plain style="width: 100px" @click="$router.go(-1)">返回</el-button>
    </el-form-item>
  </el-form>
</div>
</template>

<style scoped lang="scss">
.container {
  margin: 18px;
  padding: 30px;
  width: calc(100% - 96px);
  height: calc(100% - 96px);
  background: #FFFFFF;
  overflow: scroll;
  scrollbar-width: none;
  .demo-ruleForm{
    margin-top: 30px;
  }
  :deep .el-textarea__inner {
    height: 200px;
  }
  :deep .el-input.is-disabled.status-blue {
    .el-input__inner{
      color: #3984F5;
    }
  }
  :deep .el-input.is-disabled.status-green {
    .el-input__inner{
      color: #04CD83;
    }
  }
  :deep .el-input.is-disabled.status-red {
    .el-input__inner{
      color: #FD5451;
    }
  }
}
</style>