<template>
	<div class="cancel-order-main">
		<el-dialog title="取消订单" :visible.sync="show" width="490px" :close-on-click-modal="false"  :before-close="handleClose" @opened="openClick">
			<div class="title-text">您正在发起退款，请确认需要退款的金额，退款发起后将无法撤销</div>
			<el-form ref="ruleForm" :model="form" :rules="rules" label-width="80px">
			  <el-form-item label="退款金额" prop="refund_amount">
				  <div class="money-text">￥{{form.refund_amount}}</div>
			  </el-form-item>
			  <el-form-item label="退款方式" prop="refund_method">
			    <el-radio-group v-model="form.refund_method">
			      <el-radio :label="1" disabled >线下退回</el-radio>
			      <el-radio :label="2" disabled >线上退回</el-radio>
			    </el-radio-group>
			  </el-form-item>
			  <el-form-item>
			    <el-button @click="handleClose">取消</el-button>
			    <el-button type="primary" @click="enterClick('ruleForm')">确定</el-button>
			  </el-form-item>
			</el-form>
		
		</el-dialog>
	</div>
</template>

<script>
	export default {
		name: 'cancel-order-popup',
		props: {
			show: false,
			msg: {}
		},
		data(){
			return {
				form: {id: '', refund_amount: '', refund_method: 1},
				rules: {
					refund_amount: [{ required: true, message: '请输入退款金额', trigger: 'blur' }],
					refund_method: [{ required: true, message: '请选择退款方式', trigger: 'change' }],
				},
			}
		},
		created(){
			this.form.refund_method = this.msg.pay_method;
		},
		methods: {
			enterClick(formName){
				this.form.id = this.msg.id;
				this.$refs[formName].validate((valid) => {
					if (valid) {
						if (this.msg.order_status==1){
							this.$api.order_cancel(this.form).then(res=>{
								this.$message({type: 'success', message: '取消成功！'});
								this.handleClose();
								this.$emit('change');
							})							
						} else if (this.msg.order_status == 3) {
							this.$api.order_refund(this.form).then(res=>{
								this.$message({type: 'success', message: '发起退款成功！'});
								this.handleClose();
								this.$emit('change');
							})							
						}
					} else {
						console.log('error submit!!');
						return false;
					}
				});
			},
			//打开
			openClick(){
				this.form.refund_amount = this.msg.total_amount;
			},
			handleClose(){
				this.$emit('close');
			}
			
		},
	}
</script>

<style lang="scss" scoped>
	.cancel-order-main{
		.title-text{font-size: 12px;font-weight: bold;color: #242D33;padding: 0 0 16px 17px;}
		.money-text{width: 350px;height: 34px;border-radius: 4px;border: 1px solid #CEDAE0;padding-left: 14px;line-height: 34px;box-sizing: border-box;}
	}
	
</style>