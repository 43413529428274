<script>
import {timeFormat} from "@/utils/index.js"
import batchAuditPopup from '../components/batch-audit-popup.vue';
export default {
  name: "index",
  components: {
    batchAuditPopup
  },
  data() {
    return {
      tabList: [
        {id: '2', label: "审核通过",count: 24},
        {id: '1', label: "待审核",count: 10},
        {id: '3', label: "审核驳回",count: 5}
      ],
      inquire: {
        name: '',
        dictionary_id: '',
        releaseTime: [],
        service_name: '',
        status: '2', // 1=待审核 2=审核通过 3=驳回
      },
      serviceTypeOptions:[], // 服务类型
      tableData: [],
      page: 1,
      limit: 10,
      total: 0,
      loading: false,

      deteleData: {show: false, data: []},//删除提示
      isChecked: false,//是否全选
      auditData: {show: false, data: {}},//批量审核弹窗数据
    };
  },
  methods: {
    handleClick() {
      this.page = 1;
      this.initData();
    },
    inquireBtn() {   // 搜索按钮
      this.page = 1;
      this.initData();
    },
    resetBtn() {   // 重置按钮
      this.inquire = {
        name: '',
        dictionary_id: '',
        releaseTime: [],
        service_name: '',
      };
    },
    handleSizeChange(val) {   // 分页大小
      this.limit = val;
      this.initData();
    },
    handleCurrentChange(val) {   // 分页页码
      this.page = val;
      this.initData();
    },
    routerBtn(id) {   // 详情按钮 2审核通过 1待审核 3审核驳回
      this.$router.push({path: '/serviceDetail', query: {id: id, status: this.inquire.status}})
    },
    //关闭弹窗
    closeClick() {
      this.auditData.show = false;
    },
    //多选事件
    selectionChange(e) {
      this.deteleData.data = [];
      for (let i = 0; i < e.length; i++) {
        this.deteleData.data.push(e[i].id);
      }
    },
    //全选
    selectAllClick(e) {
      if (e) {
        this.$refs.multipleTable.toggleAllSelection();
      } else {
        this.$refs.multipleTable.clearSelection();
      }
    },
    //批量审核弹窗
    auditClick(){
      if (this.deteleData.data.length>0) {
        console.log(this.deteleData.data)
        this.auditData.data = this.deteleData.data;
        this.auditData.show = true;
      }
    },
    // 服务价格
    priceMin (price){
      if (Array.isArray(price)) {
        let priceArr = []
        price.forEach((value) => {
          if (value[1]) {
            priceArr.push(value[1])
          }
        })
        return Math.min(...priceArr)
      } else {
        return 0
      }
    },
    // 服务类型
    getServiceType() {
      this.$api.getDictionaryOptionList(31).then(res=>{
        this.serviceTypeOptions = res.data
      })
    },
    initData() {
      let form = {
        ...this.inquire,
        start_time: this.inquire.releaseTime[0]?timeFormat(this.inquire.releaseTime[0], 'yyyy-mm-dd hh:MM:ss'):'',
        end_time: this.inquire.releaseTime[1]?timeFormat(this.inquire.releaseTime[1], 'yyyy-mm-dd hh:MM:ss'):'',
        page: this.page,
        limit: this.limit
      }
      delete form.releaseTime
      console.log(form)
      this.$api.projectLists(form).then(res=>{
        this.total = res.data.count
        this.tableData = res.data.data
      })
    }
  },
  mounted() {
    this.getServiceType()
    this.initData()
  }
}
</script>

<template>
  <div class="container">
    <el-tabs v-model="inquire.status" @tab-click="handleClick">
      <el-tab-pane :label="tabList[0].label" name="2"></el-tab-pane>
      <el-tab-pane :label="tabList[1].label" name="1"></el-tab-pane>
      <el-tab-pane :label="tabList[2].label" name="3"></el-tab-pane>
    </el-tabs>
    <div class="passExamination">
      <div class="screen clearfix" style="margin-top: 10px">
        <div class="item">
          <span>服务项目名称</span>
          <div class="box">
            <el-input v-model="inquire.name" placeholder="输入服务项目名称" style="width: 210px"></el-input>
          </div>
        </div>

        <div class="item">
          <span>服务类型</span>
          <div class="box">
            <el-select v-model="inquire.dictionary_id" filterable :popper-append-to-body="false" placeholder="全部"
                       style="width: 180px">
              <el-option
                  v-for="item in serviceTypeOptions"
                  :key="item.id"
                  :label="item.dict_name"
                  :value="item.id">
              </el-option>
            </el-select>
          </div>
        </div>

        <div class="item">
          <span>服务商</span>
          <div class="box">
            <el-input v-model="inquire.service_name" placeholder="输入服务商" style="width: 210px"></el-input>
          </div>
        </div>

        <div class="item">
          <span>发布时间</span>
          <div class="box">
            <el-date-picker
                v-model="inquire.releaseTime"
                class="daterange"
                type="datetimerange"
                range-separator="至"
                value-format="yyyy-MM-dd HH:mm:ss"
                start-placeholder="开始时间"
                end-placeholder="结束时间"
                style="width: 234px">
            </el-date-picker>
          </div>
        </div>
        <span class="button inquire" @click="inquireBtn">搜索</span>
        <span class="button" @click="resetBtn">重置</span>
      </div>
      <div class="main_table">
        <el-table
            ref="multipleTable"
            :data="tableData"
            :border="true"
            v-loading="loading"
            style="width: 100%"
            @selection-change="selectionChange"
        >
          <el-table-column type="selection" width="55" v-if="inquire.status == 1" key="1"></el-table-column>
          <el-table-column label="服务项目信息" width="500">
            <template slot-scope="scope">
              <div class="info-box">
                <div class="info-box-icon">
                  <img :src="scope.row.thumb" alt="">
                </div>
                <div class="info-box-content">
                  <div class="info-name">{{ scope.row.name }}</div>
                  <div class="info-desc">{{ scope.row.features }}</div>
                </div>
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="dict_name" label="服务类型" width="150" />
          <el-table-column label="服务价格" width="250">
            <template slot-scope="scope">
              <span v-if="scope.row.price_type == 1" style="color: #FD5451">面议</span>
              <span v-else-if="scope.row.price_type == 2" style="color: #FD5451">￥{{ scope.row.price }} / 年</span>
              <span v-else style="color: #FD5451">￥{{ priceMin(scope.row.price) }}起 / 年</span>
            </template>
          </el-table-column>
          <el-table-column prop="service_name" label="服务商" />
          <el-table-column label="发布时间" width="200">
            <template slot-scope="scope">
              <span>{{ parseTime(scope.row.add_time) }}</span>
            </template>
          </el-table-column>
          <el-table-column label="操作" width="200">
            <template slot-scope="scope">
              <div class="flex flex-col-center" v-if="inquire.status == 1">
                <img class="edit-img" src="@/assets/productLibrary/process_icon@2x.png" alt="" />
                <a style="color: #3984F5" href="javaScript:" @click="routerBtn(scope.row.id)">审核</a>
              </div>
              <div class="flex flex-col-center" v-else>
                <img class="edit-img" src="@/assets/productLibrary/details_icon@2x.png" alt="" />
                <a style="color: #3984F5" href="javaScript:" @click="routerBtn(scope.row.id)">详情</a>
              </div>
            </template>
          </el-table-column>
        </el-table>
        <div class="operate">
          <div>
            <div v-if="inquire.status == 1">
              <el-checkbox v-model="isChecked" @change="selectAllClick"></el-checkbox>
              <button class="xj-text-btn" @click="auditClick">审核</button>
            </div>
          </div>
          <el-pagination
              background
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
              :current-page="page"
              :page-sizes="[10, 20, 30, 40]"
              :page-size="limit"
              layout="prev, pager, next, sizes"
              :total="total">
          </el-pagination>
        </div>
      </div>
    </div>
    <batch-audit-popup :show="auditData.show" :msg="auditData.data" type="project" @change="initData" @close="closeClick"></batch-audit-popup>
  </div>
</template>

<style lang="scss" scoped src="@/styles/common.scss"></style>
<style scoped lang="scss">
.container{
  margin: 18px;
  padding: 30px;
  width: calc(100% - 96px);
  height: calc(100% - 96px);
  background: #FFFFFF;
  //overflow: scroll;
  //scrollbar-width: none;
}
.passExamination{
  min-height: 500px;
  :deep .el-table--border .el-table__cell{
    border-right: none !important;
  }
  .info-box{
    display: flex;
    .info-box-icon{
      margin-right: 18px;
      flex-shrink: 0;
      width: 72px;
      height: 72px;
      background: #CEDAE0;
      border-radius: 6px;
      img{
        width: 100%;
        height: 100%;
      }
    }
    .info-box-content{
      flex: 1;
      .info-name{
        font-family: Microsoft YaHei;
        font-weight: 400;
        font-size: 16px;
        color: #242D33;
        line-height: 36px;
      }
      .info-desc{
        font-family: Microsoft YaHei;
        font-weight: 400;
        font-size: 12px;
        color: #6C7880;
        line-height: 36px;
      }
    }
  }
}
.edit-img{width: 12px;height: 12px;margin-right: 4px;}
.operate {
  justify-content: space-between;
}
.xj-text-btn{width: 50px;height: 32px;background: #FFFFFF;border-radius: 2px;border: 1px solid #CEDAE0;font-size: 12px;color: #6C7880;margin-left: 16px;line-height: 32px;}
</style>
