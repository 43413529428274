<template>
  <div class="container">
    <div class="tips">
      <h2 class="tipsTitle">{{ dictTypeData.dict_name }}</h2>
      <div class="tipsContent">
        <span>创建时间：{{ parseTime(dictTypeData.create_time) }}</span>
        <span>更新时间：{{ parseTime(dictTypeData.update_time) }}</span>
        <span>字典名称：{{ dictTypeData.dict_name }}</span>
        <span>字典编码：{{ dictTypeData.dict_code }}</span>
      </div>
      <div class="tipsDescribe">
        字典描述：{{ dictTypeData.remark }}
      </div>
    </div>
    <div class="screen btnBox clearfix">
      <div class="button inquire" @click="editDictItem" style="width: 94px;">添加字典项</div>
    </div>
    <title-dom :title="'字典项信息'" :distance-left="'-15px'" :is-show-line="false"
               style="margin-left: 15px; margin-bottom: 18px;"/>
    <el-table
        :data="tableData.data"
        :border="true"
        v-loading="tableData.loading"
        style="width: 100%"
    >
      <el-table-column
          prop="id"
          label="ID"
          width="80px">
      </el-table-column>
      <el-table-column
          prop="dict_name"
          label="字典名称">
      </el-table-column>
      <el-table-column
          prop="remark"
          label="字典描述">
      </el-table-column>
      <el-table-column
          prop="sort"
          width="80px"
          label="排序值">
      </el-table-column>
      <el-table-column
          label="操作"
          width="120">
        <template slot-scope="scope">
          <span class="handle" @click="editDictItem(scope.row)">编辑</span>
          <span class="handle" @click="deleteData(scope.row.id)">删除</span>
        </template>
      </el-table-column>
    </el-table>
    <div class="operate">
      <div class="handle">
      </div>
      <el-pagination
          background
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="screen.page"
          :page-sizes="[10, 20, 30, 40]"
          :page-size="screen.limit"
          layout="prev, pager, next, sizes"
          :total="tableData.total">
      </el-pagination>
    </div>

    <!-- 添加或修改字典项 -->
    <el-dialog :title="openData.title" :visible.sync="openData.open" width="500px" append-to-body class="dialog-lzz"
               :closeOnClickModal="false">
      <el-form ref="form" :model="openData.form" :rules="openData.rules" label-width="120px">
        <el-form-item label="字典项名称" prop="dict_name">
          <el-input v-model="openData.form.dict_name" placeholder="输入字典项名称" style="width: 330px;"/>
        </el-form-item>
        <el-form-item label="字典项描述" prop="remark">
          <el-input v-model="openData.form.remark" placeholder="输入字典项描述" style="width: 330px;"/>
        </el-form-item>
        <el-form-item v-if="dictTypeData.dict_name === '收费方式'" label="计费周期（月）" prop="fee_cycle">
          <el-input v-model="openData.form.fee_cycle" placeholder="请输入计费周期，0表示不按周期计费"
                           style="width: 330px;"/>
          <p style="width: 330px; font-size: 12px; color: #6C7880; line-height: 20px;">
            计费周期表示在产品购买时，将按照此计费周期来计算产品费用。 如输入12则表示，在当前计费方式下，产品的价格表示12个月的购买价格。
          </p>
        </el-form-item>
        <el-form-item label="排序值" prop="sort">
          <el-input v-model="openData.form.sort" placeholder="输入数字越小排序越靠前" style="width: 330px;"/>
        </el-form-item>
      </el-form>
      <div style="background: #fff;" class="btn_box">
        <a class="blue" href="#" @click="submitForm">确定</a>
        <a href="#" @click="cancel">取消</a>
      </div>
    </el-dialog>

  </div>
</template>

<script>
import titleDom from "@/components/common/titleDom.vue";

const defaultQuery = {
  page: 1, // 请求页码
  limit: 10, // 每页显示条数
  dict_type: null,
}

const defaultForm = {
  id: null,
  dict_type: null,
  dict_name: '',
  remark: '',
  fee_cycle: '',
  sort: 0
}

export default {
  name: 'DetailDict',
  components: {titleDom},
  data() {
    return {
      queryID: null,
      screen: {
        ...defaultQuery
      }, // 筛选条件
      tableData: {
        data: [], // 表格列表数据
        isIndeterminate: false, // 表格是否有选中但未全选
        allSelect: false, // 表格全选
        multipleSelection: [], // 表格选中列表
        currentPage: 1, // 表格当前页码
        total: 0, // 总条数
        loading: false, // 表格渲染loading
        ids: [], // 表格选中列表id值
      }, // 表格数据
      // 字典类型数据
      dictTypeData: {},
      // 弹出层数据
      openData: {
        title: '添加字典项',
        // 是否显示弹出层
        open: false,
        // 表单参数
        form: {},
        // 表单校验
        rules: {
          dict_name: [
            {required: true, message: "字典项名称不能为空", trigger: "blur"},
          ],
          sort: [
            {
              pattern: /^\d*$/,
              message: "请输入正整数",
              trigger: "blur"
            }
          ],
          fee_cycle: [
            {required: true, message: "计费周期不能为空", trigger: "blur"},
            {
              pattern: /^\d*$/,
              message: "请输入正整数",
              trigger: "blur"
            }
          ]
        }
      }
    }
  },
  mounted() {
    this.queryID = this.$route.query.id;
    if (this.queryID) {
      this.getDict(this.$route.query.id);
      this.screen.dict_type = this.queryID;
      this.getDataList()
    }
  },
  methods: {
    handleSelectionChange(val) {
      this.tableData.multipleSelection = val;
      this.tableData.ids = val.map(item => item.id);
      this.tableData.isIndeterminate = val.length > 0 && val.length < this.tableData.data.length;
      this.tableData.allSelect = val.length === this.tableData.data.length ? true : false;
    }, // 表格选中改变
    toggleSelection() {
      this.$refs.multipleTable.toggleAllSelection();
    }, // 表格全选
    handleSizeChange(val) {
      this.screen.limit = val;
      this.getDataList();
    }, // 分页pageSize改变
    handleCurrentChange(val) {
      this.screen.page = val;
      this.getDataList();
    }, // 分页currentPage改变
    /**
     * 查询
     */
    search() {
      this.getDataList()
    },
    // 表单重置
    reset() {
      this.openData.form = {
        ...defaultForm,
        dict_type: this.queryID
      };
      this.resetForm("form");
    },
    /** 重置按钮操作 */
    resetQuery() {
      this.screen = {
        ...defaultQuery,
        dict_type: this.queryID
      }
      this.search();
    },
    /**
     * 获取字典类型数据
     */
    getDict(id) {
      this.$api.getDict(id).then(res => {
        this.dictTypeData = res.data;
      })
    },
    /**
     * 获取字典项列表数据
     */
    getDataList() {
      this.tableData.loading = true;
      this.$api.dictDetailList(this.screen).then(res => {
        this.$set(this.tableData, 'data', res.data.data);
        this.$set(this.tableData, 'total', res.data.count);
      }).finally(() => {
        this.tableData.loading = false;
      })
    },
    /**
     * 列表数据删除
     */
    deleteData(id) {
      if (!id && this.tableData.ids.length === 0) {
        this.$message({
          type: 'info',
          message: '请选择需要删除的数据！'
        });
        return false;
      }
      let ids = id ? id : this.tableData.ids;

      this.$confirm('此操作将永久删除该数据, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$api.delDetailDict(ids).then(() => {
          this.$message({
            type: 'success',
            message: '删除成功!'
          });
          this.resetQuery()
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        });
      });
    },
    // 点击编辑或添加
    editDictItem(row) {
      let id = row?.id
      this.reset();
      if (id) {
        this.openData.title = "编辑字典项";
        this.$api.getDetailDict(id).then(res => {
          this.openData.form = res.data;
          this.openData.open = true;
        })
      } else {
        this.openData.title = "添加字典项";
        this.openData.open = true;
      }
    },
    /** 提交按钮 */
    submitForm: function () {
      this.$refs["form"].validate(valid => {
        if (valid) {
          this.$api.editDetailDict(this.openData.form).then(() => {
            this.$message({
              message: this.openData.form.id ? "修改成功" : "新增成功",
              type: 'success'
            });
            this.openData.open = false;
            this.getDataList();
          });
        }
      });
    },// 取消按钮
    cancel() {
      this.openData.open = false;
      this.reset();
    },
  }
}
</script>

<style lang="scss" scoped src="@/styles/common.scss"></style>
<style lang="scss" scoped>
.container {
  margin: 18px;
  padding: 30px 20px;
  width: calc(100% - 76px);
  height: calc(100% - 96px);
  background: #FFFFFF;
  overflow: scroll;
  scrollbar-width: none;

  .tips {
    background: #F6F7F9;
    border: 1px solid #EDF1F4;
    margin-bottom: 30px;
    padding: 20px 25px;

    .tipsTitle {
      font-family: Microsoft YaHei;
      font-weight: 400;
      font-size: 18px;
      color: #242D33;
      line-height: 36px;
    }

    .tipsContent {
      font-family: Microsoft YaHei;
      font-weight: 400;
      font-size: 12px;
      color: #6C7880;
      line-height: 26px;

      span {
        margin-right: 50px;

        &:last-child {
          margin-right: 0;
        }
      }
    }

    .tipsDescribe {
      font-family: Microsoft YaHei;
      font-weight: 400;
      font-size: 12px;
      color: #6C7880;
      line-height: 26px;
    }
  }
}
</style>
