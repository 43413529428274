<script>
export default {
  name: "item-order",
  data() {
    return {
      activeName: 0,
      menuList: [ //1待确认2待服务3 服务中4待验收5已关闭6已完结
        {title: "全部", path: '0'}, {title: "待确认", path: '1'}, {title: "待服务", path: '2'},
        {title: "服务中", path: '3'}, {title: "待验收", path: '4'},{title: "已关闭", path: '5'},
          {title: "已完结", path: '6'}
      ],
      alltime: [],
      maxHeight: 0, // 用于存储最大高度
      screen: {
        order_status: 0,
        order_no: '',
        product_name: '',
        start_time: '',
        end_time: '',
        order_type: '2',
      },
      dictData: {
        productTypeOptions: [
          {label: '全部', value: ''},
          {label: '产品', value: 'product'},
          {label: '项目', value: 'project'}
        ],
        statusOptions: [
          {label: '全部', value: ''},
          {label: '待评价', value: '1'},
          {label: '已评价', value: '2'}
        ]
      },
      tableData: {
        data: [],
        total: 0,
        loading: false
      }
    };
  },
  mounted() {
    this.$nextTick(() => {
      this.updateMaxHeight();
      window.addEventListener('resize', this.updateMaxHeight);
    });
    this.getTableData();
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.updateMaxHeight); // 组件销毁前移除事件监听
  },
  methods: {
    updateMaxHeight() {
      // 获取父元素的高度
      const containerHeight = this.$refs.container.clientHeight;
      this.maxHeight = `${containerHeight - 220}px`;
    },
    getTableData() {
      this.tableData.loading = true;
      this.$api.orderPageList(this.screen).then(res => {
        this.tableData.data = res.data.data;
        this.tableData.total = res.data.count;
        this.tableData.loading = false;
        console.log(res,'列表')
      })
    },
    handleClick(tab, event) {
      console.log(tab.name);
      this.activeName = tab.name;
      this.screen.page = 1;
      this.screen.order_status = tab.name;
      this.getTableData();
    },
    search() {
      this.screen.page = 1;
      console.log(this.screen,'搜索')
      this.getTableData();
    },
    resetQuery() {
      this.screen = {
        order_status: 0,
        order_no: '',
        product_name: '',
        start_time: '',
        end_time: '',
        order_type: '2',
      };
      this.search();
    },
    handleView(row) {
      this.$router.push({path: '/itemDetail', query: {id: row.id}});
    },
    handleChangeDate(val) { //时间change事件
      this.screen.start_time = val[0];
      this.screen.end_time = val[1];
      console.log(val);
    },
    handleSizeChange(val) { //分页大小
      this.screen.limit = val;
      this.screen.page = 1;
      this.getTableData();
    },
    handleCurrentChange(val) { //分页页码
      this.screen.page = val;
      this.getTableData();
    },
    // 规格内容
    getSpec (price){
      if (Array.isArray(price)) {
        let value = ''
        price.forEach((item) => {
          if (item[2] == '1') {
            value = item[0]
            return
          }
        })
        return value
      } else {
        return ''
      }
    }
  }
}
</script>

<template>
<div class="container" ref="container">
  <el-tabs v-model="activeName" @tab-click="handleClick">
    <el-tab-pane v-for="(item, index) in menuList" :key="index" :label="item.title" :name="item.path"></el-tab-pane>
  </el-tabs>

  <div class="screen clearfix mt-10">
    <div class="item">
      <span>服务项目</span>
      <div class="box">
        <el-input v-model="screen.order_no" placeholder="输入服务项目" style="width: 200px"></el-input>
      </div>
    </div>
    <div class="item">
      <span>订单编号</span>
      <div class="box">
        <el-input v-model="screen.product_name" placeholder="输入订单编号" style="width: 200px"></el-input>
      </div>
    </div>
    <div class="item">
      <span>下单时间</span>
      <div class="box">
        <el-date-picker
            v-model="alltime"
            class="daterange"
            type="datetimerange"
            range-separator="至"
            value-format="yyyy-MM-dd HH:mm:ss"
            start-placeholder="开始时间"
            end-placeholder="结束时间"
            style="width: 234px"
            @change="handleChangeDate">
        </el-date-picker>
      </div>
    </div>
    <span class="button inquire" @click="search">查询</span>
    <span class="button" @click="resetQuery">重置</span>
  </div>
  <el-table
      ref="multipleTable"
      :data="tableData.data"
      :border="true"
      v-loading="tableData.loading"
      style="width: 100%"
      :max-height="maxHeight"
  >
    <el-table-column prop="order_no" label="订单编号" width="120"></el-table-column>
    <el-table-column prop="company.company" label="下单企业" width="180"></el-table-column>
    <el-table-column label="服务项目" width="400">
      <template slot-scope="scope">
        <div class="product-info-box">
          <div class="img-box">
            <img :src="scope.row.orderProject.product_img" alt="">
          </div>
          <div class="product-info-txt">
            <div class="product-info-title">{{ scope.row.orderProject.name }}</div>
            <div class="product-info-desc">{{scope.row.orderProject.features }}</div>
            <div v-if="scope.row.orderProject?.charge_method == 3 || scope.row.orderProject?.charge_method == 4" class="product-info-specification">规格：{{ getSpec(scope.row.orderProject?.price) }}</div>
          </div>
        </div>
      </template>
    </el-table-column>
    <el-table-column prop="service_name" label="服务商" width="300"></el-table-column>
    <el-table-column prop="total_amount" label="服务价格">
      <template slot-scope="scope">
        <span v-if="scope.row.orderProject?.charge_method == 1" class="color-red">面议</span>
        <span v-else class="color-red">￥{{ scope.row.total_amount }}</span>
      </template>
    </el-table-column>
    <el-table-column prop="create_time" label="下单时间"></el-table-column>
    <el-table-column prop="evaluate_status" label="订单状态" v-if="activeName == 0 || activeName == 4" align="center">
      <template slot-scope="scope">
        <span class="color-blue" v-if="scope.row.order_status == 1">待确认</span>
        <span class="color-blue" v-else-if="scope.row.order_status == 2">待服务</span>
        <span class="color-blue" v-else-if="scope.row.order_status == 3">服务中</span>
        <span :class="scope.row.refund_status == 2 || scope.row.refund_status == 4 ? '' : 'color-blue'" v-else-if="scope.row.order_status == 4">
          待验收
          <p v-if="scope.row.refund_status == 2" class="color-red">退款中</p>
          <p v-if="scope.row.refund_status == 4" class="color-red">退款驳回</p>
        </span>
        <span class="color-red" v-else-if="scope.row.order_status == 5">已关闭</span>
        <span class="color-green" v-else-if="scope.row.order_status == 6">已完结</span>
        <span v-else>--</span>
      </template>
    </el-table-column>
    <el-table-column prop="id" fixed="right" label="操作" width="100">
      <template slot-scope="scope">
        <div class="flex flex-col-center flex-row-between">
          <span class="color-blue touch-hover" @click="handleView(scope.row)">订单详情</span>
        </div>
      </template>
    </el-table-column>
  </el-table>
  <div class="operate">
    <div class="handle">

    </div>
    <el-pagination
        background
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="screen.page"
        :page-sizes="[10, 20, 30, 40]"
        :page-size="screen.limit"
        layout="prev, pager, next, sizes"
        :total="tableData.total">
    </el-pagination>
  </div>
</div>
</template>
<style lang="scss" scoped src="@/styles/common.scss"></style>
<style scoped lang="scss">
.container{
  width: 100%;height: 100%;background-color: #fff;padding: 30px;box-sizing: border-box;
  :deep .el-table--border .el-table__cell{
    border-right: none !important;
  }
  :deep .el-table{
    border: none !important;
  }
  :deep .el-dialog{
    margin-top: 28vh !important;
    width: 640px;
    height: 430px;
    background: #FFFFFF;
    border-radius: 8px;
  }
  .el-table {
    .color-red {
      color: #FD5451;
    }

    .color-green {
      color: #04CD83;
    }

    .color-blue {
      color: #3984F5;
    }
  }
  .product-info-box{
    display: flex;
    align-items: center;
    .img-box{
      flex-shrink: 0;
      width: 72px;
      height: 72px;
      background: #CEDAE0;
      border-radius: 6px;
      margin-right: 18px;
      img{
        width: 100%;
        height: 100%;
      }
    }
    .product-info-txt{
      flex: 1;
      .product-info-title{
        font-family: Microsoft YaHei;
        font-weight: 400;
        font-size: 14px;
        color: #242D33;
        line-height: 24px;
      }
      .product-info-desc{
        font-family: Microsoft YaHei;
        font-weight: 400;
        font-size: 12px;
        color: #6C7880;
        line-height: 24px;
      }
      .product-info-specification{
        font-family: Microsoft YaHei;
        font-weight: 400;
        font-size: 12px;
        color: #3984F5;
        line-height: 24px;
      }
    }
  }
  .operate {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;

    .handle {
      display: flex;

      .all-select {
        margin-right: 16px;
        padding-left: 24px;
        line-height: 32px;
      }

      .button {
        margin-right: 10px;
        padding: 0 12px;
        height: 30px;
        line-height: 30px;
        font-size: 12px;
        color: #6C7880;
        border: 1px solid #CEDAE0;
        cursor: pointer;

        &:hover {
          color: #3984F5;
          border-color: #3984F5;
        }
      }
    }
  }
}

// 输入框样式修改
::v-deep .el-input {
  font-size: 12px;

  .el-input__inner {
    padding: 0 12px;
    height: 34px;
    line-height: 34px;
    border: 1px solid #CEDAE0;
    color: #242D33;
  }
}

// 下拉框样式修改
::v-deep .el-select {
  .el-input.is-focus .el-input__inner {
    border-color: #CEDAE0;
  }

  .el-input__icon {
    font-size: 12px;
    line-height: 34px;
  }
}

// 下拉悬浮框样式修改
::v-deep .el-select-dropdown {
  border-color: #CEDAE0;
  box-shadow: 0 0 12px rgba(36, 45, 51, 0.15);

  .el-select-dropdown__item {
    font-size: 12px;
    color: #242D33;
  }

  .el-select-dropdown__item.hover {
    background-color: transparent;
  }

  .el-select-dropdown__item:hover {
    background-color: #F6F7F9;
  }

  .el-select-dropdown__item.selected {
    color: #3984F5;
    font-weight: 400;
    background-color: #F6F7F9;
  }

  .el-select-dropdown__empty {
    font-size: 12px;
  }
}

// 日期样式修改
::v-deep .el-date-editor {
  padding: 0 12px;
  height: 34px;
  line-height: 34px;
  border: 1px solid #CEDAE0;
  color: #242D33;

  .el-range-input {
    width: 38%;
    font-size: 12px;
    color: #242D33;
  }

  .el-range-separator {
    width: 6%;
    font-size: 12px;
  }

  .el-range__close-icon {
    margin-right: -5px;
    width: 18px;
  }
}

// 表格样式修改
::v-deep .el-table {
  font-size: 12px;
  color: #242D33;
  border-color: #DCE5EC;

  &::before,
  &::after {
    background-color: #DCE5EC;
  }

  .el-table__cell {
    padding: 8px 0;
    border-color: #DCE5EC;
  }

  .cell {
    line-height: 24px;
  }

  thead {
    color: #242D33;
  }

  th.el-table__cell.is-leaf {
    border-color: #DCE5EC;
    background: #F6F7F9;
  }

  th {
    font-weight: 400;
  }

  .el-table-column--selection .cell {
    padding-right: 10px;
  }

  .el-table__body tr:hover > td.el-table__cell {
    background-color: #F6FBFE;
  }

  td.el-table__cell {
    padding: 13px 0;
  }
}

// 分页样式修改
::v-deep .el-pagination {
  padding: 0;
  color: #242D33;
  font-weight: 400;

  button {
    font-size: 12px;
    height: 32px;
    line-height: 32px;
  }

  .el-pager li {
    font-size: 12px;
    height: 32px;
    line-height: 32px;
  }

  .btn-next, .btn-prev, .el-pager li {
    border: 1px solid #CEDAE0;
    background-color: #ffffff;
    color: #242D33;
    min-width: 32px;
  }

  .btn-next:not(:disabled), .btn-prev:not(:disabled), .el-pager li {
    &:hover {
      color: #3984F5;
      border-color: #3984F5;
    }
  }

  .el-pager li:not(.disabled).active {
    background-color: #3984F5;
    border-color: #3984F5;
  }

  .btn-next.disabled, .btn-next:disabled, .btn-prev.disabled, .btn-prev:disabled, .el-pager li.disabled {
    color: #E1E8EC;
  }

  .el-pagination__sizes {
    margin-right: 0;
    height: 32px;
    line-height: 32px;
  }

  .el-select .el-input {
    margin-right: 0;

    .el-input__inner {
      font-size: 12px;
      height: 32px;
      line-height: 32px;

      &:hover {
        border-color: #3984F5;
      }
    }
  }

  .el-range-editor.is-active, .el-range-editor.is-active:hover, .el-select .el-input.is-focus .el-input__inner {
    border-color: #3984F5;
  }

  .touch-hover {
    cursor: pointer;
  }
}
</style>
