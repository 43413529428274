<template>
	<div class="">
		<search-item-vue @searchEvent="searchClick"></search-item-vue>
		<div class="list-main">
			<el-table :data="tableData" v-loading="isLoad" height="530" style="width: 100%" :header-cell-style="{height:'48px;',background:'#F6F7F9',color:'#6C7880'}" >
				<el-table-column prop="order_no" label="订单编号" width="140"></el-table-column>
				<el-table-column prop="company.company" label="下单企业" width="218"></el-table-column>
				<el-table-column prop="orderProduct" label="产品信息" width="330">
					<template slot-scope="scope">
						<span class="flex flex-col-center">
							<img class="product-img" v-for="(item, index) in scope.row.orderProduct.product_img" :key="index" :src="item" alt="" />
							<span class="product-text-box">
                <span style="font-size: 14px;color: #242D33;line-height: 36px;display: block;">{{scope.row.orderProduct.name}}</span>
                <span style="font-size: 12px;color: #6C7880;line-height: 36px;display: block;">服务商：{{scope.row.service_name ? scope.row.service_name : '平台' }}</span>
              </span>
						</span>
					</template>
				</el-table-column>
				<el-table-column prop="dict_name" label="价格/收费方式" width="228">
					<template slot-scope="scope">
						<span style="color: #FD5451;">{{scope.row.orderProduct.price}}/{{scope.row.orderProduct.dictionary.dict_name}}</span>
					</template>
				</el-table-column>
				<el-table-column prop="num" label="订购时长" width="140">
					<template slot-scope="scope">
						<span>{{scope.row.orderProduct.num}}年</span>
					</template>
				</el-table-column>
				<el-table-column prop="total_amount" label="订单金额" width="170">
					<template slot-scope="scope">
						<span style="color: #FD5451;">￥{{scope.row.total_amount}}</span>
					</template>
				</el-table-column>
				<el-table-column prop="create_time" label="下单时间" width="180"></el-table-column>
				<el-table-column label="操作" fixed="right">
					<template slot-scope="scope">
						<el-button type="text" size="small" @click="orderDetailsClick(scope.row.id)">订单详情</el-button>
						<el-button type="text" size="small" v-if="!scope.row.service_name" @click="enterOrder(scope.row.id)">立即确认</el-button>
					</template>
				</el-table-column>
			</el-table>
		</div>
		<div class="page-btn-content flex flex-col-center flex-row-end">
			 <el-pagination
			      @current-change="currentChange"
				  background
				  :pager-count="11"
			      :page-size="10"
			      layout="total, prev, pager, next"
			      :total="pageAllSize">
			</el-pagination>
		</div>
	</div>
</template>

<script>
	import searchItemVue from '../components/search-item.vue'
	export default {
		components: {
			searchItemVue
		},
		data(){
			return {
				tableData: [],
				pageIndex: 1,
				pageAllSize: 0,
				isLoad: false,
				subData: {
					page: 1,
					limit: 10,
					order_status: '1',
					product_name: '',
					order_no: '',
					start_time: '',
					end_time: ''
				},//提交数据

			}
		},
		created() {
			this.initData();
		},
		methods: {
			initData() {
				this.isLoad = true;
				this.subData.page = this.pageIndex;
				this.$api.orderPageList(this.subData).then(res=>{
					this.pageAllSize = res.data.count;
					this.tableData = res.data.data;
					this.isLoad = false;
				}).catch(err=>{
					this.isLoad = false;
				})
			},
			//查询
			searchClick(e){
				this.subData.product_name = e.name;
				this.subData.order_no = e.num;
				this.subData.start_time = e.start;
				this.subData.end_time = e.end;
				this.pageIndex = 1;
				this.initData();
			},
			//确认订单
			enterOrder(e){
				this.$confirm('是否确认办理该订单？', '提示', { confirmButtonText: '确定', cancelButtonText: '取消',type: 'warning'}).then(() => {
					this.$api.orderOrder_confirm(e).then(res=>{
						this.initData();
					})
				})
			},
			//订单详情
			orderDetailsClick(e){
				this.$router.push({path: '/orderDetails', query: {data: e}});
			},
			//翻页
			currentChange(val) {
				this.pageIndex = val;
				this.initData();
			},
		},
	}
</script>

<style lang="scss" scoped>
	.list-main{padding: 0 30px;}
	.page-btn-content{padding: 25px 30px 0 0;}
	.product-img{width: 70px;height: 70px;background: #CEDAE0;border-radius: 6px;margin-right: 10px;}

</style>
