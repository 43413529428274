<script>
import { getObjById } from '@/utils/lzzcloud.js'
import titleDom from "@/components/common/titleDom";
export default {
  name: "service-details",
  components: {titleDom},
  data() {
    return {
      status: '1', //1审核通过详情2审核3审核驳回详情
      unpackTitle: "展开",
      ruleForm: {
        id: [],
        resource: '1',
        case: ''
      },
      rules: {
        case: [
          { required: true, message: '请填写驳回原因', trigger: 'blur' }
        ]
      },
      detailData: {}, //详情数据
      cate: [], //分类
      proClass: [],//产品分类总数据
      area: [],//地区
      areaClass: [],//地区总数据
      chargeMethodList: [],//收费方式总数据
    }
  },
  methods: {
    unpackHandle() {
      if (this.unpackTitle === "展开") {
        this.unpackTitle = "收起"
      } else {
        this.unpackTitle = "展开"
      }
    },
    submitForm(formName) {
      this.ruleForm.id.push(this.$route.query.id)
      if (this.ruleForm.resource=='1'){
        delete this.ruleForm.resource;
        delete this.ruleForm.case;
        delete this.rules.case;
        this.$refs[formName].validate((valid) => {
          if (valid) {
            this.$api.auditSuccess(this.ruleForm).then(res=>{
              this.$message({type: 'success', message: '操作成功！'});
              this.status = '1'
              this.$router.replace({ path: this.$route.path, query: { ...this.$route.query, status: '1' } });
              this.getInitData(this.$route.query.id)
            })
          } else {
            console.log('error submit!!');
            return false;
          }
        });
      } else if (this.ruleForm.resource=='2') {
        delete this.ruleForm.status;
        this.$refs[formName].validate((valid) => {
          if (valid) {
            this.$api.auditFail(this.ruleForm).then(res=>{
              this.$message({type: 'success', message: '操作成功！'});
              this.status = '3'
              this.$router.replace({ path: this.$route.path, query: { ...this.$route.query, status: '3' } });
              this.getInitData(this.$route.query.id)
            })
          } else {
            console.log('error submit!!');
            return false;
          }
        });
      }
    },
    //获取分类列表,分类状态
    getProClass() {
      this.$api.cateList().then(res=>{
        this.proClass = res.data;
        this.getAddressData() // 分类请求完成再请求地区数据
      })
    },
    //获取地区数据
    getAddressData() {
      this.$api.areaTreeList().then(res => {
        this.areaClass = res.data;
        this.getInitData(this.$route.query.id) // 地区请求完成再请求详情数据
      })
    },
    // 获取收费方式分类数据
    getChargeMethod() {
      this.$api.dictionary_option_list().then(res => {
        this.chargeMethodList = res.data;
      })
    },
    // 收费方式
    chargeMethodName(id) {
      for (let i = 0; i < this.chargeMethodList.length; i++) {
        if (this.chargeMethodList[i].id == id) {
          return this.chargeMethodList[i].dict_name
        }
      }
    },
    // 详情
    getInitData(id) {
      this.$api.productInfo(id).then(res => {
        console.log(res)
        this.detailData = res.data
        for (let i = 0; i < this.detailData.cate_node_id.length; i++) {
          this.cate.push(getObjById(this.proClass, this.detailData.cate_node_id[i]).name)
        }
        for (let i = 0; i < this.detailData.area_id.length; i++) {
          this.area.push(getObjById(this.areaClass, this.detailData.area_id[i][this.detailData.area_id[i].length - 1]).name)
        }
      })
    },
  },
  mounted() {
    console.log(this.$route.query.status)
    this.status = this.$route.query.status
    this.getProClass()
    this.getChargeMethod()
  }
}
</script>

<template>
<div>
  <div class="details">
    <div class="details_header">
      <div class="company-img">
        <img :src="detailData.product_img?.[0]" alt="">
      </div>
      <div class="details_header_content">
        <div class="company-name">{{ detailData.name }}</div>
        <div class="details-type">
          <div class="details-type-name">产品分类</div>
          <div class="details-type-content classify"><span v-for="(item, index) in cate" :key="index">{{ item }}</span></div>
        </div>
        <div class="details-type">
          <div class="details-type-name">标签</div>
          <div class="details-type-content tag"><span v-for="(item, index) in detailData.tags" :key="index">{{ item }}</span></div>
        </div>
        <div class="details-area">
          <div class="details-area-name">服务地区</div>
          <div class="details-area-content" :class="{'upDown': unpackTitle === '收起'}">
            <div class="details-area-content-item" v-for="(item, index) in area" :key="index">{{item}}</div>
            <div class="details-unpack" @click="unpackHandle"><span class="unpack-title">{{unpackTitle}}</span><i :class="unpackTitle === '展开' ? 'el-icon-arrow-down' : 'el-icon-arrow-up'"></i></div>
          </div>
        </div>
        <div class="details-price">
          <div class="details-price-content">￥<span style="margin-right: 5px">{{ detailData.price }}</span>/{{ chargeMethodName(detailData.charge_method) }}</div>
          <span class="details-price-desc" v-if="detailData.charge_desc">({{ detailData.charge_desc }})</span>
        </div>
      </div>
    </div>
    <div class="project_introduction">
      <title-dom title="产品特点" :isShowLine="false"></title-dom>
      <div class="project_introduction_content">{{ detailData.feature }}</div>
    </div>
    <div class="project_introduction">
      <title-dom title="产品介绍" :isShowLine="false"></title-dom>
      <iframe style="width: 100%; height: 800px; border: none;" v-if="detailData.is_outside === 1" :src="detailData.outside_url"></iframe>
      <div v-if="detailData.is_outside === 2" class="project_introduction_content" v-html="detailData.introduce"></div>
    </div>
    <div class="providerInfo">
      <title-dom title="服务商信息" :isShowLine="false"></title-dom>
      <div class="provider-name">{{ detailData.service_name }}</div>
      <div class="provider-content">
        <div class="provider-item"><i></i>严选服务商</div>
        <div class="provider-item"><i></i>保证金保障</div>
        <div class="provider-item"><i></i>付款方式灵活</div>
        <div class="provider-item"><i></i>平台交易担保</div>
      </div>
    </div>
  </div>
  <!--  待审核显示-->
  <div v-if="status == 2" class="audit-info">
    <title-dom title="审核信息" :isShowLine="false"></title-dom>
    <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
      <el-form-item label="审核结果">
        <el-radio-group v-model="ruleForm.resource">
          <el-radio label="1">审核通过</el-radio>
          <el-radio label="2">审核驳回</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="驳回原因" prop="case" v-if="ruleForm.resource == '2'">
        <el-input type="textarea" v-model="ruleForm.case" placeholder="请输入驳回原因"></el-input>
      </el-form-item>
      <el-form-item>
        <el-button style="width: 100px;" type="primary" @click="submitForm('ruleForm')">确定</el-button>
        <el-button style="width: 100px;background: #EEF1F3;color: #6C7880" @click="$router.go(-1)">返回</el-button>
      </el-form-item>
    </el-form>
  </div>
  <!--  已审核-->
  <div v-if="status == 3" class="audited">
    <title-dom title="审核信息" :isShowLine="false"></title-dom>
    <div class="audited-content">
      <div class="audited-info-box">
        <div class="audited-info-item part1">
          <div class="name">审核结果：</div>
          <div class="item-content red">已驳回</div>
        </div>
        <div class="audited-info-item">
          <div class="name">审核时间：</div>
          <div class="item-content">{{ parseTime(detailData.check_time) }}</div>
        </div>
      </div>
      <div class="audited-reason">
        <div class="name">驳回原因：</div>
        <div class="item-content">{{ detailData.case }}</div>
      </div>
    </div>
  </div>
  <div v-if="status == 3" class="back-btn">
    <span @click="$router.go(-1)">返回</span>
  </div>
</div>
</template>

<style scoped lang="scss">
.details{
  background-color: #fff;
  margin: 18px;
  padding: 30px;
  box-sizing: border-box;
  .details_header{
    display: flex;
    border-bottom: 1px solid #F1F4F7;
    padding-bottom: 30px;
    .company-img{
      flex-shrink: 0;
      width: 220px;
      height: 220px;
      background: #ECF1F7;
      border-radius: 6px;
      margin-right: 30px;
      img{
        border-radius: 6px;
        width: 100%;
        height: 100%;
      }
    }
    .details_header_content{
      flex: 1;
      .company-name{
        font-family: Microsoft YaHei;
        font-weight: 400;
        font-size: 20px;
        color: #242D33;
      }
      .details-desc{
        font-family: Microsoft YaHei;
        font-weight: 400;
        font-size: 12px;
        color: #6C7880;
        margin-top: 15px;
      }
      .details-type{
        margin-top: 15px;
        display: flex;
        align-items: center;
        .details-type-name{
          margin-right: 10px;
          width: 50px;
          text-align: right;
          flex-shrink: 0;
          font-family: Microsoft YaHei;
          font-weight: 400;
          font-size: 12px;
          color: #6C7880;
        }
        .details-type-content{
          font-family: Microsoft YaHei;
          font-weight: 400;
          font-size: 12px;
          color: #242D33;
        }
        .classify {
          span {
            &:after {
              content: '>';
              margin: 0 5px;
              font-family: SimSun;
              color: #909DA5;
            }
            &:last-child {
              &:after {
                content: '';
              }
            }
          }
        }
        .tag {
          display: flex;
          span {
            margin-right: 8px;
            border-radius: 4px;
            padding: 0 12px;
            background: #EAF3FF;
            color: #1C72F1;
            line-height: 30px;
          }
        }
      }
      .details-area{
        margin-top: 20px;
        display: flex;
        .details-area-name{
          flex-shrink: 0;
          font-family: Microsoft YaHei;
          font-weight: 400;
          font-size: 12px;
          color: #6C7880;
          margin-right: 10px;
          padding-top: 7px;
        }
        .details-area-content{
          display: flex;
          flex-wrap: wrap;
          width: 80%;
          height: 30px;
          overflow: hidden;
          position: relative;
          &.upDown{
            height: auto;
          }
          .details-area-content-item{
            margin-right: 8px;
            border-radius: 4px;
            padding: 10px 13px 9px 12px;
            box-sizing: border-box;
            background: #F1F4F8;
            font-family: Microsoft YaHei;
            font-weight: 400;
            font-size: 12px;
            line-height: 12px;
            color: #6C7880;
            margin-bottom: 8px;
          }
          .details-unpack{
            position: absolute;
            right: -8px;
            top: 0;
            width: 72px;
            height: 30px;
            background: #EAF3FF;
            border-radius: 4px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            font-size: 12px;
            color: #1C72F1;
            text-align: center;
            line-height: 30px;
            cursor: pointer;
            .unpack-title{
              margin-right: 10px;
            }
          }
        }
      }
      .details-price{
        margin-top: 20px;
        display: flex;
        align-items: center;
        .details-price-name{
          font-family: Microsoft YaHei;
          font-weight: 400;
          font-size: 24px;
          color: #FD5451;
          margin-right: 33px;
        }
        .details-price-content{
          display: flex;
          align-items: center;
          font-family: Microsoft YaHei;
          font-weight: 400;
          font-size: 14px;
          color: #FD5451;
          span{
            font-size: 24px;
          }
        }
        .details-price-desc {
          margin-left: 18px;
          font-size: 12px;
          color: #6C7880;
        }
      }
    }
  }
  .project_introduction{
    margin-top: 30px;
    .project_introduction_content{
      margin-top: 10px;
    }
  }
  .providerInfo{
    margin-top: 30px;
    .provider-name{
      font-family: Microsoft YaHei;
      font-weight: 400;
      font-size: 18px;
      color: #242D33;
      margin-top: 10px;
    }
    .provider-content{
      margin-top: 10px;
      display: flex;
      .provider-item{
        margin-right: 40px;
        display: flex;
        align-items: center;
        font-family: Microsoft YaHei;
        font-weight: 400;
        font-size: 14px;
        color: #444F57;
        line-height: 28px;
        i{
          width: 16px;
          height: 16px;
          background: url(~@/assets/productLibrary/ensure_icon@2x.png) no-repeat 0 0;
          background-size: 100% 100%;
          margin-right: 7px;
        }
      }
    }
  }
}
.audit-info,.audited,.back-btn{
  background-color: #fff;
  margin: 12px 18px 18px 18px;
  padding: 30px;
  box-sizing: border-box;
  .demo-ruleForm{
    margin-top: 20px;
  }
  :deep .el-textarea__inner{
    width: 600px;
    height: 80px;
  }
}
.audited{
  .audited-content{
    .audited-reason{
      display: flex;
      align-items: center;
    }
    .name{
      font-family: Microsoft YaHei;
      font-weight: 400;
      font-size: 12px;
      color: #6C7880;
      line-height: 28px;
    }
    .item-content{
      font-family: Microsoft YaHei;
      font-weight: 400;
      font-size: 12px;
      color: #242D33;
      line-height: 28px;
    }
    .audited-info-box{
      display: flex;
      .audited-info-item{
        display: flex;
        align-items: center;
      }
      .part1{
        flex-shrink: 0;
        width: 300px;
        .item-content{
          &.red{
            color: #FD5451;
          }
          &.green{
            color: #04CD83;
          }
        }
      }
    }
  }
}
.back-btn{
  span{
    width: 100px;
    height: 44px;
    background: #EEF1F3;
    border-radius: 4px;
    display: block;
    font-family: Microsoft YaHei;
    font-weight: 400;
    font-size: 14px;
    color: #6C7880;
    line-height: 44px;
    text-align: center;
    cursor: pointer;
  }
}
:deep input[aria-hidden="true"] {
  display: none !important;
}

:deep.el-radio:focus:not(.is-focus):not(:active):not(.is-disabled) .el-radio__inner {
  box-shadow: none !important;
}

</style>
