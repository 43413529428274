import Vue from "vue"
import VueRouter from "vue-router"
import * as auth from "@/utils/auth.js"
import NProgress from "nprogress"
import "nprogress/nprogress.css";
import store from "@/store"
import {MessageBox} from 'element-ui'

NProgress.configure({showSpinner: false});
Vue.use(VueRouter)

import index from "@/views/index.vue"

const routes = new VueRouter({
    routes: [
        {
            path: '/redirect',
            component: index,
            hidden: true,
            children: [
                {
                    path: '/redirect/:path(.*)',
                    component: () => import('@/views/redirect')
                }
            ]
        },
        {
            path: '/login',
            name: 'login',
            component: () => import('@/views/login/index'),
            meta: {
                title: '登录'
            }
        },
        {
            path: '/pdfReport',
            name: 'PdfReport',
            component: () => import('@/views/diagnosticReport/pdfReport'),
            meta: {
                title: '诊断报告-pdf'
            }
        },
        {
            path: '/preview',
            name: 'Preview',
            component: () => import('@/views/statistics/preview'),
            meta: {
                title: '预设题库'
            }
        },
        {
            path: '/suervey',
            name: 'Suervey',
            component: () => import('@/views/statistics/looksurvey'),
            meta: {
                title: '统计分析'
            }
        },
        {
            path: '/suervey1',
            name: 'Suervey1',
            component: () => import('@/views/statistics/looksurvey1'),
            meta: {
                title: '统计分析'
            }
        },
        {
            path: '/',
            name: 'index',
            component: index,
            meta: {
                title: ''
            },
            redirect: '/questionnaire',
            children: [
                {
                    path: 'index',
                    name: 'Index',
                    redirect: '/questionnaire',
                    meta: { title: '' }
                },
                {
                    path: 'userPanel',
                    name: 'UserPanel',
                    component: () => import('@/views/system/user/userPanel.vue'),
                    meta: {
                        title: '个人中心'
                    }
                },
                {
                    path: '/questionnaireData',
                    name: 'QuestionnaireData',
                    component: () => import('@/views/questionnaire/questionnare-data'),
                    meta: {
                        title: '数据'
                    }
                },
                {
                    path: '/statistical',
                    name: 'Statistical',
                    component: () => import('@/views/statistics/components/statistical'),
                    meta: {
                        title: '统计分析'
                    }
                },
                {
                    path: '/report',
                    name: 'Report',
                    component: () => import('@/views/statistics/report'),
                    meta: {
                        title: '统计分析'
                    }
                },
                {
                    path: '/statistics',
                    name: 'Statistics',
                    component: () => import('@/views/statistics/index'),
                    meta: {
                        title: '统计分析'
                    }
                },
                {
                    path: '/sheet',
                    name: 'Sheet',
                    component: () => import('@/views/statistics/sheet'),
                    meta: {
                        title: '统计分析'
                    }
                },
                {
                    path: '/productLibrary',
                    name: 'productLibrary',
                    meta: {
                        title: '产品库'
                    },
                    component: () => import('@/views/productLibrary/index'),
                    redirect: '/productHome',
                    children: [
                        {
                            path: '/productHome',
                            name: 'productHome',
                            meta: {
                                title: '产品管理'
                            },
                            component: () => import('@/views/productLibrary/home')
                        },
                        {
                            path: '/addProduct',
                            name: 'addProduct',
                            meta: {
                                title: '添加产品'
                            },
                            component: () => import('@/views/productLibrary/add-product')
                        },
                        {
                            path: '/productDetail',
                            name: 'ProductDetail',
                            meta: {
                                title: '产品详情'
                            },
                            component: () => import('@/views/productLibrary/details')
                        }
                    ]
                },
                {
                    path: '/productManage',
                    name: 'productManage.index',
                    meta: {
                        title: '产品库'
                    },
                    component: () => import('@/views/productLibrary/productManage/index'),
                    children: [
                        {
                            path: '/classify',
                            name: 'classify',
                            meta: {
                                title: '分类管理'
                            },
                            component: () => import('@/views/productLibrary/productManage/classify')
                        },
                        {
                            path: '/addClassify',
                            name: 'addClassify',
                            meta: {
                                title: '添加分类'
                            },
                            component: () => import('@/views/productLibrary/productManage/add-classify')
                        }
                    ]
                },
                {
                    path: '/serverManage',
                    name: 'serverManage.index',
                    meta: {
                        title: '服务项目管理'
                    },
                    component: () => import('@/views/productLibrary/serviceManage/index'),
                    children: [
                        {
                            path: '/serviceHome',
                            name:'serviceHome',
                            meta: {
                                title: '服务项目'
                            },
                            component: () => import('@/views/productLibrary/serviceManage/home')
                        },
                        {
                            path: '/serviceDetail',
                            name: 'serviceDetail',
                            meta: {
                                title: '服务项目管理详情'
                            },
                            component: () => import('@/views/productLibrary/serviceManage/details')
                        },
                    ]
                },
                {
                	path: '/orderManage',
                	name: 'order.manage.index',
                	redirect: '/productOrder',
                	meta: {
                		title: '订单管理'
                	},
                	component: ()=> import('@/views/orderManage/index'),
                	children: [
                		{
                			path: '/productOrder',
                			name: 'product.order',
                			meta:{
                				title: ''
                			},
							redirect: '/allList',
                			component: ()=> import('@/views/orderManage/product-order'),
							children: [
								{
									path: '/allList',
									name: 'all.list',
									meta:{
										title: '产品订单管理'
									},
									component: ()=> import('@/views/orderManage/orderList/all-list'),
								},
								{
									path: '/waitEnterList',
									name: 'wait.enter.list',
									meta:{
										title: '产品订单管理'
									},
									component: ()=> import('@/views/orderManage/orderList/wait-enter-list'),
								},
								{
									path: '/waitHandleList',
									name: 'wait.handle.list',
									meta:{
										title: '产品订单管理'
									},
									component: ()=> import('@/views/orderManage/orderList/wait-handle-list'),
								},
								{
									path: '/orderOverList',
									name: 'order.over.list',
									meta:{
										title: '产品订单管理'
									},
									component: ()=> import('@/views/orderManage/orderList/order-over-list'),
								}
							]
                		},
                		{
                			path: '/ticketManage',
                			name: 'ticket.manage',
                			meta:{
                				title: ''
                			},
							redirect: '/ticketHome',
                			component: ()=> import('@/views/orderManage/ticket-manage'),
							children: [
								{
									path: '/ticketHome',
									name: 'ticket.home',
									meta:{
										title: '开票管理'
									},
									component: ()=> import('@/views/orderManage/ticket-home'),
								},
								{
									path: '/ticketDetails',
									name: 'ticket.details',
									meta:{
										title: '开票详情'
									},
									component: ()=> import('@/views/orderManage/ticket-details'),
								}
							]
                		},
						{
							path: '/orderDetails',
							name: 'order.details',
							meta:{
								title: '订单详情'
							},
							component: ()=> import('@/views/orderManage/orderList/order-details'),
						},
                        {
                            path: '/serviceRating',
                            name: 'serviceRating',
                            meta:{
                                title: '服务评价'
                            },
                            component: ()=> import('@/views/orderManage/serviceRating'),
                        },
                        {
                            path: '/itemOrder',
                            name: 'itemOrder',
                            meta:{
                                title: '服务项目订单'
                            },
                            component: ()=> import('@/views/orderManage/item-order.vue'),
                        },
                        {
                            path: '/itemDetail',
                            name: 'itemDetail',
                            meta:{
                                title: '服务项目订单详情'
                            },
                            component: ()=> import('@/views/orderManage/serviceItemOrder/item-detail.vue'),
                        }
                	]
                },
            ]
        },
        {
            path: '/dataBoard',
            name: 'DataBoard',
            meta: {
                title: '数据看板'
            },
            component: index,
            children:[
                {
                    path: '/dataBoard',
                    name: 'dataBoard.index',
                    component: ()=> import('@/views/dataBoard/index'),
                    meta: {
                        title: '企业数据看板'
                    }
                },
                {
                    path: '/productDataBoard',
                    name: 'product.dataBoard',
                    component: ()=> import('@/views/dataBoard/index'),
                    meta: {
                        title: '产品数据看板'
                    }
                },
                {
                    path: '/gridDataBoard',
                    name: 'grid.dataBoard',
                    component: ()=> import('@/views/dataBoard/index'),
                    meta: {
                        title: '网格数据看板'
                    }
                }
            ]
        },

        {
            path:'/serverBigManage',
            name:'serverBigManage',
            meta: {
                title: '服务商管理'
            },
            component: index,
            redirect: '/serverBigHome',
            children:[
                {
                    path: '/serverBigHome',
                    name:'serverBigHome',
                    component: ()=> import('@/views/serverManage/index'),
                    meta: {
                        title: '意见反馈处理'
                    },
                },
                {
                    path: '/feedbackProcess',
                    name: 'feedbackProcess',
                    component: ()=> import('@/views/serverManage/components/feedback/feedbackProcessing'),
                    meta: {
                        title: '反馈处理'
                    }
                },
                {
                    path: '/handleDetail',
                    name: 'handleDetail',
                    component: ()=> import('@/views/serverManage/components/feedback/handleDetail'),
                    meta: {
                        title: '已处理详情'
                    }
                },
                {
                    path: '/serverList',
                    name: 'serverList',
                    component: ()=> import('@/views/serverManage/serverList.vue'),
                    meta: {
                        title: '服务商列表'
                    }
                },
                {
                  path: '/listDetail',
                  name:'listDetail',
                  component:()=>import('@/views/serverManage/components/list/listDetails'),
                  meta:{
                      title: '服务商详情'
                  }
                },
                {
                    path: '/listEdit',
                    name:'listEdit',
                    component:()=>import('@/views/serverManage/components/list/edit.vue'),
                    meta:{
                        title: '服务商编辑'
                    }
                },
                {
                    path: '/serverProvider',
                    name:'serverProvider',
                    component:()=>import('@/views/serverManage/provider.vue'),
                    meta:{
                        title: '服务商认证审核'
                    }
                },
                {
                    path: '/audiExamine',
                    name:'audiExamine',
                    component:()=>import('@/views/serverManage/components/audit/examine.vue'),
                    meta:{
                        title: '服务商审核'
                    }
                }
            ]
        },
        {
            path: '/oneEnterOneFile',
            name: 'OneEnterOneFile',
            meta: {
                title: '一企一档'
            },
            component: index,
			redirect: '/enterpriseList',
            children:[
                {
                    path: '/enterpriseList',
                    name: 'EnterpriseList',
                    component: () => import('@/views/enterpriseList/index'),
                    meta: {
                        title: '企业列表'
                    },
					redirect: '/home',
					children: [
						{
							path: '/home',
							name: 'index.home',
							component: ()=> import('@/views/enterpriseList/home'),
							meta: {
								title: ''
							}
						},
						{
							path: '/edit',
							name: 'edit.index',
							component: ()=> import('@/views/enterpriseList/edit'),
							meta: {
								title: '编辑企业信息'
							}
						}
					]
                },
                {
                    path: '/companyImg',
                    name: 'companyImg',
                    component: () => import('@/views/companyImg/index'),
                    meta: {
                        title: '企业画像'
                    }
                },
                {
                    path: '/editList',
                    name: 'EditList',
                    component: () => import('@/views/companyImg/editList'),
                    meta: {
                        title: '数转诊断'
                    }
                },
                {
                    path: '/diagnosticReport',
                    name: 'DiagnosticReport',
                    component: () => import('@/views/diagnosticReport/index'),
                    meta: {
                        title: '诊断报告'
                    }
                },
                {
                    path: '/diagnosticDetail',
                    name: 'DiagnosticDetail',
                    component: () => import('@/views/diagnosticReport/detail'),
                    meta: {
                        title: '报告详情'
                    }
                },
                {
                    path: '/enterpriseCertification',
                    name: 'EnterpriseCertification',
                    component: () => import('@/views/enterpriseCertification/index.vue'),
                    meta: {
                        title: '企业认证审核'
                    }
                },
                {
                    path: '/examine',
                    name: 'Examine',
                    component: () => import('@/views/enterpriseCertification/examine.vue'),
                    meta: {
                        title: '审核'
                    }
                },
                {
                    path: '/certificationDetails',
                    name: 'CertificationDetails',
                    component: () => import('@/views/enterpriseCertification/details.vue'),
                    meta: {
                        title: '详情'
                    }
                },
                {
                    path: '/enterpriseFeedback',
                    name: 'EnterpriseFeedback',
                    component: () => import('@/views/enterpriseFeedback/index.vue'),
                    meta: {
                        title: '投诉建议处理'
                    }
                },
                {
                    path: '/feedbackResponse',
                    name: 'FeedbackResponse',
                    component: () => import('@/views/enterpriseFeedback/response.vue'),
                    meta: {
                        title: '投诉回复'
                    }
                },
                {
                    path: '/feedbackDetail',
                    name: 'FeedbackDetail',
                    component: () => import('@/views/enterpriseFeedback/detail.vue'),
                    meta: {
                        title: '投诉详情'
                    }
                },
            ]
        },
        {
            path: '/consultation',
            name: 'Consultation',
            meta: {
                title: '企业问诊'
            },
            component: index,
            children:[
                {
                    path: '/questionnaire',
                    name: 'Questionnaire',
                    component: () => import('@/views/questionnaire/index'),
                    meta: {
                        title: '调研问卷'
                    }
                },
                {
                    path: '/questionnaireEdit',
                    name: 'QuestionnaireEdit',
                    component: () => import('@/views/questionnaire/edit'),
                    meta: {
                        title: '调研问卷'
                    }
                },
                {
                    path: '/sendList',
                    name: 'sendList',
                    component: () => import('@/views/questionnaire/send-list'),
                    meta: {
                        title: '发送名单'
                    }
                },
                {
                    path: '/sendQuestionnaire',
                    name: 'sendQuestionnaire',
                    component: () => import('@/views/sendQuestionnaire/index'),
                    meta: {
                        title: '发送问卷'
                    }
                },
                {
                    path: '/answerSheet',
                    name: 'AnswerSheet',
                    component: () => import('@/views/answerSheet/index'),
                    meta: {
                        title: '企业答卷'
                    }
                },
                {
                    path: '/questionBank',
                    name: 'questionBank',
                    component: () => import('@/views/questionBank/index'),
                    meta: {
                        title: '预设题库'
                    }
                },
            ]
        },
        {
            path: '/system',
            name: 'System',
            meta: {
                title: '系统设置'
            },
            component: index,
            children: [
                {
                    path: '/user',
                    name: 'User',
                    component: () => import('@/views/system/user'),
                    meta: {
                        title: '员工账号'
                    }
                },
                {
                    path: '/addUser',
                    name: 'AddUser',
                    component: () => import('@/views/system/user/editList'),
                    meta: {
                        title: '添加账号'
                    }
                },
                {
                    path: '/editUser',
                    name: 'EditUser',
                    component: () => import('@/views/system/user/editList'),
                    meta: {
                        title: '编辑账号'
                    }
                },
                {
                    path: '/roles',
                    name: 'Roles',
                    component: () => import('@/views/system/roles'),
                    meta: {
                        title: '权限管理'
                    }
                },
                {
                    path: '/addRoles',
                    name: 'AddRoles',
                    component: () => import('@/views/system/roles/editList.vue'),
                    meta: {
                        title: '添加角色'
                    }
                },
                {
                    path: '/editRoles',
                    name: 'EditRoles',
                    component: () => import('@/views/system/roles/editList.vue'),
                    meta: {
                        title: '编辑角色'
                    }
                },
                {
                    path: '/dict',
                    name: 'Dict',
                    component: () => import('@/views/system/dict'),
                    meta: {
                        title: '数据字典'
                    }
                },
                {
                    path: '/detailDict',
                    name: 'DetailDict',
                    component: () => import('@/views/system/dict/detail.vue'),
                    meta: {
                        title: '配置字典'
                    }
                },
                {
                    path: '/basics',
                    name: 'Basics',
                    component: () => import('@/views/system/basics'),
                    meta: {
                        title: '基础设置'
                    }
                },
                {
                    path: '/organization',
                    name: 'Organization',
                    component: () => import('@/views/system/organization'),
                    meta: {
                        title: '组织机构'
                    },
                },{
                    path: '/addOrganization',
                    name: 'AddOrganization',
                    component: () => import('@/views/system/organization/editList.vue'),
                    meta: {
                        title: '组织机构'
                    },
                },{
                    path: '/editOrganization',
                    name: 'EditOrganization',
                    component: () => import('@/views/system/organization/editList.vue'),
                    meta: {
                        title: '组织机构'
                    },
                },{
                    path: '/dataReporting',
                    name: 'DataReporting',
                    component: () => import('@/views/system/organization/dataReporting/index.vue'),
                    meta: {
                        title: '组织机构'
                    },
                },
				{
				    path: '/commentSet',
				    name: 'comment.set',
				    component: () => import('@/views/system/commentSet'),
				    meta: {
				        title: '评语设置'
				    }
				},
                {
                    path: '/logs',
                    name: 'Logs',
                    component: () => import('@/views/system/logs'),
                    meta: {
                        title: '系统日志'
                    }
                },
            ]
        },
        {
            path: '/business',
            name: 'business',
            meta: {
                title: '商机库'
            },
            component: index,
            children:[
                {
                    path: '/businessLeads',
                    name: 'BusinessLeads',
                    component: () => import('@/views/businessLeads/index'),
                    meta: {
                        title: '商机线索'
                    }
                },
                {
                    path: '/businessDetail',
                    name: 'BusinessDetail',
                    component: () => import('@/views/businessLeads/detail'),
                    meta: {
                        title: '商机详情'
                    }
                },
                {
                    path: '/editorial',
                    name: 'Editorial',
                    component: () => import('@/views/businessLeads/editorial'),
                    meta: {
                        title: '编辑商机'
                    },
                },
                {
                    path: '/enter',
                    name: 'Enter',
                    component: () => import('@/views/businessLeads/enter'),
                    meta: {
                        title: '录入商机'
                    },
                },
                {
                    path: '/businessVisit',
                    name: 'BusinessVisit',
                    component: () => import('@/views/businessVisit/index'),
                    meta: {
                        title: '企业拜访'
                    }
                },
                {
                    path: '/visitDetail',
                    name: 'VisitDetail',
                    component: () => import('@/views/businessVisit/detail'),
                    meta: {
                        title: '拜访详情'
                    }
                },
            ]
        },
        {
            path: '/:catchAll(.*)',
            name: 'not-found',
            meta: {
                title: '错误'
            },
            component: () => import('@/views/404/index')
        }
    ],
    mode: 'hash'//hash  --  history
})

routes.beforeEach(async (to, from, next) => {
    if (from.path === '/dataReporting' && store.state.editStatus === 1) {
        let to = false;
        await MessageBox.confirm('当前页面内容暂未保存，确定切换标签吗？', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
        }).then(() => {
            to =  false;
            // 用户确认切换
        }).catch(() => {
            // 用户取消切换
            to =  true;
        });
        if (to) {
            return;
        }
    }
    NProgress.start();
    document.title = to.meta.title;
    console.log(to);
    // if (to.path==='/' || to.path==='/index.html'){
    // 	if (to.path==='/login'){
    // 		next();
    // 	}
    // 	else {
    // 		next('/login');
    // 	}
    // } else {
    // let token = auth.getToken();
    // 	if (!token){
    // 		next('/login');
    // 	} else {
    // 		next();
    // 	}
    // }
    if (to.path === '/login') {
        next();
    } else {
        let token = auth.getToken();
        if (!token) {
            next('/login');
        } else {
            next();
        }
    }

})

routes.afterEach(() => {
    NProgress.done();
})

export default routes;
