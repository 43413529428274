<script>
import {orderPageList} from "../../request/orderListApi";


export default {
  name: "serviceRating",
  data() {
    return {
      dialogVisible: false,
      nameRate: 4,
      serveRate: 3,
      maxHeight: "0",
      screen: {
        page: 1,
        limit: 10,
        order_type: "1", //订单类型 	1:服务产品订单 2:服务项目订单
        order_no: "", //订单编号
        company: "", //下单企业
        service_name: "", //服务名称
        evaluate_status: "", //需要查询评分就传这个 1:待评价 2:已评价
        order_status: '4'
      },
      stepData: {
        data:[
          {title:'步骤1',description:'提交订单'},
            {title:'步骤2',description:'服务人员接单'},
          {title:'步骤3',description:'服务人员上门'},
          {title:'步骤3',description:'服务人员上门'},
          {title:'步骤3',description:'服务人员上门'},
        ],
        currentStep:2,
      }, //步骤数据
      tableData: {
        data: [
          {
            id: 1,
            company: "202405301234",
          }
        ],
        loading: false,
        total: 0,
        isIndeterminate: false,
        allSelect: false
      },
      dictData: {
        statusOptions: [
          {label: "待评价", value: "1"},
          {label: "已评价", value: "2"}
        ],
        productTypeOptions: [
          {label: "服务产品订单", value: "1"},
          {label: "服务项目订单", value: "2"}
        ]
      },
      detailData: {}, //详情数据
      registrationTime: [],
      addressData: [],
      address: [],
      gridOptions: [],
      consultationStatusOptions: []
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.updateMaxHeight();
      window.addEventListener('resize', this.updateMaxHeight);
    });
    this.getTableData()
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.updateMaxHeight); // 组件销毁前移除事件监听
  },
  methods: {
    // 获取列表数据
    getTableData() {
      this.tableData.loading = true;
      if (this.screen.order_type == '2') {
        this.screen.order_status = '6'
      } else {
        this.screen.order_status = '4'
      }
      this.$api.orderPageList(this.screen).then(res => {
        this.tableData.data = res.data.data;
        this.tableData.total = res.data.count;
        this.tableData.loading = false;
        console.log(res,'列表')
      })
    },
    // 切换页码
    handleCurrentChange(currentPage) {
      this.screen.page = currentPage;
      this.getTableData();
    },
    // 切换每页显示数量
    handleSizeChange(pageSize) {
      this.screen.page = 1;
      this.screen.limit = pageSize;
      this.getTableData();
    },
    // 切换选择
    handleSelectionChange(val) {
      this.tableData.isIndeterminate = false;
      this.tableData.allSelect = false;
      if (val.length === this.tableData.data.length) {
        this.tableData.allSelect = true;
      } else if (val.length > 0) {
        this.tableData.isIndeterminate = true;
      }
    },
    // 全选
    toggleSelection() {
      if (this.tableData.allSelect) {
        this.tableData.data.forEach(item => {
          this.$refs.multipleTable.toggleRowSelection(item);
        });
      } else {
        this.$refs.multipleTable.clearSelection();
      }
      this.tableData.isIndeterminate = false;
    },
    // 重置查询条件
    resetQuery() {
      this.screen = {
        page: 1,
        limit: 10,
        order_type: "1", //订单类型 	1:服务产品订单 2:服务项目订单
        order_no: "", //订单编号
        company: "", //下单企业
        service_name: "", //服务名称
        evaluate_status: "", //需要查询评分就传这个 1:待评价 2:已评价
        order_status: '4'
      };
      this.registrationTime = [];
      this.addressData = [];
      this.address = [];
      this.gridOptions = [];
      this.consultationStatusOptions = [];
      this.getTableData();
    },
    // 查询
    search() {
      this.screen.page = 1;
      console.log(this.screen)
      this.getTableData();
    },
    // 处理日期
    handleChangeDate(val) {
      this.registrationTime = val;
    },
    // 处理地址
    handleChange(val) {
      this.addressData = val;
      this.address = [];
      val.forEach(item => {
        this.address.push(item.name);
      });
    },
    //编辑
    handleView(scope){
      this.detailData = scope;
      this.nameRate = scope.experience;
      this.serveRate = scope.service_rating;
      console.log(this.detailData,'查看评价')
      this.dialogVisible = true;
    },
    // 关闭弹窗
    handleClose(done) {
      done();
    },
    getRate(num){
      switch (num) {
        case 1:
          return '1分（非常不满意）';
        case 2:
          return '2分（不满意）';
        case 3:
          return '3分（一般）';
        case 4:
          return '4分（满意）';
        case 5:
          return '5分（非常满意）';
        default:
          return '未评分';
      }
    },
    updateMaxHeight() {
      // 获取父元素的高度
      const containerHeight = this.$refs.container.clientHeight;
      this.maxHeight = `${containerHeight - 148}px`;
    },
    // 规格内容
    getSpec (price){
      if (Array.isArray(price)) {
        let value = ''
        price.forEach((item) => {
          if (item[2] == '1') {
            value = item[0]
            return
          }
        })
        return value
      } else {
        return ''
      }
    }
  },
}
</script>

<template>
<div class="container" ref="container">
  <div class="screen clearfix">
    <div class="item">
      <span>订单类型</span>
      <div class="box">
        <el-select v-model="screen.order_type" :popper-append-to-body="false" placeholder="全部"
                   style="width: 140px">
          <el-option
              v-for="item in dictData.productTypeOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value">
          </el-option>
        </el-select>
      </div>
    </div>
    <div class="item">
      <span>订单编号</span>
      <div class="box">
        <el-input v-model="screen.order_no" placeholder="输入订单编号" style="width: 200px"></el-input>
      </div>
    </div>
    <div class="item">
      <span>下单企业</span>
      <div class="box">
        <el-input v-model="screen.company" placeholder="输入下单企业名称" style="width: 200px"></el-input>
      </div>
    </div>
    <div class="item">
      <span>服务商</span>
      <div class="box">
        <el-input v-model="screen.service_name" placeholder="输入服务商名称" style="width: 200px"></el-input>
      </div>
    </div>
    <div class="item">
      <span>评价状态</span>
      <div class="box">
        <el-select v-model="screen.evaluate_status" :popper-append-to-body="false" placeholder="全部"
                   style="width: 120px">
          <el-option
              v-for="item in dictData.statusOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value">
          </el-option>
        </el-select>
      </div>
    </div>
    <span class="button inquire" @click="search">查询</span>
    <span class="button" @click="resetQuery">重置</span>
  </div>
  <el-table
      ref="multipleTable"
      :data="tableData.data"
      :border="true"
      v-loading="tableData.loading"
      style="width: 100%"
      :max-height="maxHeight"
  >
    <el-table-column prop="order_no" label="订单编号" width="120"></el-table-column>
    <el-table-column prop="company.company" label="下单企业" width="180"></el-table-column>
    <el-table-column label="产品/项目信息" width="400">
       <template slot-scope="scope">
          <div class="product-info-box" v-if="scope.row.orderProduct">
            <div class="img-box">
              <img :src="scope.row.orderProduct.product_img[0]" alt="">
            </div>
            <div class="product-info-txt">
              <div class="product-info-title">{{ scope.row.orderProduct.name }}</div>
              <div class="product-info-desc">服务商：{{ scope.row.service_name ? scope.row.service_name : '平台' }}</div>
            </div>
          </div>
         <div class="product-info-box" v-if="scope.row.orderProject">
           <div class="img-box">
             <img :src="scope.row.orderProject.product_img" alt="">
           </div>
           <div class="product-info-txt">
             <div class="product-info-title">{{ scope.row.orderProject.name }}</div>
             <div class="product-info-desc">服务商：{{ scope.row.service_name }}</div>
             <div v-if="scope.row.orderProject?.charge_method == 3 || scope.row.orderProject?.charge_method == 4" class="product-info-specification">规格：{{ getSpec(scope.row.orderProject?.price) }}</div>
           </div>
         </div>
       </template>
    </el-table-column>
    <el-table-column prop="total_amount" label="订单金额">
      <template slot-scope="scope">
        <span class="color-red">￥{{ scope.row.total_amount }}</span>
      </template>
    </el-table-column>
    <el-table-column prop="create_time" label="下单时间" width="240"></el-table-column>
    <el-table-column prop="evaluate_status" label="评价状态">
      <template slot-scope="scope">
        <span v-if="scope.row.evaluate_status == 1">待评价</span>
        <span v-else-if="scope.row.evaluate_status == 2">已评价</span>
        <span v-else>--</span>
      </template>
    </el-table-column>
    <el-table-column label="评价时间" align="center">
      <template slot-scope="scope">
        <span v-if="scope.row.evaluate_time">{{parseTime(scope.row.evaluate_time)}}</span>
        <span v-else>--</span>
      </template>
    </el-table-column>
    <el-table-column prop="id" fixed="right" label="操作" width="100">
      <template slot-scope="scope">
        <div class="flex flex-col-center flex-row-between">
          <span v-if="scope.row.evaluate_status == 2" class="color-blue touch-hover" @click="handleView(scope.row)">查看评价</span>
          <span v-else>--</span>
        </div>
      </template>
    </el-table-column>
  </el-table>
  <div class="operate">
    <div class="handle">

    </div>
    <el-pagination
        background
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="screen.page"
        :page-sizes="[10, 20, 30, 40]"
        :page-size="screen.limit"
        layout="prev, pager, next, sizes"
        :total="tableData.total">
    </el-pagination>
  </div>
<!--  <div style="height: 150px;">
    <my-steps :steps="stepData.data" :currentStep="stepData.currentStep" size="24px" type="check" mode="check" alignCenter></my-steps>
  </div>-->
  <el-dialog
      title="评价详情"
      :visible.sync="dialogVisible"
      width="30%"
      :before-close="handleClose">
    <div class="dialog-content">
      <div class="detail-header" v-if="detailData.orderProduct">
        <div class="product-img"><img :src="detailData.orderProduct.product_img[0]" alt=""></div>
        <div class="product-info-box-detail">
          <div class="product-info-txt">{{detailData.orderProduct.name}}</div>
          <div class="product-info-gg">服务商：{{ detailData.service_name ? detailData.service_name : '平台' }}</div>
        </div>
      </div>
      <div class="detail-header" v-if="detailData.orderProject">
        <div class="product-img"><img :src="detailData.orderProject.product_img" alt=""></div>
        <div class="product-info-box-detail">
          <div class="product-info-txt">{{detailData.orderProject.name}}</div>
          <div class="product-info-gg">服务商：{{ detailData.service_name}}</div>
          <div v-if="detailData.orderProject?.charge_method == 3 || detailData.orderProject?.charge_method == 4" class="product-info-spec">规格：{{ getSpec(detailData.orderProject?.price) }}</div>
        </div>
      </div>
      <div class="detail-body">
        <div class="star-box">
          <div class="name">产品体验</div>
          <div class="star"><el-rate v-model="nameRate" disabled></el-rate></div>
          <div class="score">{{getRate(nameRate)}}</div>
        </div>
        <div class="star-box">
          <div class="name">服务评分</div>
          <div class="star"><el-rate v-model="serveRate" disabled></el-rate></div>
          <div class="score">{{getRate(serveRate)}}</div>
        </div>
        <div class="desc-box">
          <div class="name">评价内容</div>
          <div class="desc">{{detailData.experience_con || '--' }}</div>
        </div>
      </div>
    </div>
    <div class="dialog-footer mt-20 ml-20">
      <el-button style="width: 108px;height: 40px;" type="primary" size="small" @click="dialogVisible = false">确定</el-button>
    </div>
  </el-dialog>
</div>
</template>
<style lang="scss" scoped src="@/styles/common.scss"></style>
<style scoped lang="scss">
.container{
  width: 100%;height: 100%;background-color: #fff;padding: 30px;box-sizing: border-box;
  :deep .el-table--border .el-table__cell{
    border-right: none !important;
  }
  :deep .el-table{
    border: none !important;
  }
  :deep .el-dialog{
    margin-top: 28vh !important;
    width: 640px;
    height: 430px;
    background: #FFFFFF;
    border-radius: 8px;
  }
  .el-table {
    .color-red {
      color: #FD5451;
    }

    .color-green {
      color: #04CD83;
    }

    .color-blue {
      color: #3984F5;
    }
  }

  .product-info-box{
    display: flex;
    align-items: center;
    .img-box{
      flex-shrink: 0;
      width: 72px;
      height: 72px;
      background: #CEDAE0;
      border-radius: 6px;
      margin-right: 18px;
      img{
        width: 100%;
        height: 100%;
      }
    }
    .product-info-txt{
      flex: 1;
      .product-info-title{
        font-family: Microsoft YaHei;
        font-weight: 400;
        font-size: 14px;
        color: #242D33;
        line-height: 24px;
      }
      .product-info-desc{
        font-family: Microsoft YaHei;
        font-weight: 400;
        font-size: 12px;
        color: #6C7880;
        line-height: 24px;
      }
      .product-info-specification{
        font-family: Microsoft YaHei;
        font-weight: 400;
        font-size: 12px;
        color: #3984F5;
        line-height: 24px;
      }
    }
  }

  .operate {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;

    .handle {
      display: flex;

      .all-select {
        margin-right: 16px;
        padding-left: 24px;
        line-height: 32px;
      }

      .button {
        margin-right: 10px;
        padding: 0 12px;
        height: 30px;
        line-height: 30px;
        font-size: 12px;
        color: #6C7880;
        border: 1px solid #CEDAE0;
        cursor: pointer;

        &:hover {
          color: #3984F5;
          border-color: #3984F5;
        }
      }
    }
  }
}

.dialog-content{
  padding: 0 20px;
  box-sizing: border-box;
  .detail-header{
    display: flex;
    align-items: center;
    .product-img{
      flex-shrink: 0;
      margin-right: 18px;
      width: 72px;
      height: 72px;
      background: #E1E8EC;
      border-radius: 6px;
      img{
        width: 100%;
        height: 100%;
      }
    }
    .product-info-box-detail{
      flex: 1;
      .product-info-txt{
        font-family: Microsoft YaHei;
        font-weight: 400;
        font-size: 16px;
        color: #242D33;
        line-height: 24px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
      .product-info-gg{
        font-family: Microsoft YaHei;
        font-weight: 400;
        font-size: 12px;
        color: #6C7880;
        line-height: 24px;
      }
      .product-info-spec{
        font-size: 12px;
        color: #3984F5;
        line-height: 24px;
      }
    }

  }
  .detail-body{
    margin-top: 30px;
    .star-box{
      margin-top: 20px;
      display: flex;
      align-items: center;
      .name{
        margin-right: 20px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        font-size: 12px;
        color: #444F57;
      }
      .star{}
      .score{
        margin-left: 20px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        font-size: 12px;
        color: #242D33;
      }
    }
    .desc-box{
      margin-top: 20px;
      .name{
        margin-bottom: 10px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        font-size: 12px;
        color: #444F57;
      }
      .desc{
        font-family: Microsoft YaHei;
        font-weight: 400;
        font-size: 12px;
        color: #242D33;
        line-height: 22px;
      }
    }
  }
}

// 输入框样式修改
::v-deep .el-input {
  font-size: 12px;

  .el-input__inner {
    padding: 0 12px;
    height: 34px;
    line-height: 34px;
    border: 1px solid #CEDAE0;
    color: #242D33;
  }
}

// 下拉框样式修改
::v-deep .el-select {
  .el-input.is-focus .el-input__inner {
    border-color: #CEDAE0;
  }

  .el-input__icon {
    font-size: 12px;
    line-height: 34px;
  }
}

// 下拉悬浮框样式修改
::v-deep .el-select-dropdown {
  border-color: #CEDAE0;
  box-shadow: 0 0 12px rgba(36, 45, 51, 0.15);

  .el-select-dropdown__item {
    font-size: 12px;
    color: #242D33;
  }

  .el-select-dropdown__item.hover {
    background-color: transparent;
  }

  .el-select-dropdown__item:hover {
    background-color: #F6F7F9;
  }

  .el-select-dropdown__item.selected {
    color: #3984F5;
    font-weight: 400;
    background-color: #F6F7F9;
  }

  .el-select-dropdown__empty {
    font-size: 12px;
  }
}

// 日期样式修改
::v-deep .el-date-editor {
  padding: 0 12px;
  height: 34px;
  line-height: 34px;
  border: 1px solid #CEDAE0;
  color: #242D33;

  .el-range-input {
    width: 38%;
    font-size: 12px;
    color: #242D33;
  }

  .el-range-separator {
    width: 6%;
    font-size: 12px;
  }

  .el-range__close-icon {
    margin-right: -5px;
    width: 18px;
  }
}

// 表格样式修改
::v-deep .el-table {
  font-size: 12px;
  color: #242D33;
  border-color: #DCE5EC;

  &::before,
  &::after {
    background-color: #DCE5EC;
  }

  .el-table__cell {
    padding: 8px 0;
    border-color: #DCE5EC;
  }

  .cell {
    line-height: 24px;
  }

  thead {
    color: #242D33;
  }

  th.el-table__cell.is-leaf {
    border-color: #DCE5EC;
    background: #F6F7F9;
  }

  th {
    font-weight: 400;
  }

  .el-table-column--selection .cell {
    padding-right: 10px;
  }

  .el-table__body tr:hover > td.el-table__cell {
    background-color: #F6FBFE;
  }

  td.el-table__cell {
    padding: 13px 0;
  }
}

// 分页样式修改
::v-deep .el-pagination {
  padding: 0;
  color: #242D33;
  font-weight: 400;

  button {
    font-size: 12px;
    height: 32px;
    line-height: 32px;
  }

  .el-pager li {
    font-size: 12px;
    height: 32px;
    line-height: 32px;
  }

  .btn-next, .btn-prev, .el-pager li {
    border: 1px solid #CEDAE0;
    background-color: #ffffff;
    color: #242D33;
    min-width: 32px;
  }

  .btn-next:not(:disabled), .btn-prev:not(:disabled), .el-pager li {
    &:hover {
      color: #3984F5;
      border-color: #3984F5;
    }
  }

  .el-pager li:not(.disabled).active {
    background-color: #3984F5;
    border-color: #3984F5;
  }

  .btn-next.disabled, .btn-next:disabled, .btn-prev.disabled, .btn-prev:disabled, .el-pager li.disabled {
    color: #E1E8EC;
  }

  .el-pagination__sizes {
    margin-right: 0;
    height: 32px;
    line-height: 32px;
  }

  .el-select .el-input {
    margin-right: 0;

    .el-input__inner {
      font-size: 12px;
      height: 32px;
      line-height: 32px;

      &:hover {
        border-color: #3984F5;
      }
    }
  }

  .el-range-editor.is-active, .el-range-editor.is-active:hover, .el-select .el-input.is-focus .el-input__inner {
    border-color: #3984F5;
  }

  .touch-hover {
    cursor: pointer;
  }
}
</style>
