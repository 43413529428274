<template>
<div>
  <div class="container">
    <div class="part">
      <div class="title">
        <h3>投诉建议信息</h3>
        <div class="line"></div>
      </div>
      <div class="content">
        <div class="item item-flex">
          <span class="item-name">投诉建议企业</span>
          <div class="item-con">
            <p>{{detailData.company}}</p>
          </div>
        </div>
        <div class="item item-flex">
          <span class="item-name">投诉建议类型</span>
          <div class="item-con">
            <p>{{detailData.dict_name}}</p>
          </div>
        </div>
        <div class="item">
          <span class="item-name">投诉建议内容</span>
          <div class="item-con">
            <p style="margin-top: 4px;">{{detailData.content}}</p>
          </div>
        </div>
        <div class="item">
          <span class="item-name">相关图片</span>
          <div class="item-con">
            <div class="relate-pictures">
              <el-image v-for="(item, index) in detailData.imgs" fit="contain" :src="item" :preview-src-list="detailData.imgs" :initial-index="index" :key="index"></el-image>
            </div>
          </div>
        </div>
        <div class="item item-flex">
          <span class="item-name">联系方式</span>
          <div class="item-con">
            <p>{{detailData.call_tel}}</p>
          </div>
        </div>
        <div class="item item-flex">
          <span class="item-name">提交时间</span>
          <div class="item-con">
            <p>{{detailData.create_time}}</p>
          </div>
        </div>
      </div>
    </div>
    <div class="part">
      <div class="title">
        <h3>投诉回复</h3>
        <div class="line"></div>
      </div>
      <el-form
          label-position="right"
          label-width="82px"
          ref="ruleFormRef"
          :model="ruleForm"
          :rules="rules"
          style="margin-top: 28px;"
      >
        <el-form-item label="回复内容" prop="desc">
          <el-input v-model="ruleForm.reply_content" placeholder="请输入回复内容" type="textarea" style="width: 80%;" />
        </el-form-item>
        <el-form-item label="上传图片">
          <div class="upload-box">
            <div class="upload-tips mb-10">若有相关证明图片，可在此上传，最多可传3张，单张限制大小2M以内</div>
            <el-upload
                :action="uploadData.url"
                :headers="uploadData.headers"
                :name="uploadData.name"
                :accept="uploadData.accept"
                list-type="picture-card"
                :file-list="fileList"
                :on-success="loadSuccess"
                :on-preview="handlePictureCardPreview"
                :before-upload="handleBeforeUpload"
                :on-remove="handleRemove">
              <i class="el-icon-plus"></i>
            </el-upload>
            <el-dialog :visible.sync="dialogVisible">
              <img width="100%" :src="dialogImageUrl" alt="">
            </el-dialog>
          </div>
        </el-form-item>
        <el-form-item class="btn-box">
          <el-button type="primary" style="width: 100px;" @click="handleSubmit">确定</el-button>
          <el-button style="width: 80px;" @click="handleReturn">返回</el-button>
        </el-form-item>
      </el-form>
    </div>
  </div>
</div>
</template>

<script>
import uploadImg from "@/utils/uploadImg.js"
import {update_feedback} from "../../request/oneEnterOneFileApi";
export default {
  data(){
    return{
      data: {
        relatePictures: [
            'https://fuss10.elemecdn.com/3/63/4e7f3a15429bfda99bce42a18cdd1jpeg.jpeg?imageMogr2/thumbnail/360x360/format/webp/quality/100',
          'https://fuss10.elemecdn.com/3/63/4e7f3a15429bfda99bce42a18cdd1jpeg.jpeg?imageMogr2/thumbnail/360x360/format/webp/quality/100'
        ]
      },
      detailData: {},
      ruleForm: {
        id: '',
        reply_content: '',
        product_img: [], // 这里是数组，上传图片的url
      },
      rules: {
        reply_content: [{ required: true, message: '请输入回复内容', trigger: 'blur' }],
      },
      uploadData: {url: ''},//上传图片参数
      dialogImageUrl: '',//产品主图
      dialogVisible: false,//产品主图
      fileList: [],//产品图默认
    }
  },
  computed: {

    //获取后端的上传接口地址

    uploadUrl() {

      return '/file/upload_single_image'

    }

  },
  mounted() {
    this.uploadData = uploadImg;
    this.$nextTick(() => {
      this.$api.feedback_info({id: this.$route.query.id}).then(res => {
        this.detailData = res.data;
        console.log(res,'查看详情')
      })
    })
  },
  methods: {
    // 获取企业信息
    // getEnterpriseInfo(id) {
    //   this.$api.getEntDetail(id).then(res => {
    //     this.data = res.data
    //   })
    // },\
    handleSubmit() {
      let params = {
        id: this.$route.query.id,
        reply_content: this.ruleForm.reply_content,
        reply_imgs: this.ruleForm.product_img.map(item => item).join(',')
      }
      this.$api.update_feedback(params).then(res => {
        this.$message({
          message: '回复成功',
          type: 'success',
          duration: 1000,
          onClose: () => {
            this.$router.push({path: '/feedbackDetail', query: {id: this.$route.query.id}})
          }
        });
      })
      console.log(params,'提交参数')
    },
    //上传图片成功
    loadSuccess(e, file, fileList) {
      if (e.status !== 200) {
        this.$message.error(e.msg);
      } else {
        this.fileList = fileList;
        this.ruleForm.product_img = [];
        for (let i = 0; i < this.fileList.length; i++) {
          if (this.fileList[i].response) {
            this.ruleForm.product_img.push(this.fileList[i].response.data.image_url);
          } else {
            this.ruleForm.product_img.push(this.fileList[i].url);
          }
        }
      }
    },
    //预览图片
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },
    handleBeforeUpload(file) {
      const maxSize = 2 * 1024 * 1024; // 10MB，根据你的需求设置最大文件大小
      if (file.size > maxSize) {
        this.$message.error('文件大小不能超过2MB');
        return false;
      }
    },
    //删除图片
    handleRemove(file, fileList) {
      // console.log(file, fileList);
      this.ruleForm.product_img = [];
      for (let i = 0; i < this.fileList.length; i++) {
        if (this.fileList[i].response) {
          this.ruleForm.product_img.push(this.fileList[i].response.data.image_url);
        } else {
          this.ruleForm.product_img.push(this.fileList[i].url);
        }
      }
    },
    //返回
    handleReturn() {
      this.$router.go(-1)
    }
  }
}
</script>

<style scoped lang="scss">
.container {
  margin: 18px;
  .part {
    margin-bottom: 12px;
    padding: 30px 30px 14px;
    background-color: #ffffff;
    &:last-child {
      margin-bottom: 0;
    }
    .title {
      display: flex;
      align-items: center;
      justify-content: space-between;
      h3{
        position: relative;
        margin-right: 10px;
        flex-shrink: 0;
        height: 20px;
        line-height: 20px;
        font-weight: 400;
        font-size: 18px;
        color: #242D33;
        &:before{
          content: '';
          width: 4px;
          height: 20px;
          background: #3984F5;
          position: absolute;
          top: 0;
          left: -30px;
        }
      }
      .line{
        background: #EDF1F4;
        flex-grow: 1;
        height: 1px;
      }
    }
    .content {
      padding: 20px 0 0;
    }
  }
  .item {
    margin-bottom: 12px;
    font-size: 12px;
    color: #242D33;
    line-height: 24px;
  }
  .item-flex {
    display: flex;
  }
  .item-name {
    margin-right: 16px;
    color: #6C7880;
  }
  .relate-pictures {
    margin-top: 12px;
    .el-image {
      margin-right: 10px;
      width: 100px;
      height: 100px;
    }
  }
  .upload-tips {
    font-size: 12px;
    color: #6C7880;
    line-height: 48px;
  }
}
::v-deep {
  .el-form-item__label {
    padding-right: 16px;
    line-height: 48px;
    font-size: 12px;
    color: #242D33;
  }
  .el-textarea__inner {
    height: 140px;
  }
  .el-upload-list__item,
  .el-upload--picture-card{
    width: 120px;
    height: 120px;
    border-radius: 4px;
    border: 1px solid #CEDAE0;
  }
  .el-upload--picture-card{
    line-height: 120px;
    background-color: #FFFFFF;
    >i {
      vertical-align: middle;
      font-size: 30px;
      color: #CEDAE0;
    }
  }
}
</style>
