<script>
export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: 'steps',
  props: {
    steps: {
      type: Array,
      default: () => [] // [{ title: '标题', description: '描述' }]
    },
    currentStep: {
      type: Number,
      default: 1 // 当前步骤
    },
    alignCenter: {
      type: Boolean,
      default: false // 是否居中排列
    },
    mode: {
      type: String,
      default: 'number' // number, check 仅在 type 为 default 时生效
    },
    size: {
      type: String,
      default: '44px' // 步骤条尺寸
    },
    type: {
      type: String,
      default: 'default' // default, checkNum, check
    }
  },
 computed: {
   iconSize: function() {
     return (parseInt(this.size) - 4) > 18 ? 18 + 'px' : (parseInt(this.size) - 4) + 'px';
   }
 },
  methods: {
    // 小于10的数字前面补零
    formatNumber(num) {
      return num < 10 ? '0' + num : num
    }
  }
}
</script>

<template>
  <div class="steps container" :style="{'--steps-size': size}">
    <div class="step" v-for="(step, index) in steps" :key="index"
         :class="{ active: index+1 <= currentStep, 'align-center': alignCenter, 'current': index+1 === currentStep }">
      <template v-if="type === 'default'">
        <div class="step-number">
          <span v-if="mode === 'number'">{{ formatNumber(index + 1) }}</span>
          <span v-else-if="mode !== 'number' && index+1 > currentStep">{{ formatNumber(index + 1) }}</span>
          <el-icon :size="iconSize" v-else class="el-icon-check"></el-icon>
        </div>
        <div class="step-title"><slot name="title" :index="index" :step="step">{{ step.title }}</slot></div>
        <div class="step-description"><slot name="description" :index="index" :step="step">{{ step.description }}</slot></div>
      </template>
      <template v-if="type === 'checkNum'">
        <div class="step-number checkNum">
          <span v-if="index+1 >= currentStep"></span>
          <el-icon :size="iconSize" v-else class="el-icon-check"></el-icon>
        </div>
        <div class="step-title checkNum"><slot name="title" :index="index" :step="step"><span>{{ formatNumber(index + 1) }}</span> {{ step.title }}</slot></div>
        <div class="step-description checkNum"><slot name="description" :index="index" :step="step">{{ step.description }}</slot></div>
      </template>
      <template v-if="type === 'check'">
        <div class="step-number check">
          <span v-if="index+1 >= currentStep"></span>
          <el-icon :size="iconSize" v-else class="el-icon-check"></el-icon>
        </div>
        <div class="step-title check"><slot name="title" :index="index" :step="step">{{ step.title }}</slot></div>
        <div class="step-description check"><slot name="description" :index="index" :step="step">{{ step.description }}</slot></div>
      </template>
    </div>
  </div>
</template>

<style scoped lang="scss">
$steps-size: var(--steps-size);
.steps {
  &.container {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(0, 1fr));

    .step {
      overflow: hidden;
      position: relative;
      display: grid;
      color: #909DA5;
      transition: color 0.3s ease;

      .step-number {
        width: var(--steps-size);
        height: var(--steps-size);
        border-radius: 50%;
        background-color: #CEDAE0;
        display: grid;
        align-items: center;
        justify-items: center;
        transition: background-color 0.3s ease;
        font-family: DINL;
        font-size: 18px;
        color: #fff;

        &:after {
          content: "";
          display: inline-block;
          width: calc(100% - ($steps-size + 20px));
          //width: calc(100% - 40px);
          height: 1px;
          border-radius: 50%;
          background-color: #CEDAE0;
          position: absolute;
          top: calc($steps-size / 2);
          left: calc($steps-size + 10px);;
          //left: 35px;
        }

        &.checkNum,&.check {
          span {
            display: inline-block;
            width: calc($steps-size - 4px);
            height: calc($steps-size - 4px);
            border-radius: 50%;
            background-color: #fff;
          }
        }
      }

      .step-title {
        padding-left: 5px;
        font-size: 14px;
        margin-top: 10px;
        height: 20px;
        line-height: 20px;
        //color: #242D33;

        span {
          font-family: DINR;
        }
      }

      .step-description {
        padding-left: 5px;
        font-size: 12px;
        margin-top: 5px;
        color: #909DA5;
        height: 20px;
        line-height: 20px;
      }

      &.align-center {
        justify-items: center;

        .step-number {
          justify-items: center;

          &:before {
            content: "";
            display: inline-block;
            width: calc(50% - ($steps-size / 2));
            height: 1px;
            border-radius: 50%;
            background-color: #CEDAE0;
            position: absolute;
            top: calc($steps-size / 2);
            right: 50%;
            transform: translateX(calc(0px - (($steps-size / 2) + 10px)));
          }

          &:after {
            content: "";
            display: inline-block;
            width: calc(50% - ($steps-size / 2));
            height: 1px;
            border-radius: 50%;
            background-color: #CEDAE0;
            position: absolute;
            top: calc($steps-size / 2);
            left: 50%;
            transform: translateX(calc(($steps-size / 2) + 10px));
          }
        }

        .step-title {
          text-align: center;
          padding: 0 5px;
        }

        .step-description {
          text-align: center;
          padding: 0 5px;
        }

        &:first-child {
          .step-number {
            &:before {
              display: none;
            }
          }
        }

      }

      &.active {
        color: #242D33;

        .step-number {
          color: #fff;
          background-color: #3984F5;
        }

        &.current {
            .step-title.checkNum {
              color: #3984F5;
            }
        }
      }


      &:last-child {
        .step-number {

          &:after {
            display: none;
          }
        }
      }
    }
  }
}
</style>