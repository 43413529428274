<template>
	<div class="ticket-details-main">
		<div class="title-text">开票信息</div>
		<div class="info-content flex">
			<div class="text-left">
        <div class="name-text">
          开票主体：<span class="company-text">中国联合网络通信有限公司云南省分公司</span>
        </div>
				<div class="name-text">
					开票企业：<span class="company-text">{{allData.company?allData.company:'--'}}</span>
				</div>
				<div class="name-text">
					发票金额：<span class="company-text">￥{{allData.amount?allData.amount:'--'}}</span>
				</div>
				<div class="name-text flex flex-col-start">
					关联订单：<span class="company-text">{{allData.order_no?allData.order_no:'--'}}</span>
				</div>
				<div class="name-text">
					{{allData.medium=='电子'?'接收邮箱':'邮寄地址'}}：<span class="company-text">{{allData.medium=='电子'?allData.email:allData.mail_address}}</span>
				</div>
				<div class="name-text">
					发票状态：<span class="company-text" :style="[getStatusColor(allData.invoice_status)]">{{allData.invoice_status?getStatusText(allData.invoice_status):'--'}}</span>
				</div>
				<div class="name-text flex flex-col-start" v-if="allData.invoice_status==1&&allData.medium=='电子'">
					电子发票：<span class="company-text">{{allData.invoice_name_pdf?allData.invoice_name_pdf:allData.invoice_pdf?allData.invoice_pdf:'--'}}</span>
					<span v-if="allData.invoice_pdf" class="fun-btn" @click="funClick()">预览</span>
					<a v-if="allData.invoice_pdf" :href="allData.invoice_pdf" rel="external nofollow" target="_blank" download="123.pdf" class="fun-btn">下载</a>
				</div>
				<div class="name-text" v-if="allData.invoice_status==8">
					驳回原因：<span class="company-text">{{allData.reject_reason?allData.reject_reason:'--'}}</span>
				</div>
			</div>
			<div class="text-right">
				<div class="name-text">
					发票抬头：<span class="company-text">{{allData.title?allData.title:'--'}}</span>
				</div>
				<div class="name-text">
					发票介质：<span class="company-text">{{allData.medium?allData.medium:'--'}}</span>
				</div>
				<div class="name-text">
					发票类型：<span class="company-text">{{allData.type_text?allData.type_text:'--'}}</span>
				</div>
				<div class="name-text">
					申请时间：<span class="company-text">{{allData.create_time?allData.create_time:'--'}}</span>
				</div>
				<div class="name-text" v-if="allData.invoice_status==5">
					取消时间：<span class="company-text">{{getTimeMul(allData.handle_time)}}</span>
				</div>
				<div class="name-text" v-if="allData.invoice_status==1">
					开具时间：<span class="company-text">{{getTimeMul(allData.handle_time)}}</span>
				</div>
				<div class="name-text" v-if="allData.invoice_status==8">
					驳回时间：<span class="company-text">{{getTimeMul(allData.handle_time)}}</span>
				</div>
				<div class="name-text" v-if="(allData.invoice_status==1||allData.invoice_status==8)&&allData.medium!='纸质'">
					备注：<span class="company-text">{{allData.handle_notes?allData.handle_notes:'--'}}</span>
				</div>
			</div>
			<div :class="['biao-icon',allData.invoice_status==2?'biao-green-bg':'']" v-if="allData.invoice_status==2"><p>待审核</p></div>
			<div :class="['biao-icon',allData.invoice_status==5?'biao-hui-bg':'']" v-if="allData.invoice_status==5"><p>已取消</p></div>
			<div :class="['biao-icon',allData.invoice_status==1?'biao-hui-bg':'']" v-if="allData.invoice_status==1"><p>已开具</p></div>
			<div :class="['biao-icon',allData.invoice_status==8?'biao-red-bg':'']" v-if="allData.invoice_status==8"><p>已驳回</p></div>
		</div>
		<div class="from-content" v-if="allData.invoice_status==2">
			<el-form ref="ruleForm" :model="form" :rules="rules" label-width="110px">
				<el-form-item label="审核结果" prop="invoice_status">
					<el-radio-group v-model="form.invoice_status">
					    <el-radio label="1">通过并开具</el-radio>
					    <el-radio label="8">驳回</el-radio>
					</el-radio-group>
				</el-form-item>
				<el-form-item :label="allData.medium=='纸质'?'物流单号':'上传发票'" :prop="allData.medium=='纸质'?'express_no':'invoice_name_pdf'" v-show="form.invoice_status==1">
					<div class="flex flex-col-center" v-if="allData.medium=='电子'">
						<el-input class="input-w" v-model="form.invoice_name_pdf" placeholder="请上传电子发票，格式为PDF"></el-input>
						<div class="load-btn">
							<el-button class="pdf-btn" type="primary">点击上传</el-button>
							<input class="file-btn" type="file" accept=".pdf" @change="loadFileClick" />
						</div>
					</div>
					<div class="flex flex-col-center" v-if="allData.medium=='纸质'">
						<el-input class="input-w" v-model="form.express_no" placeholder="请填写纸质发票邮寄的物流单号"></el-input>
					</div>
				</el-form-item>
				<el-form-item label="驳回原因" prop="reject_reason" v-show="form.invoice_status==8">
					<el-input class="input-w" v-model="form.reject_reason" placeholder="请填写驳回原因"></el-input>
				</el-form-item>
				<el-form-item label="备注" prop="handle_notes">
					<el-input class="input-w" v-model="form.handle_notes" type="textarea" :rows="4" placeholder="如有其他备注信息，可在此填写" maxlength="200" show-word-limit></el-input>
				</el-form-item>
				<el-form-item>
					<el-button class="sub-btn" type="primary" @click="enterClick('ruleForm')">提交</el-button>
				</el-form-item>
			</el-form>
		</div>
	</div>
</template>

<script>
	import {timeFormat} from "@/utils/index.js"
	export default {
		data(){
			return {
				allData: {},//所有数据
				form: {
					id: '',
					invoice_status: '1',
					invoice_pdf: '',
					reject_reason: '',
					handle_notes: '',
					express_no: '',
          invoice_name_pdf: ''
				},
				rules: {
					invoice_status: [{required: true, message: '', trigger: 'change' }],
          invoice_name_pdf: [{required: true, message: '请上传电子发票，格式为PDF', trigger: 'blur' }],
					reject_reason: [{required: true, message: '请填写驳回原因', trigger: 'blur' }],
					express_no: [{required: true, message: '请填写纸质发票邮寄的物流单号', trigger: 'blur' }],
				},
			}
		},
		created() {
			Object.assign(this.form, {})
			this.form.id = this.$route.query.id;
			this.initData();
		},
		methods: {
			initData(){
				this.$api.invoice_details(this.$route.query.id).then(res=>{
					this.allData = res.data;
				})
			},
			//提交
			enterClick(formName) {
				if (this.form.invoice_status=='1'){//已开具
					delete this.form.reject_reason;
					delete this.rules.reject_reason;
					if(this.allData.medium=='纸质') {
						delete this.form.invoice_pdf;
						delete this.rules.invoice_pdf;
						delete this.form.invoice_name_pdf;
						delete this.rules.invoice_name_pdf;
					} else {
						delete this.form.express_no;
					}
				} else {//驳回
					delete this.form.express_no;
					delete this.rules.express_no;
					delete this.form.invoice_pdf;
					delete this.rules.invoice_pdf;
					delete this.form.invoice_name_pdf;
					delete this.rules.invoice_name_pdf;
				}
				console.log(this.form);

				this.$refs[formName].validate((valid) => {
					if (valid) {
						this.$api.invoice_audit(this.form).then(res=>{
							this.$message({type: 'success', message: '提交成功！'});
							this.$router.go(-1);
						})
					} else {
						console.log('error submit!!');
						return false;
					}
				});

			},
			//预览PDF
			funClick(){
				window.open(this.allData.invoice_pdf);
			},
			//上传文件
			loadFileClick(e){
				const file = e.target.files;
				let formData = new FormData();
				formData.append("file", file[0]);
				this.$api.upload_single_file(formData).then(res=>{
					this.form.invoice_pdf = res.data.file_path;
					this.form.invoice_name_pdf = res.data.original_file_name;
				})
			},
			//时间格式
			getTimeMul(time) {
				return time?timeFormat(time, 'yyyy-mm-dd hh:MM:ss'):'--';
			},
			//获取状态文本
			getStatusText(num) {
				let str = '已开具';
				switch (num){
					case 1:
						str = '已开具';
						break;
					case 2:
						str = '待审核';
						break;
					case 5:
						str = '已取消';
						break;
					case 8:
						str = '已驳回';
						break;
					default:
						break;
				}
				return str;
			},
			//获取状态文本颜色
			getStatusColor(num) {
				let color = {color: '#242D33'};
				switch (num){
					case 1:
						color.color = '#242D33';
						break;
					case 2:
						color.color = '#04CD83';
						break;
					case 5:
						color.color = '#242D33';
						break;
					case 8:
						color.color = '#FD5451';
						break;
					default:
						break;
				}
				return color;
			},

		},
	}
</script>

<style lang="scss" scoped>
	.ticket-details-main{width: 100%;height: 100%;background-color: #fff;padding: 30px;box-sizing: border-box;
		.title-text{position: relative;font-size: 18px;color: #242D33;
			&:before{position: absolute;content: '';width: 4px;height: 20px;background-color: #3984F5;left: -30px;top: 4px;}
		}
		.info-content{width: 1100px;background: #F6F7F9;border-radius: 4px;border: 1px solid #E1E8EC;margin-top: 20px;padding: 20px;box-sizing: border-box;position: relative;
			.text-left{margin-right: 270px;
				.name-text{font-size: 12px;color: #6C7880;margin-bottom: 20px;
					.company-text{display: inline-block; color: #242D33;max-width: 250px;white-space: pre-wrap;word-wrap: break-word;}
					.fun-btn{font-size: 12px;color: #3984F5;margin-left: 25px;
						&:hover{cursor: pointer;text-decoration: underline;}
					}
				}
			}
			.text-right{
				.name-text{font-size: 12px;color: #6C7880;margin-bottom: 20px;max-width: 300px;white-space: pre-wrap;word-wrap: break-word;
					.company-text{color: #242D33;}
				}
			}
			.biao-icon{position: absolute;top: 0;right: 0;width: 48px;height: 48px;
					p{font-size: 12px;color: #fff;transform: rotate(45deg);margin: 7px -5px -12px 15px;}
			}
			.biao-green-bg{background: linear-gradient(-270deg, transparent 48px, #04CD83 0) top left, linear-gradient(45deg, transparent 34px, #04CD83 0) bottom left;}
			.biao-hui-bg{background: linear-gradient(-270deg, transparent 48px, #A7B2B8 0) top left, linear-gradient(45deg, transparent 34px, #A7B2B8 0) bottom left;}
			.biao-red-bg{background: linear-gradient(-270deg, transparent 48px, #FD5451 0) top left, linear-gradient(45deg, transparent 34px, #FD5451 0) bottom left;}
		}
		.from-content{margin-top: 20px;
			.input-w{width: 360px;}
			.load-btn{width: 100px;height: 38px;position: relative;margin-left: 10px;
				.pdf-btn{width: 100px;height: 38px;background: #3984F5;border-radius: 4px;padding: 0;}
				.file-btn{position: absolute;top: 0;left: 0;width: 100%;height: 100%;opacity: 0;z-index: 2;
					&:hover{cursor: pointer;}
				}
			}
			.sub-btn{width: 120px;height: 40px;background: #3984F5;border-radius: 4px;padding: 0;}
		}

	}
	::v-deep(){}

</style>
