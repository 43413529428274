<template>
<div>
  <div class="screen clearfix" style="margin-top: 10px">
    <div class="item">
      <span>投诉建议企业</span>
      <div class="box">
        <el-input v-model="inquire.company" placeholder="输入投诉建议企业" style="width: 210px"></el-input>
      </div>
    </div>

    <div class="item">
      <span>投诉建议时间</span>
      <div class="box">
        <el-date-picker
            v-model="allTime"
            class="daterange"
            type="datetimerange"
            range-separator="至"
            value-format="yyyy-MM-dd HH:mm:ss"
            start-placeholder="开始时间"
            end-placeholder="结束时间"
            style="width: 234px"
            @change="handleChangeDate">
        </el-date-picker>
      </div>
    </div>
    <span class="button inquire" @click="inquireBtn">搜索</span>
    <span class="button" @click="resetBtn">重置</span>
  </div>
  <div class="main_table">
    <el-table
        ref="multipleTable"
        :data="tableData"
        :border="true"
        v-loading="loading"
        style="width: 100%">
      <el-table-column
          label="序号"
          width="70"
          align="center"
      >
        <template slot-scope="scope">
          <span>{{ (inquire.page - 1) * inquire.limit + scope.$index + 1 }}</span>
        </template>
      </el-table-column>
      <el-table-column
          prop="company"
          label="投诉建议企业"
          width="330"
      >
      </el-table-column>
      <el-table-column
          prop="dict_name"
          label="投诉建议类型"
          width="200"
      >
      </el-table-column>
      <el-table-column
          prop="content"
          label="投诉建议内容">
      </el-table-column>
      <el-table-column
          prop="create_time"
          label="投诉建议时间"
          width="200"
          >
      </el-table-column>
      <el-table-column
          label="操作"
          width="200"
          align="center"
      >
        <template slot-scope="scope">
          <a style="color: #3984F5" href="JavaScript:;" @click="routerBtn(scope.row.id)">投诉回复</a>
        </template>
      </el-table-column>
    </el-table>
    <div class="operate fr">
      <el-pagination
          background
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="inquire.page"
          :page-sizes="[10, 20, 30, 40]"
          :page-size="inquire.limit"
          layout="prev, pager, next, sizes"
          :total="total">
      </el-pagination>
    </div>
  </div>
</div>
</template>

<script>

import {feedback_pending_lists} from "../../../request/oneEnterOneFileApi";

export default {
  name: "waiting",
  data(){
    return {
      allTime:[],
      inquire:{
        page:1,
        limit:10,
        company:'',
        start_time:'',
        end_time:''
      },
      tableData:[],
      total:0,
      loading:false
    }
  },
  mounted(){
    this.getData();
  },
  methods:{
    // 获取列表数据
    getData(){
      this.loading = true;
      this.$api.feedback_pending_lists(this.inquire).then(res => {
        this.total = res.data.count;
        this.tableData = res.data.list;
        this.loading = false;
        console.log(res,'待回复列表')
      })
    },
    handleChangeDate(val){
      this.inquire.start_time = val[0];
      this.inquire.end_time = val[1];
    },
    inquireBtn(){
      this.inquire.page = 1;
      this.getData();
    },
    resetBtn(){
      this.inquire = {
        page:1,
        limit:10,
        company:'',
        start_time:'',
        end_time:''
      }
      this.allTime = [];
      this.inquire.page = 1;
      this.getData();
    },
    handleSizeChange(value){
      this.inquire.page = 1;
      this.inquire.limit = value;
      this.getData();
    },
    handleCurrentChange(value){
      this.inquire.page = value;
      this.getData();
    },
    routerBtn(id){
      this.$router.push({path: '/feedbackResponse', query: {id: id}})
    }
  }
}
</script>
<style lang="scss" scoped src="@/styles/common.scss"></style>
<style scoped lang="scss">

</style>
