import { render, staticRenderFns } from "./ticket-reject-table-item.vue?vue&type=template&id=6bbccc0a&scoped=true&"
import script from "./ticket-reject-table-item.vue?vue&type=script&lang=js&"
export * from "./ticket-reject-table-item.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6bbccc0a",
  null
  
)

export default component.exports