<script>
import mySteps from "@/components/MySteps/index.vue";
import titleDom from "@/components/common/titleDom";
import {order_details} from "../../../request/orderListApi";
export default {
  name: "item-detail",
  components: {mySteps, titleDom},
  data() {
    return {
      loading: false,
      stepData: { //步骤数据
        data: [
          /*{ title: "待付款", description: "wait" },
          { title: "待服务",description: "如平台未确认，系统将在11:59:59后进行自动确认" },
          { title: "服务中"},
          { title: "待评价"},
          { title: "已完成"}*/
        ],
        currentStep: 0
      },
      status: 0, //状态 1待确认2待服务3 服务中4待验收5已关闭6已完结）
      refundStatus: 0, //退款状态：退款状态：1=已退款 2=退款中 3=取消 4=驳回
      origin_status: 0, //订单原状态：1待确认2待服务3 服务中4待验收5已关闭6已完结）
      detailAllData: {},
      tableData: [],
      serviceInfo: {},
      companyInfo: {},
      recordList: [],
      orderRefund: {} // 退款信息
    };
  },
  methods: {
    getDetailData() {
      this.loading = true;
      this.$api.order_details(this.$route.query.id).then(res => {
        this.$nextTick(() => {
          this.status = res.data.order_status;
          this.refundStatus = res.data.refund_status;
          this.origin_status = res.data.order_status;
          this.detailAllData = res.data;
          this.stepData.data = this.detailAllData.flow_line.map(item => ({
            title: item.flow,
            description: item.time
          }));
          this.stepData.currentStep = this.countConsecutiveBright(this.detailAllData.flow_line)
          this.tableData.push(res.data.order_product)
          this.serviceInfo = res.data.service_info
          this.companyInfo = res.data.company
          this.recordList = res.data.order_product.service_info
          if (res.data.order_refund !== null) {
            this.orderRefund = res.data.order_refund
          }
          console.log(this.orderRefund)
          this.loading = false;
        });
        console.log(res,'订单详情')
      })
    },
    countConsecutiveBright(items) {
      let count = 0;
      for (let i = 0; i < items.length; i++) {
        if (items[i].is_bright) {
          count++;
        } else {
          // 如果遇到 is_bright 为 false，则停止计数
          break;
        }
      }
      return count + 1;
    },
    getStatusText(status) {
      switch (status) {
        case 1:
          return "待确认";
        case 2:
          return "待服务";
        case 3:
          return "服务中";
        case 4:
          return "待验收";
        case 5:
          return "已关闭";
        case 6:
          return "已完结";
        default:
          return "";
      }
    },
    getRefundStatusText(status) {
      switch (status) {
        case 1:
          return "（已退款）";
        case 2:
          return "（退款中）";
        case 3:
          return "（取消退款）";
        case 4:
          return "（驳回退款）";
        default:
          return "";
      }
    },
    getRefundInfo(status) {
      switch (status) {
        case 1:
          return "已退款";
        case 2:
          return "退款中";
        case 3:
          return "取消退款";
        case 4:
          return "驳回退款";
        default:
          return "";
      }
    },
    refundMethod(status) {
      if (status == 1){
        return '线下退款'
      }else if (status == 2){
        return '线上退款'
      }
    },
    // 规格内容
    getSpec(price) {
      if (Array.isArray(price)) {
        let value = ''
        price.forEach((item) => {
          if (item[2] == '1') {
            value = item[0]
            return
          }
        })
        return value
      } else {
        return ''
      }
    },
    // 规格价格
    getPrice(price) {
      if (Array.isArray(price)) {
        let value = ''
        price.forEach((item) => {
          if (item[2] == '1') {
            value = item[1]
            return
          }
        })
        return value
      } else {
        return ''
      }
    },
    // 预览
    previewClick(url) {
      window.open(url)
    }
  },
  mounted() {
    this.getDetailData();
  }
}
</script>

<template>
<div class="container" v-loading="loading">
  <div class="plate">
    <div class="header_box">
      <div class="header_left">
        <div class="status" :class="{'blue': refundStatus === 0 && status === 4 || status !== 4}">{{getStatusText(status)}}<span v-if="status==4" style="color: #FD5451">{{getRefundStatusText(refundStatus)}}</span></div>
        <p>订单编号：{{detailAllData.order_no}}</p>
      </div>
      <div class="header_right">
        <my-steps :steps="stepData.data" :currentStep="stepData.currentStep" size="24px" type="check" mode="check" alignCenter></my-steps>
      </div>
    </div>
  </div>
  <div class="plate part1">
    <titleDom :isShowLine="false" title="产品信息"/>
    <div class="tabel-content">
      <el-table :data="tableData" style="width: 100%" :header-cell-style="{height:'40px;',background:'#F6F7F9',color:'#6C7880'}">
        <el-table-column label="服务项目" width="565">
          <template  slot-scope="scope">
            <div class="product-info-box">
              <div class="img-box">
                <img :src="scope.row.product_img" alt="">
              </div>
              <div class="product-info-txt">
                <div class="product-info-title">{{ scope.row.name }}</div>
                <div class="product-info-desc">{{scope.row.features }}</div>
                <div v-if="scope.row.charge_method == 3 || scope.row.charge_method == 4" class="product-info-specification">规格：{{ getSpec(scope.row.price) }}</div>
              </div>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="服务类型">
          <template  slot-scope="scope">
            <span>{{scope.row.dictionary}}</span>
          </template>
        </el-table-column>
        <el-table-column prop="num" label="价格">
          <template  slot-scope="scope">
            <span v-if="scope.row.charge_method == 1" style="color: #FD5451;">面议</span>
            <span v-else-if="scope.row.charge_method == 2" style="color: #FD5451;">￥{{scope.row.price}}</span>
            <span v-else style="color: #FD5451;">￥{{ getPrice(scope.row.price) }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="total_amount" label="服务时长">
          <template  slot-scope="scope">
            <span>{{scope.row.num}}年</span>
          </template>
        </el-table-column>
        <el-table-column prop="dictionary.dict_name" label="总费用">
          <template  slot-scope="scope">
            <span style="color: #FD5451;">￥{{detailAllData.total_amount}}</span>
          </template>
        </el-table-column>
      </el-table>
      <div class="server-info">
        <div class="server-info-name">服务商：{{serviceInfo.service_name}}</div>
        <div class="server-info-phone">联系电话：{{serviceInfo.mobile}}</div>
      </div>
    </div>
  </div>
  <div class="plate part2">
    <titleDom :isShowLine="false" title="客户信息"/>
    <div class="part-item-box">
      <div class="part-item">
        <div class="name">企业名称：</div>
        <div class="value">{{ companyInfo.company }}</div>
      </div>
      <div class="part-item">
        <div class="name">用户名：</div>
        <div class="value">{{ companyInfo.username }}</div>
      </div>
      <div class="part-item">
        <div class="name">联系人：</div>
        <div class="value">{{ detailAllData.contacts }}</div>
      </div>
      <div class="part-item">
        <div class="name">联系电话：</div>
        <div class="value">{{ detailAllData.mobile }}</div>
      </div>
      <div class="part-item">
        <div class="name">备注：</div>
        <div class="value">{{ detailAllData.notes }}</div>
      </div>
    </div>
  </div>
  <!-- 待验收（退款中/退款驳回）-->
  <div class="plate part4" v-if="status === 4 && (refundStatus === 2 || refundStatus === 4)">
    <titleDom before-color="#FD5451" :isShowLine="false" title="退款信息"/>
    <div class="part-item-box">
      <div class="part-item">
        <div class="name">退款原因：</div>
        <div class="value">{{ orderRefund.refund_reason}}</div>
      </div>
      <div class="part-item">
        <div class="name">申请退款金额：</div>
        <div class="value red">￥{{ orderRefund.refund_amount }}</div>
      </div>
      <div class="part-item">
        <div class="name">申请时间：</div>
        <div class="value">{{ orderRefund.create_time }}</div>
      </div>
      <div class="part-item">
        <div class="name">退款状态：</div>
        <div class="value">{{ getRefundInfo(refundStatus) }}</div>
      </div>
      <div class="part-item full-width">
        <div class="name">退款说明：</div>
        <div class="value">{{ orderRefund.refund_explain }}</div>
      </div>
      <div class="part-item full-width" v-if="refundStatus === 4"> <!--已驳回状态下显示-->
        <div class="name">驳回原因：</div>
        <div class="value">{{ orderRefund.reject_reason}}</div>
      </div>
    </div>
  </div>
  <!--  已关闭(办理/未办理)-->
  <div class="plate part4" v-if="status === 5">
    <titleDom before-color="#FD5451" :isShowLine="false" title="退款信息"/>
    <!--  已关闭(办理)-->
    <div class="part-item-box" v-if="origin_status === 4">
      <div class="part-item">
        <div class="name">退款原因：</div>
        <div class="value">{{ orderRefund.refund_reason}}</div>
      </div>
      <div class="part-item">
        <div class="name">申请退款金额：</div>
        <div class="value red">￥{{ orderRefund.refund_amount }}</div>
      </div>
      <div class="part-item">
        <div class="name">申请时间：</div>
        <div class="value">{{ orderRefund.create_time }}</div>
      </div>
      <div class="part-item">
        <div class="name">退款状态：</div>
        <div class="value">{{ getRefundInfo(refundStatus) }}</div>
      </div>
      <div class="part-item">
        <div class="name">退款说明：</div>
        <div class="value">{{ orderRefund.refund_explain }}</div>
      </div>
      <div class="part-item">
        <div class="name">退款时间：</div>
        <div class="value">{{ orderRefund.update_time }}</div>
      </div>
      <div class="part-item">
        <div class="name">退款金额：</div>
        <div class="value red">￥{{ orderRefund.refund_amount }}</div>
      </div>
      <div class="part-item">
        <div class="name">退款方式：</div>
        <div class="value">{{ refundMethod(orderRefund.refund_method) }}</div>
      </div>
    </div>
    <!--  已关闭(未办理)-->
    <div class="part-item-box" v-else>
      <div class="part-item">
        <div class="name">退款原因：</div>
        <div class="value">{{ orderRefund.refund_reason}}</div>
      </div>
      <div class="part-item">
        <div class="name">申请退款金额：</div>
        <div class="value red">￥{{ orderRefund.refund_amount }}</div>
      </div>
      <div class="part-item">
        <div class="name">退款时间：</div>
        <div class="value">{{ orderRefund.update_time }}</div>
      </div>
      <div class="part-item">
        <div class="name">退款状态：</div>
        <div class="value">{{ getRefundInfo(refundStatus) }}</div>
      </div>
      <div class="part-item">
        <div class="name">退款方式：</div>
        <div class="value">{{ refundMethod(orderRefund.refund_method) }}</div>
      </div>
    </div>
  </div>
  <!--  服务记录-->
  <div class="plate part3" v-if="recordList">
    <titleDom :isShowLine="false" title="服务记录"/>
    <div class="service-record">
      <el-timeline>
        <el-timeline-item v-for="(item, index) in recordList" :key="index">
          <div class="list"><span class="time">{{ item.time}}</span>
            <div class="content">
              上传凭证：
              <div class="content-item">
                <div class="content-item-box">
                  <div class="name">{{item.file_name}}</div>
                  <div class="preview-btn" @click="previewClick(item.file_path)">预览</div>
                </div>
              </div>
            </div>
          </div>
        </el-timeline-item>
      </el-timeline>
    </div>
  </div>
</div>
</template>

<style scoped lang="scss">
.blue{
  color: #1C72F1;
}
.orange{
  color: #FEAE4D;
}
.green{
  color: #04CD83;
}
.red{
  color: #FD5451;
}
.header_box{
  padding: 40px;
  box-sizing: border-box;
  display: flex;
  .header_left{
    width: 383px;
    border-right: 1px solid #CEDAE0;
    .status{
      font-family: Microsoft YaHei;
      font-weight: 400;
      font-size: 18px;
      divne-height: 36px;
    }
    p{
      font-family: Microsoft YaHei;
      font-weight: 400;
      font-size: 14px;
      color: #6C7880;
      line-height: 44px;
    }
  }
  .header_right{
    //padding-left: 130px;
    flex: 1;
  }
}
.plate{
  background-color: #fff;
  .part-item-box{
    margin-top: 30px;
    display: grid;
    grid-template-columns: 550px 2fr;
    grid-gap: 20px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    font-size: 12px;
    .part-item{
      display: flex;
      &.full-width{
        grid-column: 1 / -1;
      }
      .name{
        color: #6C7880;
      }
      .value{
        color: #242D33;
        &.red{
          color: #FD5451;
        }
      }
    }
  }
}
.part1{
  padding: 19px 47px 40px 47px;
  box-sizing: border-box;
  margin-top: 14px;
  .server-info{
    height: 36px;
    background: #F6F7F9;
    display: flex;
    padding: 0 10px;
    box-sizing: border-box;
    .server-info-name{
      font-family: Microsoft YaHei;
      font-weight: 400;
      font-size: 12px;
      color: #444F57;
      line-height: 36px;
      width: 340px;
    }
    .server-info-phone{
      font-family: Microsoft YaHei;
      font-weight: 400;
      font-size: 12px;
      color: #444F57;
      line-height: 36px;
    }
  }
  .tabel-content{border: 1px solid #DCE5EC;margin-top: 20px;
    .product-img{width: 70px;height: 70px;background: #CEDAE0;border-radius: 6px;margin-right: 10px;}
    .sm-text{font-size: 12px;color: #242D33;margin-left: 10px;}
  }
  .el-image {
    max-width: 64px;
    max-height: 64px;
    width: 64px;
    height: 64px;
    background: #E1E8EC;
    border-radius: 4px;
  }

  .image-slot {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    background: var(--el-fill-color-light);
    color: var(--el-text-color-secondary);
    font-size: 30px;
  }

  .image-slot .el-icon {
    font-size: 16px;
  }
  .product-info {
    display: grid;
    align-items: center;
    grid-template-columns: 64px 1fr;
    grid-gap: 10px;
  }
}
.part2,.part3{
  padding: 19px 47px 40px 47px;
  box-sizing: border-box;
  margin-top: 14px;
}
.part3{
  .service-record{
    margin-top: 30px;
  }
  .follow_item{
    margin-bottom: 0;
  }
  .el-timeline{
    font-size: 12px;
  }
  .add_record{
    color: #3984F5;
    margin-left: 140px;
    margin-top: -20px;
  }
  .add_record.no_record{
    margin-left: 112px;
  }
  .list {display: flex;font-size: 12px;color: #6C7880;}
  .time {margin-right: 58px;}
  .content {flex: 1;display: flex;
    .content-item-box{
      margin-bottom: 10px;
      color: #6C7880;
      display: flex;
      .name{
        color: #242D33;
      }
      .preview-btn{
        margin-left: 29px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        font-size: 12px;
        color: #3984F5;
        cursor: pointer;
      }
    }
  }
}
.part4{
  padding: 19px 47px 40px 47px;
  box-sizing: border-box;
  margin-top: 14px;
  background: #FFF4F4;
}
.product-info-box{
  display: flex;
  align-items: center;
  .img-box{
    flex-shrink: 0;
    width: 72px;
    height: 72px;
    background: #CEDAE0;
    border-radius: 6px;
    margin-right: 18px;
    img{
      width: 100%;
      height: 100%;
    }
  }
  .product-info-txt{
    flex: 1;
    .product-info-title{
      font-family: Microsoft YaHei;
      font-weight: 400;
      font-size: 14px;
      color: #242D33;
      line-height: 24px;
    }
    .product-info-desc{
      font-family: Microsoft YaHei;
      font-weight: 400;
      font-size: 12px;
      color: #6C7880;
      line-height: 24px;
    }
    .product-info-specification{
      font-family: Microsoft YaHei;
      font-weight: 400;
      font-size: 12px;
      color: #3984F5;
      line-height: 24px;
    }
  }
}
::v-deep .el-timeline {flex: 1;margin-top: 2px;
  .el-timeline-item {padding-bottom: 30px;}
  .el-timeline-item__node {width: 13px;height: 13px;border: 2px solid #3984F5;background: #EEF3FB;box-sizing: border-box;}
  .el-timeline-item__tail {left: 5px;border-left: 1px solid #CEDAE0;}
}
</style>
