<template>
	<div class="">
		<el-table :data="msg" v-loading="isLoad" height="530" style="width: 100%" border :header-cell-style="{height:'48px;',background:'#F6F7F9',color:'#6C7880'}" >
      <el-table-column label="开票主体" width="208">
        <template slot-scope="scope">
          <span>{{scope.row.invoice_name || '中国联合网络通信有限公司云南省分公司'}}</span>
        </template>
      </el-table-column>
			<el-table-column prop="company.company" label="开票企业" width="208"></el-table-column>
			<el-table-column prop="title" label="发票抬头" width="208"></el-table-column>
			<el-table-column label="发票金额" width="100">
				<template slot-scope="scope">
					<span style="color: #FD5451">￥{{scope.row.amount}}</span>
				</template>
			</el-table-column>
			<el-table-column prop="medium" label="发票介质" width="80" align="center"></el-table-column>
			<el-table-column prop="order_no" label="关联订单" width="140"></el-table-column>
			<el-table-column label="申请时间" width="180">
				<template slot-scope="scope">
					<span>{{scope.row.create_time}}</span>
				</template>
			</el-table-column>
			<el-table-column label="发票状态" width="110">
				<template slot-scope="scope">
					<span :style="[getStatusColor(scope.row.invoice_status)]">{{scope.row.invoice_status_text}}</span>
				</template>
			</el-table-column>
			<el-table-column label="操作时间" width="200">
				<template slot-scope="scope">
					<span>{{getTimeMul(scope.row.handle_time)}}</span>
				</template>
			</el-table-column>
			<el-table-column label="操作" fixed="right" width="140">
				<template slot-scope="scope">
					<el-button type="text" size="small"v-if="scope.row.invoice_status!=2" @click="openDetails(scope.row)">开票详情</el-button>
					<el-button type="text" size="small" v-if="scope.row.invoice_status==2" @click="openDetails(scope.row)">审核</el-button>
				</template>
			</el-table-column>
		</el-table>
	</div>
</template>

<script>
	import {timeFormat} from "@/utils/index.js"
	export default {
		name: 'ticket-all-table-item',
		props: {
			msg: {}
		},
    onMounted() {
      console.log(this.msg,'aa')
    },
		data(){
			return {
				isLoad: false,
			}
		},
		methods: {
			//详情
			openDetails(e){
				this.$router.push({path: '/ticketDetails', query: {id: e.id}})
			},
			//时间格式
			getTimeMul(time) {
				return time?timeFormat(time, 'yyyy-mm-dd hh:MM:ss'):'--';
			},
			//获取状态文本颜色
			getStatusColor(num) {
				let color = {color: '#242D33'};
				switch (num){
					case 1:
						color.color = '#242D33';
						break;
					case 2:
						color.color = '#04CD83';
						break;
					case 5:
						color.color = '#242D33';
						break;
					case 8:
						color.color = '#FD5451';
						break;
					default:
						break;
				}
				return color;
			},
			
		},
	}
</script>

<style lang="scss" scoped>
	
</style>