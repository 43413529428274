<template>
	<div class="">
		<el-table :data="msg" height="530" style="width: 100%" border :header-cell-style="{height:'48px;',background:'#F6F7F9',color:'#6C7880'}" >
      <el-table-column label="开票主体" width="300">
        <template slot-scope="scope">
          <span>{{scope.row.invoice_name || '中国联合网络通信有限公司云南省分公司'}}</span>
        </template>
      </el-table-column>
			<el-table-column prop="company.company" label="开票企业" width="300"></el-table-column>
			<el-table-column prop="title" label="发票抬头" width="258"></el-table-column>
			<el-table-column label="发票金额" width="100">
				<template slot-scope="scope">
					<span style="color: #FD5451">￥{{scope.row.amount}}</span>
				</template>
			</el-table-column>
			<el-table-column prop="medium" label="发票介质" width="80" align="center"></el-table-column>
			<el-table-column prop="order_no" label="关联订单" width="210"></el-table-column>
			<el-table-column prop="create_time" label="申请时间" width="210"></el-table-column>
			<el-table-column label="操作" fixed="right">
				<template slot-scope="scope">
					<el-button type="text" size="small" @click="openDetails(scope.row)">审核</el-button>
				</template>
			</el-table-column>
		</el-table>
	</div>
</template>

<script>
	export default {
		name: 'ticket-exa-table-item',
		props: {
			msg: {}
		},
		data(){
			return {
				
			}
		},
		created(){
			
		},
		methods: {
			//详情
			openDetails(e){
				this.$router.push({path: '/ticketDetails', query: {id: e.id}})
			},
			
		},
	}
</script>

<style lang="scss" scoped>
	
	
</style>