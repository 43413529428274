<script>
import {merchant_processed_lists} from "../../../../request/servertApi";

export default {
  name: "processed",
  data(){
    return {
      serviceTypeOptions:[
        {name: "技术支持", id: 1},
        {name: "售后服务", id: 2},
        {name: "培训服务", id: 3},
        {name: "其他", id: 4},
      ],
      registrationTime:[],
      inquire:{
        limit:10,
        page:1,
        cate:'',
        start_time:'',
        end_time:'',
      },
      tableData:[
        {company: "北京某公司", identification: "技术支持", corporation: "我想咨询一下关于xxx的事情", create_time: "2021-01-01 12:00:00"}
      ],
      total:0,
      loading:false
    }
  },
  mounted(){
    this.getType();
    this.getData();
  },
  methods:{
    // 获取列表数据
    getData(){
      this.loading = true;
      this.$api.merchant_processed_lists(this.inquire).then(res => {
        this.tableData = res.data.list;
        this.total = res.data.count;
        console.log(res.data,'已处理反馈列表')
        this.loading = false;
      })
    },
    getType(){
      this.$api.getDictionary("serviceFeed").then(res => {
        this.serviceTypeOptions = res.data;
      })
    },
    handleChangeDate(val){
      this.inquire.start_time = val[0];
      this.inquire.end_time = val[1];
    },
    inquireBtn(){
      this.inquire.page = 1;
      this.getData();
    },
    resetBtn(){
      this.inquire = {
        limit:10,
        page:1,
        cate:'',
        start_time:'',
        end_time:'',
      }
      this.registrationTime = [];
      this.getData();
    },
    handleSizeChange(value){
      this.inquire.page = 1;
      this.inquire.limit = value;
      this.getData();
    },
    handleCurrentChange(value){
      this.inquire.page = value;
      this.getData();
    },
    routerBtn(id){
      this.$router.push({path: '/handleDetail', query: {id: id}})
    }
  }
}
</script>

<template>
<div>
  <div class="screen clearfix" style="margin-top: 10px">
    <div class="item">
      <span>反馈类型</span>
      <div class="box">
        <el-select v-model="inquire.cate" filterable :popper-append-to-body="false" placeholder="全部"
                   style="width: 180px">
          <el-option
              v-for="item in serviceTypeOptions"
              :key="item.id"
              :label="item.dict_name"
              :value="item.id">
          </el-option>
        </el-select>
      </div>
    </div>

    <div class="item">
      <span>投诉建议时间</span>
      <div class="box">
        <el-date-picker
            v-model="registrationTime"
            class="daterange"
            type="datetimerange"
            range-separator="至"
            value-format="yyyy-MM-dd HH:mm:ss"
            start-placeholder="开始时间"
            end-placeholder="结束时间"
            style="width: 234px"
            @change="handleChangeDate">
        </el-date-picker>
      </div>
    </div>
    <span class="button inquire" @click="inquireBtn">搜索</span>
    <span class="button" @click="resetBtn">重置</span>
  </div>
  <div class="main_table">
    <el-table
        ref="multipleTable"
        :data="tableData"
        :border="true"
        v-loading="loading"
        style="width: 100%">
      <el-table-column
          label="序号"
          width="70"
          align="center"
      >
        <template slot-scope="scope">
          <span>{{ (inquire.page - 1) * inquire.limit + scope.$index + 1 }}</span>
        </template>
      </el-table-column>
      <el-table-column
          prop="service_name"
          label="反馈服务商"
          width="330"
      >
      </el-table-column>
      <el-table-column
          prop="dict_name"
          label="反馈类型"
          width="200"
      >
      </el-table-column>
      <el-table-column label="反馈内容">
        <template slot-scope="scope">
          <div class="oe2">{{scope.row.content}}</div>
        </template>
      </el-table-column>
      <el-table-column
          prop="reply_time"
          label="反馈时间"
          width="200"
      >
      </el-table-column>
      <el-table-column
          label="操作"
          width="200"
          align="center"
      >
        <template slot-scope="scope">
          <a style="color: #3984F5" href="JavaScript:;" @click="routerBtn(scope.row.id)">查看详情</a>
        </template>
      </el-table-column>
    </el-table>
    <div class="operate fr">
      <el-pagination
          background
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="inquire.page"
          :page-sizes="[10, 20, 30, 40]"
          :page-size="inquire.limit"
          layout="prev, pager, next, sizes"
          :total="total">
      </el-pagination>
    </div>
  </div>
</div>
</template>
<style lang="scss" scoped src="@/styles/common.scss"></style>
<style scoped lang="scss">

</style>
