<script>
import titleDom from "@/components/common/titleDom";
import {merchant_authentication_info, merchant_authentication_save} from "../../../../request/servertApi";
export default {
  name: "examine",
  components: {titleDom},
  data() {
    return {
      loading: false,
      providerStauts: 0,
      examineInfo: {},
      ruleForm: {
        resource: '',
        desc: ''
      },
      rules: {
        resource: [
          { required: true, message: '审核结果', trigger: 'change' }
        ],
        desc: [
          { required: true, message: '请填写驳回原因', trigger: 'blur' }
        ]
      }
    }
  },
  methods: {
    getDetailData(){
      this.loading = true
      this.$api.merchant_authentication_info({id: this.$route.query.id}).then(res => {
        this.examineInfo = res.data
        this.loading = false
        console.log(res,'认证信息')
      })
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if (this.ruleForm.resource === '审核通过'){
            let params = {
              id: this.$route.query.id,
              status: 2,
              note: ''
            }
            this.$api.merchant_authentication_save(params).then(res => {
              this.$message.success('审核通过成功')
            })
            console.log(params,'审核通过参数')
          }else{
            let params = {
              id: this.$route.query.id,
              status: 3,
              note: this.ruleForm.desc
            }
            this.$api.merchant_authentication_save(params).then(res => {
              this.$message.success('审核驳回成功')
            })
            console.log(params,'审核驳回参数')
          }
          console.log(this.ruleForm)
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    resetForm(formName) {
      // this.$refs[formName].resetFields();
    },
    returnBack(){
      this.$router.push({path: '/serverProvider', query: {activeName:'second'}})
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.providerStauts = this.$route.query.providerStauts
      this.getDetailData()
    })
  }
}
</script>

<template>
<div class="container" v-loading="loading">
  <div class="authentication-info">
    <title-dom title="认证信息"></title-dom>
    <div class="authentication-info-title">{{examineInfo.service_name}}</div>
    <div class="authentication-info-content">
      <div class="authentication-info-content-item">
        <div class="authentication-info-content-item-label">统一社会信用代码：</div>
        <div class="authentication-info-content-item-value">{{examineInfo.identification}}</div>
      </div>
      <div class="authentication-info-content-item">
        <div class="authentication-info-content-item-label">法定代表人姓名：</div>
        <div class="authentication-info-content-item-value">{{examineInfo.corporation}}</div>
      </div>
      <div class="authentication-info-content-item">
        <div class="authentication-info-content-item-label">营业执照：</div>
        <div class="authentication-info-content-item-value"><img :src="examineInfo.business_image" alt=""></div>
      </div>
      <div class="authentication-info-content-item">
        <div class="authentication-info-content-item-label">法定代表人身份证：</div>
        <div class="authentication-info-content-item-value">
          <img :src="examineInfo.front_image" alt="">
          <img :src="examineInfo.back_image" alt="">
        </div>
      </div>
      <div class="authentication-info-content-item">
        <div class="authentication-info-content-item-label">提交时间：</div>
        <div class="authentication-info-content-item-value">
          {{examineInfo.create_time}}
        </div>
      </div>
    </div>
  </div>
<!--  未审核-->
  <div v-if="providerStauts == 1" class="audit-info">
    <title-dom title="审核信息" :isShowLine="false"></title-dom>
    <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
      <el-form-item label="审核结果" prop="resource">
        <el-radio-group v-model="ruleForm.resource">
          <el-radio label="审核通过"></el-radio>
          <el-radio label="审核驳回"></el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label=" 驳回原因" prop="desc" v-if="ruleForm.resource === '审核驳回'">
        <el-input type="textarea" v-model="ruleForm.desc" placeholder="请输入驳回原因"></el-input>
      </el-form-item>
      <el-form-item>
        <el-button style="width: 100px;" type="primary" @click="submitForm('ruleForm')">确定</el-button>
        <el-button style="width: 100px;background: #EEF1F3;color: #6C7880" @click="$router.go(-1)">返回</el-button>
      </el-form-item>
    </el-form>
  </div>
<!--  已审核查看详情-->
  <div v-if="providerStauts == 2" class="audited">
    <title-dom title="审核信息" :isShowLine="false"></title-dom>
    <div class="audited-content">
      <div class="audited-info-box">
        <div class="audited-info-item">
          <div class="name">审核结果：</div>
          <div class="item-content red">{{examineInfo.status == 3 ? '驳回' : '通过'}}</div>
        </div>
        <div class="audited-info-item">
          <div class="name">审核时间：</div>
          <div class="item-content">{{examineInfo.update_time}}</div>
        </div>
        <div class="audited-info-item">
          <div class="name">驳回原因：</div>
          <div class="item-content">{{examineInfo.note}}</div>
        </div>
      </div>
      <div class="back-btn" @click="returnBack">返回</div>
    </div>
  </div>
</div>

</template>

<style scoped lang="scss">
.container {
  .authentication-info{
    margin: 18px;
    padding: 30px;
    width: calc(100% - 96px);
    height: calc(100% - 96px);
    background: #FFFFFF;
    overflow: scroll;
    scrollbar-width: none;
    .authentication-info-title{
      font-family: Microsoft YaHei;
      font-weight: 400;
      font-size: 16px;
      color: #242D33;
      margin-top: 30px;
    }
    .authentication-info-content{
      .authentication-info-content-item{
        margin-top: 25px;
        display: flex;
        .authentication-info-content-item-label{
          flex-shrink: 0;
          width: 120px;
          text-align: right;
          font-family: Microsoft YaHei;
          font-weight: 400;
          font-size: 12px;
          color: #6C7880;
        }
        .authentication-info-content-item-value{
          font-family: Microsoft YaHei;
          font-weight: 400;
          font-size: 12px;
          color: #242D33;
          flex: 1;
          img{
            width: 280px;
            height: 200px;
            margin-right: 10px;
          }
        }
      }
    }
  }
  .audit-info{
    background-color: #fff;
    margin: 12px 18px 18px 18px;
    padding: 30px;
    box-sizing: border-box;
    .demo-ruleForm{
      margin-top: 20px;
    }
    :deep .el-textarea__inner{
      width: 600px;
      height: 80px;
    }
  }
  .audited{
    background-color: #fff;
    margin: 12px 18px 18px 18px;
    padding: 30px;
    box-sizing: border-box;
    .audited-content{
      .name{
        width: 100px;
        text-align: right;
        font-family: Microsoft YaHei;
        font-weight: 400;
        font-size: 12px;
        color: #6C7880;
        line-height: 28px;
      }
      .item-content{
        font-family: Microsoft YaHei;
        font-weight: 400;
        font-size: 12px;
        color: #242D33;
        line-height: 28px;
        &.red{
          color: #FD5451;
        }
        &.green{
          color: #04CD83;
        }
      }
      .audited-info-box{
        margin-top: 20px;
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        grid-gap: 10px;
        .audited-info-item{
          display: flex;
          align-items: center;
        }
      }
      .back-btn{
        width: 100px;
        height: 40px;
        background: #EEF1F3;
        border-radius: 4px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        font-size: 14px;
        color: #6C7880;
        line-height: 40px;
        text-align: center;
        margin-left: 40px;
        margin-top: 30px;
        cursor: pointer;
      }
    }
  }
}
:deep input[aria-hidden="true"] {
  display: none !important;
}

:deep.el-radio:focus:not(.is-focus):not(:active):not(.is-disabled) .el-radio__inner {
  box-shadow: none !important;
}
</style>