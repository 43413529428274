<template>
  <div>
    <div class="container" v-loading="loading">
      <div class="part">
        <div class="title">
          <h3>投诉建议信息</h3>
          <div class="line"></div>
        </div>
        <div class="content">
          <div class="item item-flex">
            <span class="item-name">投诉建议企业</span>
            <div class="item-con">
              <p>{{detailData.company}}</p>
            </div>
          </div>
          <div class="item item-flex">
            <span class="item-name">投诉建议类型</span>
            <div class="item-con">
              <p>{{detailData.dict_name}}</p>
            </div>
          </div>
          <div class="item">
            <span class="item-name">投诉建议内容</span>
            <div class="item-con">
              <p style="margin-top: 4px;">{{detailData.content}}</p>
            </div>
          </div>
          <div class="item">
            <span class="item-name">相关图片</span>
            <div class="item-con">
              <div class="relate-pictures">
                <el-image v-for="(item, index) in detailData.imgs" fit="contain" :src="item" :preview-src-list="detailData.imgs" :initial-index="index" :key="index"></el-image>
              </div>
            </div>
          </div>
          <div class="item item-flex">
            <span class="item-name">联系方式</span>
            <div class="item-con">
              <p>{{detailData.call_tel}}</p>
            </div>
          </div>
          <div class="item item-flex">
            <span class="item-name">提交时间</span>
            <div class="item-con">
              <p>{{detailData.create_time}}</p>
            </div>
          </div>
        </div>
      </div>
      <div class="part mt-12">
        <div class="title">
          <h3>投诉回复</h3>
          <div class="line"></div>
        </div>
        <div class="content">
          <div class="item">
            <span class="item-name">回复内容</span>
            <div class="item-con">
              <p style="margin-top: 4px;">{{detailData.reply_content}}</p>
            </div>
          </div>
          <div class="item">
            <span class="item-name">回复图片</span>
            <div class="item-con">
              <div class="relate-pictures">
                <el-image v-for="(item, index) in detailData.reply_imgs" fit="contain" :src="item" :preview-src-list="detailData.reply_imgs" :initial-index="index" :key="index"></el-image>
              </div>
            </div>
          </div>
          <div class="item item-flex">
            <span class="item-name">回复时间</span>
            <div class="item-con">
              <p>{{detailData.reply_time}}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {feedback_info} from "../../request/oneEnterOneFileApi";

export default {
  name: "enterpriseFeedbackDetail",
  data() {
    return {
      loading: false,
      detailData: {},
    }
  },
  mounted() {
    this.loading = true;
    this.$nextTick(() => {
      this.$api.feedback_info({id: this.$route.query.id}).then(res => {
        this.detailData = res.data;
        this.loading = false;
        console.log(res,'查看详情')
      })
    })
  },
  methods: {

  }
}
</script>

<style scoped lang="scss">
.container {
  margin: 18px;
  //padding: 30px;
  width: calc(100% - 96px);
  height: calc(100% - 96px);
  //background: #FFFFFF;
  overflow: scroll;
  scrollbar-width: none;
  .part {
    margin-bottom: 12px;
    padding: 30px 30px 14px;
    background-color: #ffffff;
    &:last-child {
      margin-bottom: 0;
    }
    .title {
      display: flex;
      align-items: center;
      justify-content: space-between;
      h3{
        position: relative;
        margin-right: 10px;
        flex-shrink: 0;
        height: 20px;
        line-height: 20px;
        font-weight: 400;
        font-size: 18px;
        color: #242D33;
        &:before{
          content: '';
          width: 4px;
          height: 20px;
          background: #3984F5;
          position: absolute;
          top: 0;
          left: -30px;
        }
      }
      .line{
        background: #EDF1F4;
        flex-grow: 1;
        height: 1px;
      }
    }
    .content {
      padding: 20px 0 0;
    }
  }
  .item {
    margin-bottom: 12px;
    font-size: 12px;
    color: #242D33;
    line-height: 24px;
  }
  .item-flex {
    display: flex;
  }
  .item-name {
    margin-right: 16px;
    color: #6C7880;
  }
  .relate-pictures {
    margin-top: 12px;
    .el-image {
      margin-right: 10px;
      width: 100px;
      height: 100px;
    }
  }
  .upload-tips {
    font-size: 12px;
    color: #6C7880;
    line-height: 48px;
  }
  // tags样式修改
  ::v-deep .el-tabs__nav-wrap::after {
    background: #EEEFF2;
    height: 1px;
  }
  ::v-deep .el-tabs__item.is-active {
    color: #3984F5;
    font-weight: bold;
  }
  ::v-deep .el-tabs__item {
    &:hover {
      color: #3984F5;
    }
  }
}
</style>
