<template>
	<div class="product-order-main">
		<div class="title-text">产品订单管理</div>
		<el-tabs v-model="activeName" @tab-click="handleClick">
		    <el-tab-pane v-for="(item, index) in menuList" :key="index" :label="item.title" :name="item.path"></el-tab-pane>
		</el-tabs>
		<router-view></router-view>
	</div>
</template>

<script>
	export default {
		data(){
			return {
				menuList: [
					{title: "全部", path: '0'}, {title: "待确认", path: '1'}, {title: "待办理", path: '2'}, 
					{title: "待验收", path: '3'}, {title: "已完结", path: '4'},{title: "已关闭", path: '5'}
				],//菜单
				activeName: 0,
				pathList: ['/allList', '/waitEnterList', '/waitHandleList', '/orderOverList', '/orderOverList', '/orderOverList'],
				
			}
		},
		created() {
			
		},
		methods: {
			handleClick(e){
				let url = this.pathList[Number(e.name)];
				this.$router.push({path: url, query:{id: e.name}});
			}
		},
	}
</script>

<style lang="scss" scoped>
	.product-order-main{width: 100%;height: 100%;background-color: #fff;
		.title-text{position: relative;font-size: 18px;color: #242D33;padding: 26px 0 20px 30px;
			&:before{position: absolute;content: '';width: 4px;height: 20px;background-color: #3984F5;left: 0;top: 30px;}
		}
	}
	::v-deep(.el-tabs__nav-scroll){padding-left: 30px;}
	::v-deep(.el-tabs__nav-wrap::after){height: 1px;}
	
</style>