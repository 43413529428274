<template>
	<div class="ticket-manage-main">
		<router-view></router-view>
		
	</div>
</template>

<script>
	export default {
		data(){
			return {
				
			}
		},
		created() {
			
		},
		methods: {
			
		
		},
	}
</script>

<style lang="scss" scoped>
	.ticket-manage-main{width: 100%;height: 100%;background-color: #fff;
		
	}
	
</style>