
/***************************** 产品库所有接口 **************************************/


// 导入axios实例
import httpRequest from '@/request/index'



// 获取企业列表
export function productList(param) {
    return httpRequest({
		url: 'admin/product/product_list',
		method: 'GET',
		params: param,
	})
}

// 分类列表
export function cateList(param) {
    return httpRequest({
		url: 'admin/product/cate_list',
		method: 'GET',
		params: param,
	})
}

// 分类状态
export function cateStatus(param) {
    return httpRequest({
		url: 'admin/product/cate_status',
		method: 'POST',
		data: param,
	})
}

// 产品状态修改
export function productStatus(param) {
    return httpRequest({
		url: 'admin/product/product_status',
		method: 'POST',
		data: param,
	})
}

// 产品删除
export function productDel(param) {
    return httpRequest({
		url: 'admin/product/product_del',
		method: 'PUT',
		data: param,
	})
}

// 产品添加/编辑
export function productSave(param) {
    return httpRequest({
		url: 'admin/product/product_save',
		method: 'POST',
		data: param,
	})
}


// 上传单个图片
export function uploadSingleImage(param) {
    return httpRequest({
		url: 'file/upload_single_image',
		method: 'POST',
		data: param,
	})
}


// 分类添加/编辑
export function cateSave(param) {
    return httpRequest({
		url: 'admin/product/cate_save',
		method: 'POST',
		data: param,
	})
}

// 分类删除
export function productCateDel(param) {
    return httpRequest({
		url: 'admin/product/cate_del/'+param,
		method: 'DELETE',
		// data: param,
	})
}

// 分类信息
export function productCateInfo(param) {
    return httpRequest({
		url: 'admin/product/cate_info/'+param,
		method: 'GET',
		// params: param,
	})
}

// 地区树列表
export function areaTreeList(param) {
    return httpRequest({
		url: 'admin/area_tree_list',
		method: 'GET',
		params: param,
	})
}

// 商品信息
export function productInfo(param) {
    return httpRequest({
		url: 'admin/product/product_info/'+param,
		method: 'GET',
		// params: param,
	})
}

// 选项列表
export function dictionaryOptionList(param) {
    return httpRequest({
		url: 'admin/system/dictionary_option_list/23',
		method: 'GET',
		// params: param,
	})
}

// 选项列表
export function cateOptionList(param) {
    return httpRequest({
		url: 'admin/product/cate_option_list',
		method: 'GET',
		// params: param,
	})
}

// 收费方式
export function dictionary_option_list(param=23) {
    return httpRequest({
		url: 'admin/dictionary_option_list/'+param,
		method: 'GET',
		// params: param,
	})
}

// 审核通过
export function auditSuccess(param) {
	return httpRequest({
		url: 'admin/product/audit_success',
		method: 'POST',
		data: param,
	})
}

// 审核驳回
export function auditFail(param) {
	return httpRequest({
		url: 'admin/product/audit_fail',
		method: 'POST',
		data: param,
	})
}

// 获取项目列表
export function projectLists(param) {
	return httpRequest({
		url: 'admin/project/lists',
		method: 'POST',
		data: param,
	})
}

// 获取项目详情
export function projectDetail(param) {
	return httpRequest({
		url: 'admin/project/detail',
		method: 'POST',
		data: param,
	})
}

// 项目审核通过
export function projectAuditSuccess(param) {
	return httpRequest({
		url: 'admin/project/audit_success',
		method: 'POST',
		data: param,
	})
}

// 项目审核驳回
export function projectAuditFail(param) {
	return httpRequest({
		url: 'admin/project/audit_fail',
		method: 'POST',
		data: param,
	})
}

// 服务类型
export function getDictionaryOptionList(param) {
	return httpRequest({
		url: 'admin/dictionary_option_list/' + param,
		method: 'GET'
	})
}



