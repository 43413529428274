<script>
import titleDom from "@/components/common/titleDom";
export default {
  name: "listDetails",
  components: {titleDom},
  data() {
    return {
      loading: false,
      detailsData: {},
      addressData: [],
      industryData: [],
      dictDefaultProps: {
        label: "dict_name",
        value: 'id'
      },
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.getAddressData();
      this.getHy();
      this.getDetailsDataMethod();
    })
  },
  methods: {
    getDetailsDataMethod() {
      this.$api.base_info({id: this.$route.query.id}).then(res => {
        this.detailsData = res.data;
        console.log(res.data,'详情信息')
      })
    },
    getHy(){
      this.$api.getDictionary('serviceIndustry').then(res=>{
        this.industryData = res.data
        console.log(res,'行业接口')
      })
    },
    getAddressData() {
      this.$api.areaTreeList().then(res=>{
        this.addressData = res.data;
        console.log(res,'地区接口')
      })
    },
    findRegionNameById(regions, targetId) {
      targetId = Number(targetId); // 转换为数字类型
      for (let region of regions) {
        // 检查当前地区的ID
        if (region.id === targetId) {
          return region.name; // 如果ID匹配，返回当前地区名称
        }

        // 如果当前地区有子地区，递归查找
        if (region.children && region.children.length > 0) {
          const childResult = this.findRegionNameById(region.children, targetId);
          if (childResult !== '--') { // 判断子查找结果是否有效
            return `${region.name}-${childResult}`; // 匹配到后返回名称并拼接
          }
        }
      }
      return '--'; // 如果没有找到相应的 ID，则返回 --
    },
    goBack() {
      this.$router.go(-1);
    },
    getTxt(status){
      if (status == 0) {
        return "未认证";
      }else if (status == 1) {
        return "审核中";
      }else if (status == 2) {
        return "已认证";
      }else if (status == 3) {
        return "认证失败";
      }
    }
  }
}
</script>

<template>
<div class="container">
  <div class="base-info">
    <title-dom title="基本信息"></title-dom>
    <div class="base-info-content">
      <div class="base-info-content-header">{{detailsData.service_name}}</div>
      <div class="base-info-content-body">
        <div class="base-info-content-body-item">
          <div class="base-info-content-body-item-label">统一社会信用代码：</div>
          <div class="base-info-content-body-item-value">{{detailsData.identification}}</div>
        </div>
        <div class="base-info-content-body-item">
          <div class="base-info-content-body-item-label">用户名：</div>
          <div class="base-info-content-body-item-value">{{detailsData.username}}</div>
        </div>
        <div class="base-info-content-body-item">
          <div class="base-info-content-body-item-label">联系人：</div>
          <div class="base-info-content-body-item-value">{{detailsData.truename}}</div>
        </div>
        <div class="base-info-content-body-item">
          <div class="base-info-content-body-item-label">联系电话：</div>
          <div class="base-info-content-body-item-value">{{detailsData.mobile}}</div>
        </div>
        <div class="base-info-content-body-item">
          <div class="base-info-content-body-item-label">所在地区：</div>
          <div class="base-info-content-body-item-value">{{findRegionNameById(addressData, detailsData.areaid)}}</div>
        </div>
        <div class="base-info-content-body-item">
          <div class="base-info-content-body-item-label">详细地址：</div>
          <div class="base-info-content-body-item-value">{{detailsData.address}}</div>
        </div>
        <div class="base-info-content-body-item">
          <div class="base-info-content-body-item-label">所属行业：</div>
          <div class="base-info-content-body-item-value">{{selectDictLabel(industryData,detailsData.industry_id,dictDefaultProps)}}</div>
        </div>
        <div class="base-info-content-body-item">
          <div class="base-info-content-body-item-label">认证状态：</div>
          <div class="base-info-content-body-item-value" :class="{'red': detailsData.vaildate_status == 3, 'green': detailsData.vaildate_status == 2, 'blue': detailsData.vaildate_status == 1 || detailsData.vaildate_status == 0}">{{getTxt(detailsData.vaildate_status)}}</div>
        </div>
        <div class="base-info-content-body-item full-width">
          <div class="base-info-content-body-item-label">主营业务：</div>
          <div class="base-info-content-body-item-value">{{detailsData.main_business}}</div>
        </div>
      </div>
    </div>
  </div>
  <div class="billingInfo">
    <title-dom title="开票信息"></title-dom>
    <div class="billingInfo-content">
      <div class="billingInfo-content-item">
        <div class="billingInfo-content-item-label">是否可以开具发票：</div>
        <div class="billingInfo-content-item-value">{{detailsData.is_invoice == 0 ? '否' : '是'}}</div>
      </div>
      <div class="billingInfo-content-item">
        <div class="billingInfo-content-item-label">开票主体名称：</div>
        <div class="billingInfo-content-item-value">{{detailsData.invoice_name || '--'}}</div>
      </div>
    </div>
  </div>
  <div class="authentication-info" v-if="detailsData.vaildate_info">
    <title-dom title="认证信息"></title-dom>
    <div class="authentication-info-content">
      <div class="authentication-info-content-item">
        <div class="authentication-info-content-item-label">营业执照：</div>
        <div class="authentication-info-content-item-value"><img :src="detailsData.vaildate_info.business_image" alt=""></div>
      </div>
      <div class="authentication-info-content-item">
        <div class="authentication-info-content-item-label">法定代表人姓名：</div>
        <div class="authentication-info-content-item-value">{{detailsData.vaildate_info.corporation}}</div>
      </div>
      <div class="authentication-info-content-item">
        <div class="authentication-info-content-item-label">法定代表人身份证：</div>
        <div class="authentication-info-content-item-value">
          <img :src="detailsData.vaildate_info.front_image" alt="">
          <img :src="detailsData.vaildate_info.back_image" alt="">
        </div>
      </div>
      <div class="authentication-info-content-item">
        <div class="authentication-info-content-item-label">认证时间：</div>
        <div class="authentication-info-content-item-value">
          {{detailsData.vaildate_info.update_time}}
        </div>
      </div>
    </div>
  </div>
  <div class="back-btn">
    <a href="javascript:;" @click="goBack" class="back-btn-link">返回</a>
  </div>
</div>
</template>

<style scoped lang="scss">
.container {
  margin: 18px;
  padding: 30px;
  width: calc(100% - 96px);
  height: calc(100% - 96px);
  background: #FFFFFF;
  overflow: scroll;
  scrollbar-width: none;
  .base-info{
    .base-info-content{
      margin-top: 10px;
       .base-info-content-header{
         font-family: Microsoft YaHei;
         font-weight: 400;
         font-size: 18px;
         color: #242D33;
         line-height: 36px;
       }
       .base-info-content-body{
         display: grid;
         grid-template-columns: 500px 1fr;
           .base-info-content-body-item{
              display: flex;
             &.full-width{
               grid-column: 1 / -1;
             }
             .base-info-content-body-item-label{
               text-align: right;
               width: 120px;
               flex-shrink: 0;
               font-family: Microsoft YaHei;
               font-weight: 400;
               font-size: 12px;
               color: #6C7880;
               line-height: 36px;
             }
              .base-info-content-body-item-value{
                flex: 1;
                font-family: Microsoft YaHei;
                font-weight: 400;
                font-size: 12px;
                color: #242D33;
                line-height: 36px;
                &.red{
                  color: #FD5451;
                }
                &.green{
                  color: #04CD83;
                }
                &.blue{
                  color: #3984F5;
                }
              }
           }
       }
    }
  }
  .billingInfo{
    margin-top: 20px;
    .billingInfo-content{
      padding-top: 20px;
      display: grid;
      grid-template-columns: 500px 1fr;
       .billingInfo-content-item{
         display: flex;
         .billingInfo-content-item-label{
           width: 120px;
           font-family: Microsoft YaHei;
           font-weight: 400;
           font-size: 12px;
           color: #6C7880;
           line-height: 36px;
           text-align: right;
           flex-shrink: 0;
         }
         .billingInfo-content-item-value{
           flex: 1;
           font-family: Microsoft YaHei;
           font-weight: 400;
           font-size: 12px;
           color: #242D33;
           line-height: 36px;
         }
       }
    }
  }
  .authentication-info{
    margin-top: 20px;
    .authentication-info-content{
      .authentication-info-content-item{
        margin-top: 25px;
        display: flex;
       .authentication-info-content-item-label{
         flex-shrink: 0;
         width: 120px;
         text-align: right;
         font-family: Microsoft YaHei;
         font-weight: 400;
         font-size: 12px;
         color: #6C7880;
       }
        .authentication-info-content-item-value{
          font-family: Microsoft YaHei;
          font-weight: 400;
          font-size: 12px;
          color: #242D33;
          flex: 1;
          img{
            width: 280px;
            height: 200px;
            margin-right: 10px;
          }
        }
      }
    }
  }
  .back-btn{
    margin-top: 35px;
    a{
      width: 100px;
      height: 40px;
      background: #EEF1F3;
      border-radius: 4px;
      display: block;
      font-family: Microsoft YaHei;
      font-weight: 400;
      font-size: 14px;
      color: #6C7880;
      line-height: 40px;
      text-align: center;
      margin-left: 120px;
    }
  }
}
</style>