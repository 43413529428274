<template>
	<div class="pro-content flex flex-col-center">
		<div class="flex flex-col-center" v-for="(item, index) in statusData" :key="index">
			<div class="pro-item flex flex-col-center">
				<img :src="item.icon" alt="" />
				<div :class="['pro-title-text', item.is_bright?'color-bule':'']">{{item.flow}}</div>
				<div :class="['time-text', item.is_bright?'color-bule':'']">{{item.time}}</div>
			</div>
			<img class="jian-img" :src="item.is_bright?lineImg[0]:lineImg[1]" alt="" v-if="index<statusData.length-1" />
		</div>
	</div>
</template>

<script>
	export default {
		name: 'order-status-item',
		props: {
			msg: {}
		},
		data(){
			return {
				statusList: [ 
					{icon: require('@/assets/order/order_establish_blue_icon.png'), icon_two: require('@/assets/order/order_establish_blue_icon.png')},
					{ icon: require('@/assets/order/order_confirm_blue_icon.png'), icon_two: require('@/assets/order/order_confirm_grey_icon.png')},
					{ icon: require('@/assets/order/order_Processed_blue_icon.png'), icon_two: require('@/assets/order/order_Processed_grey_icon.png')},
					{ icon: require('@/assets/order/order_complete_blue_icon.png'), icon_two: require('@/assets/order/order_complete_grey_icon.png')}
				],
				lineImg: [require('@/assets/order/order_arrow_blue_icon.png'), require('@/assets/order/order_arrow_grey_icon.png')],//进度状态剪头
				statusData: [],
			}
		},
		methods: {
			
		},
		watch: {
			msg(val, vul) {
				this.statusData = val.flow_line;
				for(let i = 0; i < this.statusData.length; i++){
					if (this.statusData[i].flow == '已关闭'){
						this.statusData[i]['icon'] = require('@/assets/order/close_blue_icon.png');
					} else {
						this.statusData[i]['icon'] = this.statusData[i].is_bright ? this.statusList[i].icon : this.statusList[i].icon_two;
					}
				}
			}
		}
	}
</script>

<style lang="scss" scoped>
		.pro-content{padding-left: 80px;
			.pro-item{flex-direction: column;
				img{width: 17px;height: 17px;}
				.pro-title-text{font-size: 12px;color: #A7B2B8;margin-top: 10px;}
				.time-text{font-size: 12px;color: #A7B2B8;margin-top: 5px;width: auto;}
				.color-bule{color: #3984F5;}
			}
			.jian-img{width: 17px;height: 17px;margin: 0 60px;}
		}
	
</style>