<template>
	<div class="">
		<el-dialog title="审核退款" :visible.sync="show" width="490px" :close-on-click-modal="false"  :before-close="handleClose" @opened="openClick">
			<el-form ref="ruleForm" :model="form" :rules="rules" label-width="80px">
				<el-form-item label="审核结果" prop="status">
					<el-radio-group v-model="form.status">
						<el-radio label="1">通过</el-radio>
						<el-radio label="2">驳回</el-radio>
					</el-radio-group>
				</el-form-item>
				<el-form-item label="退款金额" prop="refund_amount" v-show="form.status=='1'">
					<div class="money-text flex flex-col-center">
						<span>￥</span>
						<input class="input-text" type="text" v-model="form.refund_amount" placeholder="请填写退款金额" />
					</div>
				</el-form-item>
				<el-form-item label="退款方式" prop="refund_method" v-show="form.status=='1'">
				  <el-radio-group v-model="form.refund_method">
				    <el-radio :label="1" disabled >线下退回</el-radio>
				    <el-radio :label="2" disabled >线上退回</el-radio>
				  </el-radio-group>
				</el-form-item>
				<el-form-item label="驳回原因" prop="refund_amount" v-show="form.status=='2'">
					<el-input v-model="form.reject_reason" type="textarea" placeholder="请填写驳回原因" maxlength="100" rows="3" show-word-limit></el-input>
				</el-form-item>
				<el-form-item>
					<el-button @click="handleClose">取消</el-button>
					<el-button type="primary" @click="enterClick('ruleForm')">确定</el-button>
				</el-form-item>
			</el-form>
		</el-dialog>
	</div>
</template>

<script>
	export default {
		name: 'exa-order-popup',
		props: {
			show: false,
			msg: {}
		},
		data(){
			return {
				form: {id: '', refund_amount: '', refund_method: 1, status: '1', reject_reason: ''},
				rules: {
					refund_amount: [{ required: true, message: '请输入退款金额', trigger: 'blur' }],
					reject_reason: [{ required: true, message: '请填写驳回原因', trigger: 'blur' }],
					refund_method: [{ required: true, message: '请选择退款方式', trigger: 'blur' }],
				},
				
			}
		},
		created() {
			
		},
		methods: {
			enterClick(formName){
				this.form.id = this.msg.id;
				if (this.form.status=='1'){
					delete this.form.reject_reason;
					delete this.rules.reject_reason;
				} else {
					
				}
				this.$refs[formName].validate((valid) => {
					if (valid) {
						this.$api.review_and_refund(this.form).then(res=>{
							this.$message({type: 'success', message: '操作成功！'});
							this.handleClose();
							this.$emit('change');
						})
					} else {
						console.log('error submit!!');
						return false;
					}
				});
			},
			//打开
			openClick(){
				this.form.refund_amount = this.msg.total_amount;
			},
			handleClose(){
				this.$emit('close');
			}
			
		},
	}
</script>

<style lang="scss" scoped>
		.money-text{width: 350px;height: 34px;border-radius: 4px;border: 1px solid #CEDAE0;padding-left: 14px;line-height: 34px;box-sizing: border-box;
			span{font-size: 12px;}
			.input-text{font-size: 12px;margin-left: 5px;border: none;}
		}
	
</style>