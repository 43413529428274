


/***************************** 订单管理所有接口 **************************************/


// 导入axios实例
import httpRequest from '@/request/index'



// 获取订单列表
export function orderPageList(param) {
    return httpRequest({
		url: 'admin/order/page_list',
		method: 'GET',
		data: param,
		param: param
	})
}

// 确认订单
export function orderOrder_confirm(param) {
    return httpRequest({
		url: 'admin/order/order_confirm/'+param,
		method: 'PUT',
	})
}

// 获取订单详情
export function order_details(param) {
    return httpRequest({
		url: 'admin/order/order_details/'+param,
		method: 'GET',
		param: param
	})
}

// 取消订单
export function order_cancel(param) {
    return httpRequest({
		url: 'admin/order/order_cancel',
		method: 'post',
		data: param
	})
}

// 办理人员
export function order_handle_admin(param) {
    return httpRequest({
		url: 'admin/order/order_handle_admin',
		method: 'GET',
		data: param
	})
}

// 办理订单
export function order_handle(param) {
    return httpRequest({
		url: 'admin/order/order_handle',
		method: 'POST',
		data: param
	})
}

// 完结订单
export function order_finish(param) {
    return httpRequest({
		url: 'admin/order/order_finish/'+param,
		method: 'PUT'
	})
}

// 订单退款
export function order_refund(param) {
    return httpRequest({
		url: 'admin/order/order_refund',
		method: 'POST',
		data: param
	})
}

// 发票列表
export function invoice_list(param) {
    return httpRequest({
		url: 'admin/order/invoice_list',
		method: 'GET',
		data: param
	})
}

// 发票详情
export function invoice_details(param) {
    return httpRequest({
		url: 'admin/order/invoice_details/'+param,
		method: 'GET'
	})
}

// 发票审核 
export function invoice_audit(param) {
    return httpRequest({
		url: 'admin/order/invoice_audit',
		method: 'POST',
		data: param
	})
}

// 上传单个文件
export function upload_single_file(param) {
    return httpRequest({
		url: 'file/upload_single_file',
		method: 'POST',
		data: param,
		type: 'file'
	})
}

// 审核退款 
export function review_and_refund(param) {
    return httpRequest({
		url: 'admin/order/review_and_refund',
		method: 'POST',
		data: param
	})
}

