<template>
	<div class="">
		<el-dialog title="批量审核" :visible.sync="show" width="490px" :close-on-click-modal="false" :before-close="handleClose" @opened="openClick">
			<el-form ref="ruleForm" :model="form" :rules="rules" label-width="80px">
				<el-form-item label="审核结果" prop="status">
					<el-radio-group v-model="form.status">
						<el-radio label="1">通过</el-radio>
						<el-radio label="2">驳回</el-radio>
					</el-radio-group>
				</el-form-item>
				<el-form-item label="驳回原因" prop="case" v-show="form.status=='2'">
					<el-input v-model="form.case" type="textarea" placeholder="请填写驳回原因" maxlength="100" rows="3" show-word-limit></el-input>
				</el-form-item>
				<el-form-item>
					<el-button @click="handleClose">取消</el-button>
					<el-button type="primary" @click="enterClick('ruleForm')">确定</el-button>
				</el-form-item>
			</el-form>
		</el-dialog>
	</div>
</template>

<script>
	export default {
		name: 'batch-audit-popup',
		props: {
			show: false,
			msg: {},
      type: {
        default: 'product'
      }
		},
		data(){
			return {
				form: {id: [], status: '1', case: ''},
				rules: {
          case: [{ required: true, message: '请填写驳回原因', trigger: 'blur' }],
				},

			}
		},
		created() {

		},
		methods: {
			enterClick(formName){
				this.form.id = this.msg;
				console.log(this.type)
				if (this.type == 'product') {
          if (this.form.status=='1'){
            delete this.form.status;
            delete this.form.case;
            delete this.rules.case;
            this.$refs[formName].validate((valid) => {
              if (valid) {
                this.$api.auditSuccess(this.form).then(res=>{
                  this.$message({type: 'success', message: '操作成功！'});
                  this.handleClose();
                  this.$emit('change');
                })
              } else {
                console.log('error submit!!');
                return false;
              }
            });
          } else if (this.form.status=='2') {
            delete this.form.status;
            this.$refs[formName].validate((valid) => {
              if (valid) {
                this.$api.auditFail(this.form).then(res=>{
                  this.$message({type: 'success', message: '操作成功！'});
                  this.handleClose();
                  this.$emit('change');
                })
              } else {
                console.log('error submit!!');
                return false;
              }
            });
          }
        } else if(this.type == 'project') {
          if (this.form.status=='1'){
            delete this.form.status;
            delete this.form.case;
            delete this.rules.case;
            this.$refs[formName].validate((valid) => {
              if (valid) {
                this.$api.projectAuditSuccess(this.form).then(res=>{
                  this.$message({type: 'success', message: '操作成功！'});
                  this.handleClose();
                  this.$emit('change');
                })
              } else {
                console.log('error submit!!');
                return false;
              }
            });
          } else if (this.form.status=='2') {
            delete this.form.status;
            this.$refs[formName].validate((valid) => {
              if (valid) {
                this.$api.projectAuditFail(this.form).then(res=>{
                  this.$message({type: 'success', message: '操作成功！'});
                  this.handleClose();
                  this.$emit('change');
                })
              } else {
                console.log('error submit!!');
                return false;
              }
            });
          }
        }
			},
			//打开
			openClick(){

			},
			handleClose(){
				this.$emit('close');
			}

		},
	}
</script>

<style lang="scss" scoped>
		.money-text{width: 350px;height: 34px;border-radius: 4px;border: 1px solid #CEDAE0;padding-left: 14px;line-height: 34px;box-sizing: border-box;
			span{font-size: 12px;}
			.input-text{font-size: 12px;margin-left: 5px;border: none;}
		}

</style>
