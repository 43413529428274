<template>
	<div class="refund-main">
		<div class="info-title flex flex-col-center">
			<img src="@/assets/order/warning_icon.png" alt="" />
			<span>退款信息</span>
		</div>
		<div class="text-content flex">
			<div class="text-left">
				<div class="name-text">
					退款原因：<span class="company-text">{{msg.order_refund?msg.order_refund.refund_reason:'--'}}</span>
				</div>
				<div class="name-text">
					退款说明：<span class="company-text">{{msg.order_refund?msg.order_refund.refund_explain:'--'}}</span>
				</div>
				<div class="name-text">
					退款状态：<span class="company-text">{{msg.order_refund?getStatusText(msg.refund_status):'--'}}</span>
				</div>
			</div>
			<div class="text-right">
				<div class="name-text">
					申请退款金额：<span class="company-text">{{msg.order_refund?msg.order_refund.refund_amount:'--'}}</span>
				</div>
				<div class="name-text">
					申请时间：<span class="company-text">{{msg.order_refund?msg.order_refund.create_time:'--'}}</span>
				</div>
				<div class="name-text" v-if="msg.refund_status==4">
					驳回原因：<span class="company-text">{{msg.order_refund?msg.order_refund.reject_reason:'--'}}</span>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		name: 'refund-info-item',
		props: {
			msg: {}
		},
		data(){
			return {
				allData: {},
			}
		},
		created() {
			
		},
		methods: {
			//退款状态
			getStatusText(e){
				let str = '已退款';
				switch (e){
					case 1:
						str = '已退款';
						break;
					case 2:
						str = '退款中';
						break;
					case 3:
						str = '取消';
						break;
					case 4:
						str = '驳回';
						break;
					default:
						break;
				}
				return str;
			}
		},
		watch: {
			msg(val, vul) {
				
			}
		}
	}
</script>

<style lang="scss" scoped>
	.refund-main{background: #FFF5F5;margin-top: 18px;padding: 30px;
		.info-title{font-size: 18px;color: #242D33;position: relative;margin-bottom: 29px;
			img{width: 18px;height: 18px;margin-right: 10px;}
			&:before{position: absolute;content: '';left: -30px;top: 4px;width: 4px;height: 20px;background: #FD5451;}
		}
		.text-content{
			.text-left{margin-right: 270px;
				.name-text{font-size: 12px;color: #6C7880;margin-bottom: 20px;
					.company-text{color: #242D33;}
				}
			}
			.text-right{
				.name-text{font-size: 12px;color: #6C7880;margin-bottom: 20px;
					.company-text{color: #242D33;}
				}
			}
		}
	}
	
</style>