<script>
import {change_status, merchantList} from "../../request/servertApi";

export default {
  name: "serverList",
  data() {
    return {
      screen: {
        page: 1,
        limit: 10,
        service_name: "", //服务商名称
        truename: "", //联系人
        areaid: [], //所在地区数组id
        vaildate_status: "", //全部传空，认证状态 0:未认证 1:审核中 2:已认证 3:认证失败
        status: "", //全部传空，状态 0正常 -1禁用
      },
      selectedArea: [], //选择的地区
      tableData: {
        data: [
          {
            id: 1,
          }
        ],
        selectedTable: [],
        loading: false,
        total: 0,
        isIndeterminate: false,
        allSelect: false
      },
      dictData: {
        addressData: [],
        seriousState:[
          {
            label: '未认证',
            value: 0
          },
          {
            label: '审核中',
            value: 1
          },
          {
            label: '已认证',
            value: 2
          },
          {
            label: '认证失败',
            value: 3
          }
        ],
        accountStatus:[
          {
            label: '正常',
            value: 0
          },
          {
            label: '禁用',
            value: -1
          }
        ]
      },
      disableEnableParams: {
        id: "",
        status: ""
      },
      selectedRow: {
        allZero: false, //是否每个对象的status都为0
        allMinusOne: false, //是否每个对象的status都为-1
        hasBoth: true, //是否同时包含0和-1
      }, //选中是否全部禁用或者全部启用或者都有
    }
  },
  mounted() {
    this.getAddressData();
    this.getTableData();
  },
  methods: {
    // 获取列表数据
    getTableData() {
      this.tableData.loading = true;
      this.$api.merchantList(this.screen).then(res => {
        this.tableData.data = res.data.list;
        this.tableData.total = res.data.count;
        this.tableData.loading = false;
        console.log(res,'服务商列表')
      })
    },
    // 切换页码
    handleCurrentChange(currentPage) {
      this.screen.page = currentPage;
      this.getTableData();
    },
    // 切换每页显示数量
    handleSizeChange(pageSize) {
      this.screen.pageSize = pageSize;
      this.getTableData();
    },
    // 切换选择
    handleSelectionChange(val) {
      this.tableData.isIndeterminate = false;
      this.tableData.allSelect = false;
      if (val.length === this.tableData.data.length) {
        this.tableData.allSelect = true;
      } else if (val.length > 0) {
        this.tableData.isIndeterminate = true;
      }
      this.tableData.selectedTable = val;
      // 判断是否每个对象的status都为0
      if (this.tableData.selectedTable.length > 0){
        this.selectedRow.allZero = this.tableData.selectedTable.every(item => item.status === 0);
        // 判断是否每个对象的status都为-1
        this.selectedRow.allMinusOne = this.tableData.selectedTable.every(item => item.status === -1);
        // 判断是否同时包含0和-1
        this.selectedRow.hasBoth = this.tableData.selectedTable.some(item => item.status === 0) && this.tableData.selectedTable.some(item => item.status === -1);
      }else {
        this.selectedRow.allZero = false;
        this.selectedRow.allMinusOne = false;
        this.selectedRow.hasBoth = true;
      }
      console.log(this.selectedRow,'选中状态')
    },
    // 全选
    toggleSelection() {
      if (this.tableData.allSelect) {
        this.tableData.data.forEach(item => {
          this.$refs.multipleTable.toggleRowSelection(item);
        });
      } else {
        this.$refs.multipleTable.clearSelection();
      }
      this.tableData.isIndeterminate = false;
    },
    // 重置查询条件
    resetQuery() {
      this.screen = {
         page: 1,
         limit: 10,
         service_name: "", //服务商名称
         truename: "", //联系人
         areaid: [], //所在地区数组id
         vaildate_status: "", //全部传空，认证状态 0:未认证 1:审核中 2:已认证 3:认证失败
         status: "", //全部传空，状态 0正常 -1禁用
      };
      this.getTableData();
    },
    // 查询
    search() {
      this.screen.page = 1;
      console.log(this.screen)
      this.getTableData();
    },
    // 处理日期
    handleChangeDate(val) {
      this.registrationTime = val;
    },
    // 处理地址
    handleChange(val) {
      this.addressData = val;
      this.address = [];
      val.forEach(item => {
        this.address.push(item.name);
      });
    },
    //编辑
    handleEdit(scope){
      this.$router.push({path: '/listEdit',query: {id: scope.id}})
    },
    //获取地区数据
    getAddressData() {
      this.$api.areaTreeList().then(res=>{
        this.dictData.addressData = res.data;
      })
    },
    handleCascaderChange(val){
      this.screen.areaid = val.map(subArray => subArray[subArray.length - 1]);
      // console.log(this.screen.areaid,'aa')
    },
    getVaildateStatus(status){
      switch (status) {
        case 0:
          return '未认证'
        case 1:
          return '审核中'
        case 2:
          return '已认证'
        case 3:
          return '认证失败'
        default:
          return '--'
      }
    },
    handleStatus(row){ //禁用或者启用
      console.log(row,'服务商状态')
      this.disableEnableParams.id = row.id;
      if(row.status === 0){ //正常，操作禁用
        this.disableEnableParams.status = -1;
        this.$confirm('您确定要禁用该服务商吗？').then(() => {
          this.$api.change_status(this.disableEnableParams).then(res => {
            this.getTableData();
            this.$message.success('禁用成功');
          })
          console.log(this.disableEnableParams,'禁用参数')
        }).catch(() => {});
      }else { //禁用，操作启用
        this.disableEnableParams.status = 0;
        this.$confirm('您确定要启动该服务商吗？').then(() => {
          this.$api.change_status(this.disableEnableParams).then(res => {
            this.getTableData();
            this.$message.success('启动成功');
          })
          console.log(this.disableEnableParams,'启动参数')
        }).catch(() => {});
      }
    },
    enableHandle(){ //启用
      let params = {
        id: this.tableData.selectedTable.map(item => item.id).join(','),
        status: 0
      }
      this.$confirm('您确定要启动该服务商吗？').then(() => {
        this.$api.change_status(params).then(res => {
          this.getTableData();
          this.$message.success('启动成功');
        })
      }).catch(() => {});
    },
    disableHandle(){ //禁用
      let params = {
        id: this.tableData.selectedTable.map(item => item.id).join(','),
        status: -1
      }
      this.$confirm('您确定要禁用该服务商吗？').then(() => {
        this.$api.change_status(params).then(res => {
          this.getTableData();
          this.$message.success('禁用成功');
        })
      }).catch(() => {});
    }
  },
}
</script>

<template>
<div class="container">
  <div class="screen clearfix">
    <div class="item">
      <span>服务商名称</span>
      <div class="box">
        <el-input v-model="screen.service_name" placeholder="输入服务商名称" style="width: 240px"></el-input>
      </div>
    </div>
    <div class="item">
      <span>联系人</span>
      <div class="box">
        <el-input v-model="screen.truename" placeholder="输入联系人" style="width: 140px"></el-input>
      </div>
    </div>
    <div class="item">
      <span>所在地区</span>
      <div class="box">
        <el-cascader style="width: 280px" v-model="selectedArea" :options="dictData.addressData" collapse-tags :props="{value: 'id', label: 'name', multiple: true}" clearable @change="handleCascaderChange"></el-cascader>
    </div>
      </div>
    <div class="item">
      <span>认证状态</span>
      <div class="box">
        <el-select v-model="screen.vaildate_status" :popper-append-to-body="false" placeholder="全部"
                   style="width: 110px">
          <el-option
              v-for="item in dictData.seriousState"
              :key="item.value"
              :label="item.label"
              :value="item.value">
          </el-option>
        </el-select>
      </div>
    </div>
    <div class="item">
      <span>账号状态</span>
      <div class="box">
        <el-select v-model="screen.status" :popper-append-to-body="false" placeholder="全部"
                   style="width: 110px">
          <el-option
              v-for="item in dictData.accountStatus"
              :key="item.value"
              :label="item.label"
              :value="item.value">
          </el-option>
        </el-select>
      </div>
    </div>
    <span class="button inquire" @click="search">查询</span>
    <span class="button" @click="resetQuery">重置</span>
  </div>
  <el-table
      ref="multipleTable"
      :data="tableData.data"
      :border="true"
      v-loading="tableData.loading"
      style="width: 100%"
      @selection-change="handleSelectionChange">
    <el-table-column type="selection" width="60" align="center"></el-table-column>
    <el-table-column prop="id" label="ID" width="60" align="center"></el-table-column>
    <el-table-column prop="service_name" label="服务商名称" width="270"></el-table-column>
    <el-table-column prop="username" label="用户名"></el-table-column>
    <el-table-column prop="truename" label="联系人"></el-table-column>
    <el-table-column prop="mobile" label="联系电话"></el-table-column>
    <el-table-column prop="areaname" label="所在地区" width="240"></el-table-column>
    <el-table-column prop="create_time" label="注册时间"></el-table-column>
    <el-table-column prop="source" label="认证状态" align="center">
      <template slot-scope="scope">
        <span> {{ getVaildateStatus(scope.row.vaildate_status) }}</span>
      </template>
    </el-table-column>
    <el-table-column align="center" label="账号状态">
      <template slot-scope="scope">
        <span v-if="scope.row.status === 0" class="color-green">正常</span>
        <span v-if="scope.row.status === -1" class="color-red">禁用</span>
      </template>
    </el-table-column>
    <el-table-column prop="id" fixed="right" label="操作" width="150">
      <template slot-scope="scope">
        <div class="flex flex-col-center flex-row-between">
          <router-link :to="{path: '/listDetail', query: {id: scope.row.id}}">
            <span class="color-blue">详情</span>
          </router-link>
          <span class="color-blue touch-hover" @click="handleEdit(scope.row)">编辑</span>
          <span class="color-blue touch-hover" @click="handleStatus(scope.row)">{{scope.row.status === 0 ? '禁用' : '启用'}}</span>
        </div>
      </template>
    </el-table-column>
  </el-table>
  <div class="operate">
    <div class="handle">
      <div class="all-select">
        <el-checkbox :indeterminate="tableData.isIndeterminate" v-model="tableData.allSelect" @change="toggleSelection()"></el-checkbox>
      </div>
      <div class="button" v-if="selectedRow.allMinusOne" @click="enableHandle">启用</div>
      <div class="button" v-if="selectedRow.allZero" @click="disableHandle">禁用</div>
      <div class="button" style="background: #d1cfcf; color: white" v-if="selectedRow.hasBoth">启用/禁用</div>
    </div>
    <el-pagination
        background
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="screen.page"
        :page-sizes="[10, 20, 30, 40]"
        :page-size="screen.pageSize"
        layout="prev, pager, next, sizes"
        :total="tableData.total">
    </el-pagination>
  </div>
</div>
</template>
<style lang="scss" scoped src="@/styles/common.scss"></style>
<style scoped lang="scss">
.container{
  margin: 18px;
  padding: 30px;
  width: calc(100% - 96px);
  height: calc(100% - 96px);
  background: #FFFFFF;
  overflow: scroll;
  scrollbar-width: none;
  .el-table {
    .color-red {
      color: #FD5451;
    }

    .color-green {
      color: #04CD83;
    }

    .color-blue {
      color: #3984F5;
    }
  }

  .operate {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;

    .handle {
      display: flex;

      .all-select {
        margin-right: 16px;
        padding-left: 24px;
        line-height: 32px;
      }

      .button {
        margin-right: 10px;
        padding: 0 12px;
        height: 30px;
        line-height: 30px;
        font-size: 12px;
        color: #6C7880;
        border: 1px solid #CEDAE0;
        cursor: pointer;

        &:hover {
          color: #3984F5;
          border-color: #3984F5;
        }
      }
    }
  }
}

// 输入框样式修改
::v-deep .el-input {
  font-size: 12px;

  .el-input__inner {
    padding: 0 12px;
    height: 34px;
    line-height: 34px;
    border: 1px solid #CEDAE0;
    color: #242D33;
  }
}

// 下拉框样式修改
::v-deep .el-select {
  .el-input.is-focus .el-input__inner {
    border-color: #CEDAE0;
  }

  .el-input__icon {
    font-size: 12px;
    line-height: 34px;
  }
}

// 下拉悬浮框样式修改
::v-deep .el-select-dropdown {
  border-color: #CEDAE0;
  box-shadow: 0 0 12px rgba(36, 45, 51, 0.15);

  .el-select-dropdown__item {
    font-size: 12px;
    color: #242D33;
  }

  .el-select-dropdown__item.hover {
    background-color: transparent;
  }

  .el-select-dropdown__item:hover {
    background-color: #F6F7F9;
  }

  .el-select-dropdown__item.selected {
    color: #3984F5;
    font-weight: 400;
    background-color: #F6F7F9;
  }

  .el-select-dropdown__empty {
    font-size: 12px;
  }
}

// 日期样式修改
::v-deep .el-date-editor {
  padding: 0 12px;
  height: 34px;
  line-height: 34px;
  border: 1px solid #CEDAE0;
  color: #242D33;

  .el-range-input {
    width: 38%;
    font-size: 12px;
    color: #242D33;
  }

  .el-range-separator {
    width: 6%;
    font-size: 12px;
  }

  .el-range__close-icon {
    margin-right: -5px;
    width: 18px;
  }
}

// 表格样式修改
::v-deep .el-table {
  font-size: 12px;
  color: #242D33;
  border-color: #DCE5EC;

  &::before,
  &::after {
    background-color: #DCE5EC;
  }

  .el-table__cell {
    padding: 8px 0;
    border-color: #DCE5EC;
  }

  .cell {
    line-height: 24px;
  }

  thead {
    color: #242D33;
  }

  th.el-table__cell.is-leaf {
    border-color: #DCE5EC;
    background: #F6F7F9;
  }

  th {
    font-weight: 400;
  }

  .el-table-column--selection .cell {
    padding-right: 10px;
  }

  .el-table__body tr:hover > td.el-table__cell {
    background-color: #F6FBFE;
  }

  td.el-table__cell {
    padding: 13px 0;
  }
}

// 分页样式修改
::v-deep .el-pagination {
  padding: 0;
  color: #242D33;
  font-weight: 400;

  button {
    font-size: 12px;
    height: 32px;
    line-height: 32px;
  }

  .el-pager li {
    font-size: 12px;
    height: 32px;
    line-height: 32px;
  }

  .btn-next, .btn-prev, .el-pager li {
    border: 1px solid #CEDAE0;
    background-color: #ffffff;
    color: #242D33;
    min-width: 32px;
  }

  .btn-next:not(:disabled), .btn-prev:not(:disabled), .el-pager li {
    &:hover {
      color: #3984F5;
      border-color: #3984F5;
    }
  }

  .el-pager li:not(.disabled).active {
    background-color: #3984F5;
    border-color: #3984F5;
  }

  .btn-next.disabled, .btn-next:disabled, .btn-prev.disabled, .btn-prev:disabled, .el-pager li.disabled {
    color: #E1E8EC;
  }

  .el-pagination__sizes {
    margin-right: 0;
    height: 32px;
    line-height: 32px;
  }

  .el-select .el-input {
    margin-right: 0;

    .el-input__inner {
      font-size: 12px;
      height: 32px;
      line-height: 32px;

      &:hover {
        border-color: #3984F5;
      }
    }
  }

  .el-range-editor.is-active, .el-range-editor.is-active:hover, .el-select .el-input.is-focus .el-input__inner {
    border-color: #3984F5;
  }

  .touch-hover {
    cursor: pointer;
  }
}
</style>